
import Vue from 'vue';
import { API, Result } from '../repositories/api';
import { FirebaseRepository } from '../repositories/firebase';
import { Notification } from '../services/misc/notification';
import firebase from 'firebase/app';
import { FirebaseError } from 'firebase';
import firestore from 'firebase/firestore';
import { Resize } from 'vuetify/es5/directives';
import { PasswordAPI } from '@/components/ForgotPasswordHandler';
export default Vue.extend({
  name: 'forgotpasswordhandler',
  data() {
    return {
      newPassword: '',
      repeatNewPassword: '',
    };
  },
  methods: {
    async resetActionHandler(event: any) {
      event.preventDefault();
      const auth = firebase.auth();
      const result = await PasswordAPI.methods.PasswordResetHandler(
        auth,
        this.newPassword,
        this.repeatNewPassword,
      );
      Notification.methods.send(result.success ? 'success' : 'error', result.message);
      if (result.success) {
        (this as any).$router.push({ path: '/login' });
      }
    },
  },
});
