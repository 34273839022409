
import Vue from 'vue';
import Papa from 'papaparse';
import { Notification } from '@/services/misc/notification';
import _ from 'lodash';
import ParseSheltersService from '@/services/pure/shelters/parse';
import formRules from '@/services/misc/form-validation';
import Settings from '@/components/Utility/Settings.vue';
import { ShelterInformation, ShelterSet } from '@/models/all';
import RegionVerificationService from '@/services/pure/shelters/regions';
import { RegionMap } from '@/models/Organization';
import { FirebaseRepository } from '@/repositories/firebase';
import v4 from 'uuid/v4';
export default Vue.extend({
  components: { Settings },
  props: {
    isDialog: {
      type: Boolean
    }
  },
  data() {
    return {
      content: '',
      nameRules: formRules.name,
      csvRules: formRules.csv,
      includeRegions: false,
      valid: false,
      regionMap: null as null | RegionMap,
      file: null,
      loading: false,
      name: '',
      shelters: [] as ShelterInformation[],
    };
  },
  computed: {
    regions() {
      return this.$store.get('organization/regions');
    },
  },
  watch: {
    file: {
      handler(file: File) {
          const fileReader = new FileReader();
          fileReader.readAsText(file);
          fileReader.onload = async evt => {
             try {
            const text = evt.target!.result as string;
            const result = ParseSheltersService.parse(text);
            this.shelters = result;
            } catch (err) {
          Notification.methods.send('error', `${err}`);
          this.file = null;
        }
          };
      },
    },
  },
  methods: {
    async save() {
      const shelterSet = this.includeRegions ? ShelterSetFactory.create(this.shelters, this.name, this.regionMap?.ID) : ShelterSetFactory.create(this.shelters, this.name);
      const result = await FirebaseRepository.addShelters(
      shelterSet
      );
      if (result) {
        Notification.methods.send('success', 'Upload successful');
        this.$emit('close');
      } else {
        Notification.methods.send('error', 'Something went wrong');
      }
    },
    submit(e: Event) {
      e.preventDefault();
      const hasRegions = _.some(this.shelters.map(s => s.region));
      if (hasRegions && !this.includeRegions) {
        Notification.methods.send('error', 'Found regions in CSV when you said you weren\'t including regions.');
        return;
      }
      if (!this.includeRegions) {
        this.save();
        return;
      }
        if (!this.regionMap) return;
      const result = RegionVerificationService.allRegionsAreValid(
        this.regionMap,
        this.shelters,
      );
      if (result.result === false) {
        Notification.methods.send('error', result.message);
        return;
      }
      this.save();
    },
  },
});

const ShelterSetFactory = {
  create(
    shelterInformations: ShelterInformation[],
    name: string,
    regionMapId?: string,
  ) {
    const shelters = shelterInformations.map(si => {
      return { ...si, id: v4() };
    });
    const date = Date.now();
    const set: ShelterSet = {
      name,
      date,
      ...(regionMapId ? { regionMapId } : {}),
      shelters,
    };
    return set;
  },
};

