
import Vue from 'vue';
import SurveyList from '@/components/SurveyList.vue';
import NoCountsPrompt from '@/components/NoCountsPrompt.vue';
import { SubmissionWrapper } from '../models/survey';
import { FormWrapper, FormType } from '@/models/formstructure';
import CreateSurveyDialog from '@/components/Dialogs/CreateCount/CreateSurveyDialog.vue';

import { SurveyWithCode } from '@/models/count';
export default Vue.extend({
  data() {
    return {
      showDialog: false,
    };
  },
  components: {
    SurveyList,
    NoCountsPrompt,
    CreateSurveyDialog,
  },
  computed: {
    surveys(): SurveyWithCode[] {
      const surveys = (this as any).$store.getters.surveys;
      if (!surveys || surveys.length === 0) {
        return [];
      }
      return surveys.sort((a: any, b: any) => {
        return b.created - a.created;
      });
    },
    forms() {
      return this.$store.getters.formStructures;
    },
  },
});
