
import Vue from 'vue';
import SurveyScreen from '@/components/PITCount/SurveyScreen.vue';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
export default Vue.extend({
  components: {
    SurveyScreen,
    SurveyListener,
  },
  props: ['id'],
});
