
import Vue from 'vue';
import { FormType } from '../../models/formstructure';
export default Vue.extend({
  computed: {
    options() {
      return [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'PIT - Interview',
          value: FormType.PITCountInterview,
        },
        {
          text: 'PIT - Observation',
          value: FormType.PITCountObservation,
        },
        {
          text: 'Misc Survey',
          value: FormType.Other,
        },
        {
          text: 'Registration',
          value: FormType.VolunteerSignup,
        },
      ];
    },
  },
  methods: {
      registerChange(index: number) {
        const v = this.options[index].value;
        this.$emit('change', v);
      },
  },
  data() {
    return {
      message: 'wow',
    };
  },
});
