
import Vue from 'vue';
import { FilterFromQuery } from '../SurveyScreen.vue';
import _ from 'lodash';
import { SurveyWithCode } from '@/models/count';
interface FlattenedPair {
  key: string;
  value: string;
}
interface Pair extends Omit<FlattenedPair, 'value'> {
  value: string | (string | null)[];
}
export default Vue.extend({
  props: {
    id: {},
  },
  methods: {
    removeFilter(filter: FilterFromQuery) {
      const query = { ...this.$route.query };
      if (!query[filter.key]) return;
      if (!Array.isArray(query[filter.key]) || query[filter.key].length < 2)
        delete query[filter.key];
      else {
        query[filter.key] = (query[filter.key] as string[]).filter(
          v => v !== filter.value,
        );
      }
      this.$router.push({ path: this.$route.path, query });
    },
    addToDisplay({ key, value }: FlattenedPair): FilterFromQuery {
      if (key === 'missingInformation') return {
        key,
        value,
        toDisplay: `${key}:${value}`
      }
      if (key === 'boundingBox')
        return {
          key,
          value: value as string,
          toDisplay: 'Bounding Box',
        };
      if (key === 'shelter') {
        const survey: SurveyWithCode = this.$store.getters.Survey(this.id);
        const shelters = survey.shelters;
        // Shouldn't happen.
        if (!shelters) return { key, value, toDisplay: 'Shelter' };
        const shelter = shelters.shelters.find(s => s.id === value);
        // Shouldn't happen
        if (!shelter) return { key, value, toDisplay: 'Shelter' };
        return {
          key,
          value,
          toDisplay: shelter.name,
        };
      }

      //Otherwise, general case.
      if (value === 'Unknown')
        return { key, value, toDisplay: `${key}:${value}` };
      return {
        key,
        value,
        toDisplay: value,
      };
    },
    flatten(pairs: Pair[]) {
      const chips: FlattenedPair[] = [];
      for (let pair of pairs) {
        if (!Array.isArray(pair.value)) chips.push(pair as FlattenedPair);
        else
          for (let val of pair.value) {
            chips.push({ key: pair.key, value: val as string });
          }
      }
      return chips;
    },
  },
  computed: {
    queryFilters(): FilterFromQuery[] {
      const keysWithValues = Object.keys(this.$route.query).filter(
        k => this.$route.query[k] && k !== 'tab' && k !== 'tool',
      );
      const pairs = keysWithValues.map(k => {
        return { key: k, value: this.$route.query[k] };
      });
      // We could have a single filter as a string or multiple (that are ORed together).
      const chips: FlattenedPair[] = this.flatten(pairs);
      // We could also have some that need to be handled in particular ways.
      return chips.map(c => this.addToDisplay(c));
    },
  },
});
