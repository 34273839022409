
import Vue, { PropType } from 'vue';
import { Analytics, SubAnalytics } from '@/models/analytics';
import { DataService } from '@/services/pure/data';
import { AnalyticsService } from '@/services/pure/analytics';
import ReportingTable from '@/components/reporting/ReportingTable.vue';
import { AdminAdjustment } from '@/models/adminadjustment';
import { ConclusionService } from '@/services/pure/conclusion';
import Strings from './Strings';
import _ from 'lodash';
import { BreakdownWrapper } from '@/services/pure/breakdown';
import ReportingService from '@/services/pure/reporting';

export default Vue.extend({
  props: {
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
      required: true,
    },
    report: {
      type: Boolean,
    },
  },
  components: { ReportingTable },
  computed: {
    toDisplay(): any {
      return ReportingService.AllChildren(this.breakdown, this.report);
    },
  },
});
