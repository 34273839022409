
import Vue from 'vue';
import { SurveyType } from '../../../models/count';
import { FormWrapper } from '../../../models/formstructure';
import { RegionMap } from '../../../models/Organization';
import { CountService } from '../../../services/count';
import { Notification } from '../../../services/misc/notification';
import Step from './Step.vue';

export interface StepInfo {
  stepNumber: number;
  stepCount: number;
  title: string;
  description: string;
  body: string;
  hideImage?: boolean;
}

export type stepID =
  | 'type'
  | 'name'
  | 'code'
  | 'zone'
  | 'observationForm'
  | 'interviewForm' |
  'volunteerSignUpForm';

export type TypeAnswers =
  | {
      stepID: 'type';
      answer: 'PIT Count';
    }
  | {
      stepID: 'type';
      answer: 'other';
    };

export type NameAnswers = {
  stepID: 'name';
  answer: string;
};

export type CodeAnswers = {
  stepID: 'code';
  answer: string;
};

export type RegionAnswers = {
  stepID: 'zone';
  answer: RegionMap | null;
};

export type ObservationFormAnswers = {
  stepID: 'observationForm';
  answer: FormWrapper | null;
};

export type InterviewFormAnswers = {
  stepID: 'interviewForm';
  answer: FormWrapper;
};

export type VolunteerSignUpAnswers = {
  stepID: 'volunteerSignUpForm';
  answer: FormWrapper | null;
}
export type answer =
  | TypeAnswers
  | NameAnswers
  | CodeAnswers
  | RegionAnswers
  | ObservationFormAnswers
  | InterviewFormAnswers
  | VolunteerSignUpAnswers;

export interface PartialStepInfo {
  title: string;
  description: string;
  body: string;
  hideImage?: boolean;
}

export default Vue.extend({
  components: {
    Step,
  },
  data() {
    return {
      answers: <answer[]>[],
    };
  },
  props: ['close'],
  computed: {
    step(): StepInfo {
      const partial = this.steps[this.answers.length];
      return {
        stepNumber: this.answers.length + 1,
        stepCount: this.steps.length,
        title: partial.title,
        description: partial.description,
        body: partial.body,
        hideImage: partial.hideImage
      };
    },
    steps(): PartialStepInfo[] {
      // Order matters! We want the list in the end to be in this order.
      const always = [
        {
          title: 'What type of survey are you doing?',
          description: "You can use Hyperion for PIT counts or for any type of surveying you'd like. If you're not doing a PIT count, then you can fully customize your questions, but you lose out on some of the PIT features.",
          body: 'TypeBody',
          hideImage: true
        },
        {
          title: 'Give it a name',
          description:
            'This will be visible to volunteers and administrators. It can be changed later.',
          body: 'NameBody',
        },
        {
          title: 'Customize your code',
          description:
            'This is the code the volunteers will enter to get access to your questions, hotspots, etc.',
          body: 'CodeBody',
        },
      ];

      const pit = [
        {
          title: 'Add zone map',
          description:
            'You can upload a zone map shape file to assign your volunteers to particular zones of your CoC.',
          body: 'ZoneBody',
        },
        {
          title: 'Allow observation surveys',
          description:
            'You can allow volunteers to fill out an observation survey. This is a limited survey that they can fill out themselves when they see someone who they believe to be homeless.',
          body: 'ObservationBody',
        },
        {
          title: 'Include volunteer sign up',
          description:
            'You can add a volunteer registration flow prior to the PIT count.',
          body: 'SignUpBody',
        },
      ];

      const always2 = [
        {
          title: 'Select survey questions',
          description:
            'These are the questions that your volunteers will ask people experiencing homelessness.',
          body: 'FormBody',
        },
      ];

      const isPIT =
        this.answers.length > 0 && this.answers[0].answer === 'PIT Count';

      const toReturn = always;
      if (isPIT) toReturn.push(...pit);
      toReturn.push(...always2);

      return toReturn;
    },
  },
  methods: {
    advance(answer: answer) {
      const done = 1 + this.answers.length === this.steps.length;
      if (!done)  {
        this.answers.push(answer);
        return;
      }

      this.createCount(answer as InterviewFormAnswers);
    },
    answerFor(stepID: stepID): any {
      return this.answers.find(a => a.stepID === stepID)?.answer
    },
    async createCount(lastAnswer: InterviewFormAnswers) {
      const code = await CountService.create(
        this.answerFor('name'),
        this.answerFor('code'),
        lastAnswer.answer.Structure as any as string,
        this.answerFor('observationForm')?.Structure as any as string ?? null,
        this.answerFor('volunteerSignUpForm')?.Structure as any as string ?? null,
        this.answerFor('type') as SurveyType,
        this.answerFor('zone') as RegionMap ?? null,
        null,
      );
      if (code === 'error') {
        Notification.methods.send(
          'error',
          'Something went wrong. The code might have become invalid since it was checked. Please try again.',
        );
        return;
      }
      this.$router.push({ path: `/survey/${code}` });
    },
    cancel() {
      this.answers = [];
      this.close();
    },
  },
});
