import JSZip from 'jszip';
import { KML } from 'ol/format';
import geo from '@/services/pure/geo';

export function getKMLData(buffer: any) {
  const zip = new JSZip();
  let kmlData;
  zip.load(buffer);
  const kmlFile = zip.file(/.kml$/i)[0];
  if (kmlFile) {
    kmlData = kmlFile.asText();
  }
  return kmlData;
}

function getKMLImage(href: string) {
  const zip = new JSZip();
  let url = href;
  let path = window.location.href;
  path = path.slice(0, path.lastIndexOf('/') + 1);
  if (href.indexOf(path) === 0) {
    const regexp = new RegExp(href.replace(path, '') + '$', 'i');
    const kmlFile = zip.file(regexp)[0];
    if (kmlFile) {
      url = URL.createObjectURL(new Blob([kmlFile.asArrayBuffer()]));
    }
  }
  return url;
}

// Define a KMZ format class by subclassing ol/format/KML

const KMZ = /*@__PURE__*/ (function(KML) {
  function KMZ(opt_options: any) {
    const options = opt_options || {};
    options.iconUrlFunction = getKMLImage;
    // @ts-ignore
    KML.call(this, options);
  }

  if (KML) KMZ.__proto__ = KML;
  KMZ.prototype = Object.create(KML && KML.prototype);
  KMZ.prototype.constructor = KMZ;

  KMZ.prototype.getType = function getType() {
    return 'arraybuffer';
  };

  KMZ.prototype.readFeature = function readFeature(source: any, options: any) {
    const kmlData = getKMLData(source);
    return KML.prototype.readFeature.call(this, kmlData, options);
  };

  KMZ.prototype.readFeatures = function readFeatures(
    source: any,
    options: import('ol/format/Feature').ReadOptions | undefined,
  ) {
    const kmlData = getKMLData(source);
    return KML.prototype.readFeatures.call(this, kmlData, options);
  };

  return KMZ;
})(KML);
export default KMZ;