
import Vue from 'vue';
import { CountService } from '@/services/count';
import { GroundTruthService } from '@/services/misc/ground-truth';
import { Display } from '@/models/cleanup';
import { Conclusion, HousingStatus, ShelterStatus, SurveyStatus } from '../../models/adminadjustment';
export default Vue.extend({
  props: ['selected', 'allHouseholds', 'survey'],
  mounted() {
  },
  data(): { surveyStatus: string, housingStatus: '', shelterStatus: '', } {
    return {
      surveyStatus: '',
      housingStatus: '',
      shelterStatus: '',
    };
  },
  computed: {
    hasChange() {
      return this.surveyStatus || this.housingStatus || this.shelterStatus;
    },
    surveyStatuses() {
      return ['', ...GroundTruthService.GetSurveyStatuses()];
    },
    housingStatuses() {
      return ['', ...GroundTruthService.GetHousingStatuses()];
    },
    shelterStatuses() {
      return ['', ...GroundTruthService.GetShelterStatuses()];
    }
  },
  methods: {
    async saveHouseholds(event: any) {
      event.preventDefault();
      const newAdminAdjustments: Conclusion[] = this.selected.map(
        (s: Display) => s.wrapper.conclusion,
      );
      newAdminAdjustments.forEach(
        c => {
          if (this.surveyStatus) {
            c.surveyStatus = this.surveyStatus as SurveyStatus;
          }
          if (this.housingStatus) {
            c.housingStatus = this.housingStatus as HousingStatus;
          }
          if (this.shelterStatus) {
            c.shelterStatus = this.shelterStatus as ShelterStatus;
          }
        },
      );
      const restrictToStatusIfGuess = newAdminAdjustments.map(a => {
        if (!a.isGuess) {
          return a;
        }
        return {
          surveyStatus: a.surveyStatus,
          housingStatus: a.housingStatus,
          shelterStatus: a.shelterStatus,
          householdID: a.householdID,
          isGuess: a.isGuess,
        };
      });
      await CountService.updateAdminAdjustments(
        this.survey.code,
        restrictToStatusIfGuess as Conclusion[],
      );
      this.$emit('disableDialog');
    },
  },
});
