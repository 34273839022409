
import Vue from 'vue';
import HouseholdComponent from '@/components/Cleanup/Household.vue';
import IndividualDetails from '@/components/Cleanup/IndividualDetails.vue';
import CompareHousehold from '@/components/Cleanup/Compare/CompareHousehold.vue';
import CompareMap from '@/components/Maps/CompareMap.vue';
export default Vue.extend({
  components: {
    HouseholdComponent,
    CompareMap,
    IndividualDetails,
    CompareHousehold,
  },
  props: ['surveyID', 'one', 'two'],
});
