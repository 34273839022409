import {
  TimesHomelessPastThreeYears,
  MonthsHomelessPastThreeYears,
} from '@/models/hmis/Individual/HMISEnrollment';
import { safeParseInt } from '@/services/pure/hmis/hmis-utility';
import { NoYesReasons } from '@/models/hmis/HMISShared';

export function mapTimesHomelessPastThreeYears(
  timesHomeless: string,
): TimesHomelessPastThreeYears {
  switch (timesHomeless) {
    case 'Never':
      return 0;
    case 'Once':
      return 1;
    case 'Twice':
      return 2;
    case 'Three Times':
      return 3;
    case 'Four or More Times':
      return 4;
    case 'Not Sure':
      return 8;
    case 'Refused to Answer':
      return 9;
    default:
      return 99;
  }
}

export function mapMonthsHomelessPastThreeYears(
  monthsHomeless: string,
): MonthsHomelessPastThreeYears {
  if (!monthsHomeless) return 99;

  const monthsHomelessConverted = safeParseInt(monthsHomeless);

  switch (monthsHomelessConverted) {
    case 0:
      return 100;
    case 1:
      return 101;
    case 2:
      return 102;
    case 3:
      return 103;
    case 4:
      return 104;
    case 5:
      return 105;
    case 6:
      return 106;
    case 7:
      return 107;
    case 8:
      return 108;
    case 9:
      return 109;
    case 10:
      return 110;
    case 11:
      return 111;
    case 12:
      return 112;
    default: {
      if (monthsHomelessConverted && monthsHomelessConverted > 12) return 7;
      else return 99;
    }
  }
}

export function mapHomelessContinuouslyOneYear(
  monthsHomeless: string,
): NoYesReasons {
  if (!monthsHomeless) return 99;

  switch (monthsHomeless) {
    case '1 year or longer':
      return 1;
    default:
      return 0;
  }
}
