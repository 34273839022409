
import Vue from 'vue';
import { DeduplicatonService } from '../../services/pure/deduplication';
import { DeDuplicationCluster } from '../../models/DeDuplicationResult';
export default Vue.extend({
  props: ['both', 'surveyID'],
  mounted() {},
  data() {
    return {};
  },
  computed: {
    duplicates(): DeDuplicationCluster[] {
      return DeduplicatonService.methods.findDuplicates(
        this.both,
        this.surveyID,
      );
    },
    text(): string {
      let number = this.duplicates.length === 0 ? 'No' : this.duplicates.length;
      let duplicates =
        this.duplicates.length === 1 ? 'duplicate' : 'duplicates';
      return number + ' ' + duplicates + ' detected.';
    },
  },
  methods: {
    navigateToDuplicates() {
      const query = this.$route.query;
      const newQuery = {
        ...query,
        tool: 'deduplicate',
        tab: 'Tools',
      };
      this.$router.push({
        path: this.$route.path,
        query: newQuery,
      });
      this.$emit('disableDialog');
    }
  },
});
