
interface Property {
  name: Field;
  value: string;
}
interface ToDisplay {
  count: number;
  properties: Property[];
  id: string;
}
import { Both } from '../../../Both';
import Vue, { PropType } from 'vue';
import ExtrapolationTitle from './ExtrapolationTitle.vue';

import {pick, flatten, groupBy, every} from 'lodash';
import PieChart from './PieChart.vue';
import { Field } from '@/models/all';
import { GroundTruthService } from '@/services/misc/ground-truth';
export default Vue.extend({
  props: {
    problematic: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    all: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    missingProperty: {
      type: String as PropType<Field>,
      required: true,
    },
    properties: {
      type: Array as PropType<Field[]>,
    },
  },
  components: {
    ExtrapolationTitle,
    PieChart,
  },
  data() {
    return {
      expanded: [],
    };
  },
  mounted() {
    this.expanded = (this.$refs.table as any).items;
  },
  computed: {
    headers() {
      let headers = [{ text: '# Matching Records', value: 'count' }];
      headers = headers.concat(
        this.properties.map(p => {
          return {
            text: p,
            value: p,
          };
        }),
      );
      return headers;
    },
    items(): any {
      return Object.keys(this.groupByProperties).map(k => {
        const matching = this.groupByProperties[k];
        const numberMatching = matching.length;
        const member = matching[0].conclusion.members.find(
          (m: any) => m[this.missingProperty] === 'Unknown',
        );
        const metadata = {
          count: numberMatching,
          id: matching[0].household.householdID,
        };
        const justOurProperties = pick(member, this.properties);
        return { ...metadata, ...justOurProperties };
      });
    },
    missing(): Both[] {
      const result = this.problematic.filter(b => {
        const members = b.conclusion.members;
        return (
          members.find(
            (m: any) => (m as any)[this.missingProperty as any] === 'Unknown',
          ) !== undefined
        );
      });
      return result;
    },
    groupByProperties(): _.Dictionary<Both[]> {
      return groupBy(this.missing, (b: Both) => {
        const member = b.conclusion.members.find(
          (m: any) => m[this.missingProperty] === 'Unknown',
        );
        return this.properties.map(p => member![p]);
      });
    },
    toDisplay(): ToDisplay[] {
      return Object.keys(this.groupByProperties).map(k => {
        const matching = this.groupByProperties[k];
        const numberMatching = matching.length;
        const member = matching[0].conclusion.members.find(
          (m: any) => m[this.missingProperty] === 'Unknown',
        );
        const toDisplay: ToDisplay = {
          count: numberMatching,
          id: matching[0].household.householdID,
          properties: this.properties.map(p => {
            return {
              name: p,
              value: member![p],
            };
          }),
        };
        return toDisplay;
      });
    },
  },
  methods: {
    viewRecords(item: any) {
      const { count, id, ...properties } = item;
      const key = Object.values(properties).join(',');
      const grouped = this.groupByProperties;
      const surveys = grouped[key];
      const surveyIds = surveys.map(s => s.household.householdID);
      const query = { tab: 'Data', prefilteredSurveys: surveyIds };
      this.$router.push({ path: this.$route.path, query });
    },
    hasMatchingRecords(item: any) {
      return (
        this.getRecordsThatArentMissingAValueAndShareKnown(item).length > 0
      );
    },
    chartData(item: any) {
      const fieldDistribution = this.fieldDistribution(item);
      const labels = fieldDistribution.map(f => f.name);
      const data = fieldDistribution.map(f => f.found);
      return {
        labels,
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            label: 'Data One',
            data,
          },
        ],
      };
    },
    getRecordsThatArentMissingAValueAndShareKnown(item: any) {
      const allMembers = flatten(this.all.map(a => a.conclusion.members));
      const wherePropertyIsKnown = allMembers.filter(
        (m: any) => !!m[this.missingProperty] && m[this.missingProperty] !== 'Unknown',
      );
      const whereTheyShareThoseValues = wherePropertyIsKnown.filter((m: any) => {
        const { count, id, ...properties } = item;
        return every(
          Object.keys(properties),
          (k: Field) => properties[k] === m[k],
        );
      });
      return whereTheyShareThoseValues;
    },
    fieldDistribution(item: any) {
      const whereTheyShareThoseValues = this.getRecordsThatArentMissingAValueAndShareKnown(
        item,
      );
      const possibleAnswers = GroundTruthService.GetAnswers(
        this.missingProperty,
        '2022'
      );
      return possibleAnswers.map(a => {
        const count = whereTheyShareThoseValues.filter(
          v => v[this.missingProperty] === a,
        ).length;
        const percentage = count / whereTheyShareThoseValues.length;
        const suggested = Math.round(item.count * percentage);
        return { name: a, found: count, suggested };
      });
    },
  },
});
