
import Vue from 'vue';
import CleanupWrapper from '@/components/Cleanup/CleanupWrapper.vue';
import { Both} from "../Both"
import { Household } from '../models/survey';
import { ConclusionService } from '../services/pure/conclusion';
import DoneCleaning from '@/components/Cleanup/DoneCleaning.vue';
import { Conclusion } from '@/models/adminadjustment';
import HouseholdComponent from '@/components/Cleanup/Household.vue';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
import { EntryService } from '../services/entry';

export default Vue.extend({
  props: ['surveyID'],
  components: {
    CleanupWrapper,
    DoneCleaning,
    HouseholdComponent,
    SurveyListener,
  },
  computed: {
    households(): Both[] {
      return EntryService.attentionNeeded(this.surveyID);
    },
    next(): Both | null {
      return this.households.length > 0 ? this.households[0] : null;
    },
  },
});
