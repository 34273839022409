import { RaceExport, RaceNoneExport } from "@/models/hmis/Individual/HMISClient";

export const HMISRace = {
  mapIsIndianNative(race?: string | string[]): RaceExport {
    return this.mapRace(race, 'American Indian');
  },
  mapIsAsian(race?: string | string[]): RaceExport {
    return this.mapRace(race, 'Asian');
  },
  mapIsBlackAfAmerican(race?: string | string[]): RaceExport {
    return this.mapRace(race, 'Black');
  },
  mapIsNativeHIOtherPacific(race?: string | string[]): RaceExport {
    return this.mapRace(race, 'Pacific');
  },
  mapIsWhite(race?: string | string[]): RaceExport {
    return this.mapRace(race, 'White');
  },
  mapRace(race: string | string[] | undefined, raceToCheck: string): RaceExport {
    if (!race)
      return 99;

    if (typeof race === 'string')
      return race.indexOf(raceToCheck) !== -1 ? 1 : 0;
    else
      return race.some(r => r.indexOf(raceToCheck) !== -1) ? 1 : 0;
  },
  mapIsRaceNone(race?: string | string[] | undefined): RaceNoneExport {
    if (!race)
      return 99;

    if (typeof race === 'string') {
      if (race === 'Not Sure') return 8;
      if (race === 'Refused to Answer') return 9;
      else return null;
    }
    else {
      const raceMappings = [
        this.mapIsIndianNative(race),
        this.mapIsAsian(race),
        this.mapIsBlackAfAmerican(race),
        this.mapIsNativeHIOtherPacific(race),
        this.mapIsWhite(race)
      ];
      return raceMappings.every(race => race === 99) ? 99 : null;
    }
  }
};
