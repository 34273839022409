
import Vue from 'vue';
import { DeduplicatonService } from '../../services/pure/deduplication';
import { DeDuplicationCluster } from '../../models/DeDuplicationResult';
import { DataService } from '../../services/pure/data';
import { HouseholdMember } from '../../models/survey';
import * as _ from 'lodash'
export default Vue.extend({
  props: ['members'],
  methods: {
            nicer(value: string) {
      return DataService.nicer(value);
    },
     commonProperties(members: HouseholdMember[]): string[] {
         if (members[0] === undefined) return Object.keys(members[1]);
         if (members[1] === undefined) return Object.keys(members[0]);
       const one = Object.keys(members[0]);
       const two = Object.keys(members[1]);
       const common = _.intersectionWith(one, two, _.isEqual);
       return common;
     },
     uniqueProperties(member: HouseholdMember, other: HouseholdMember): string[] {
         if (member === undefined) return [];
         const one = Object.keys(member);
      return _.differenceWith(one, this.commonProperties([member, other]), _.isEqual);
     }
  }
});
