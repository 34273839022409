
import Vue, { PropType } from 'vue';
import DetailBox from '@/components/Cleanup/DetailBox.vue';
import { DataService } from '@/services/pure/data';
import CountMap from '@/components/Maps/CountMap.vue';
import { Household } from '@/models/survey';
import { SurveyWithCode } from '@/models/count';

export default Vue.extend({
  components: {
    DetailBox,
    CountMap,
  },
  props: {
    survey: {
      type: Object as PropType<Household>,
    },
    surveyID: {
      type: String,
    },
  },
  computed: {
    location(): string {
      if (this.hasShelter) return this.shelterName;
      return DataService.formatLocation(this.survey.latlng);
    },
    shelterName(): string {
      if (!this.survey.shelter) return 'Unknown';
      const survey: SurveyWithCode = this.$store.get('survey/survey');
      if (!survey.shelters) return 'Unknown';
      const shelter = survey.shelters.shelters.find(
        s => s.id === this.survey.shelter,
      );
      if (!shelter) return 'Unknown';
      return shelter.name;
    },
    invalid(): boolean {
      return (
        DataService.formatLocation(this.survey.latlng) === 'Unknown' ||
        this.hasShelter
      );
    },
    hasShelter(): boolean {
      return !!this.survey.shelter;
    },
  },
  data() {
    return {
      mapDialog: false,
    };
  },
});
