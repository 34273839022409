
import Vue from 'vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import { CountService } from '../../../services/count';
export default Vue.extend({
  props: ['one', 'two', 'surveyID'],
  components: {
    ConfirmationDialog,
  },
  methods: {
    markDuplicate(id: string, number: string) {
      this.dialogText = `Mark Household ${number} as a duplicate?`;
      this.duplicateID = id;
      this.showDialog = true;
    },
    markNeither() {
      this.dialogText = 'Keep both households?';
      this.duplicateID = '';
      this.showDialog = true;
    },
    async proceed() {
      let primary = '';
      let secondary = '';
      let isDuplicate = false;
      if (this.duplicateID === '') {
        primary = this.one.household.householdID;
        secondary = this.two.household.householdID;
        isDuplicate = false;
      } else {
        isDuplicate = true;
        if (this.duplicateID === this.one.household.householdID) {
          secondary = this.one.household.householdID;
          primary = this.two.household.householdID;
        } else {
          secondary = this.two.household.householdID;
          primary = this.one.household.householdID;
        }
      }
      await CountService.updateDuplicate(this.surveyID, {
        primaryHouseholdID: primary,
        secondaryHouseholdID: secondary,
        isDuplicate,
      });
      this.showDialog = false;
    },
  },
  data() {
    return {
      showDialog: false,
      dialogText: '',
      duplicateID: '',
    };
  },
});
