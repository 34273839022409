
  import Vue from 'vue';
  import { CountService } from '@/services/count';

  export default Vue.extend({
    async created() {
      this.createListeners();
    },
    async destroyed() {
      for (let listener of this.listeners) {
        const result = await listener;
        (result as any)();
      }
    },
    data() {
      return {
        listeners: [] as (() => void)[],
      };
    },
    props: {
      id: String,
    },
    methods: {
      async createListeners() {
        const first = this.$store.getters.isLead
          ? await CountService.listenToMyTeamSubmissions(this.id)
          : [await CountService.listenToSubmissions(this.id), await CountService.listenToDashboard(this.id)];
        const second = await CountService.listenToLiveSurveys(this.id);
        const third = await CountService.listenToAdminAdjustments(this.id);
        const fourth = await CountService.listenToDuplicates(this.id);
        const fifth = await CountService.listenToEvents(this.id);
        const sixth = await CountService.listenToVolunteerRegistrations(this.id);
        const seventh = await CountService.listenToHotspots(this.id);
        const eight = await CountService.listenToLocationLookup(this.id);
        this.listeners = [...first, second, third, fourth, fifth, sixth, seventh, eight];
      },
    },
  });
  