
import { HUDSection, ReportLine, ReportSection } from '@/models/all';
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import ReportLink from './ReportLink.vue';
import { BreakdownWrapper } from '../../services/pure/breakdown';

export default Vue.extend({
  components: { ReportLink },
  props: {
    toDisplay: {
      type: Array as PropType<ReportSection[]>,
      required: true,
    },
    title: {
      type: String as PropType<HUDSection>
    },
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
    }
  },
  methods: {
    show(item: ReportLine) {
      if (item.title !== 'Unknown') return true;
      const values = Object.values(item).filter(v => v !== 'Unknown');
      return _.some(values, value => value !== 0);
    },
    isUnknown(item: ReportLine) {
      return item.title === 'Unknown' || item.title === 'Number of Persons (unknown age)';
    },
  },
});
