
import Vue, { PropType } from 'vue';
import { answer } from '../CreateSurveyDialog.vue';
export default Vue.extend({
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
  },
  computed: {
    commonFeatures() {
      return [
        {
          icon: 'mdi-check-bold',
          text: 'Export to CSV',
        },
        {
          icon: 'mdi-check-bold',
          text: 'Powerful analytics',
        },
        {
          icon: 'mdi-check-bold',
          text: 'Geotagging',
        },
        {
          icon: 'mdi-check-bold',
          text: 'Volunteer monitoring',
        },
        {
          icon: 'mdi-check-bold',
          text: 'Hotspots',
        },
      ];
    },
    pitFeatures() {
      return [
        {
          icon: 'mdi-check-bold',
          text: 'Easy reporting to HUD',
        },
        {
          icon: 'mdi-check-bold',
          text: 'Use PIT form, add questions',
        },
        {
          icon: 'mdi-check-bold',
          text: 'Extrapolation + De-duplication',
        },
      ];
    },
    otherFeatures() {
      return [
        {
          icon: 'mdi-check-bold',
          text: 'Fully customizable questions',
        },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    selectAnswer(type: 'PIT Count' | 'other') {
      this.advance({ stepID: 'type', answer: type });
    },
  },
});
