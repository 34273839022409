
import Vue from 'vue';
import firebase from 'firebase/app';
require('firebase/auth');
require('firebase/performance');
require('firebase/functions');
import Nav from './components/Nav.vue';
import Toolbar from './components/Toolbar.vue';
import Toast from './components/Toast.vue';
import { FirebaseRepository } from '@/repositories/firebase';
import { RouterService } from '@/services/misc/router';
import { Notification } from './services/misc/notification';
import { LoggingService } from './services/logging';
export default Vue.extend({
  name: 'App',
  components: {
    Nav,
    Toolbar,
    Toast,
  },
  data() {
    return {
      listeners: [] as any,
    };
  },
  created() {
    const config = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
    };
    firebase.initializeApp(config);
    const functions = firebase.functions();
    // functions.useFunctionsEmulator("http://localhost:5001");
    const perf = firebase.performance();
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user === this.$store.getters.user) {
        return;
      }
      if (user !== null) {
        this.login(user);
      } else {
        try {
          for (const listener of this.listeners) {
            listener();
          }
          this.$store.commit('logout');
          if (this.$router.currentRoute.path !== '/login')
            this.$router.push({ path: '/login' });
        } catch (error) { }
      }
    });
  },
  methods: {
    async login(user: firebase.User): Promise<void> {
      try {
        this.listeners = await FirebaseRepository.listen();
        this.$store.commit('updateUser', user);

        if (
          (this as any).$route.path === '/login' ||
          (this as any).$route.path === '/register'
        )
          (this as any).$router.push({ path: '/home' });
      } catch (error) {
        LoggingService.Log('Permission error when logging in');
        LoggingService.Log(error);
        Notification.methods.send(
          'error',
          "It looks like you don't have permission to access your organization. You may have been removed.",
        );
        await firebase.auth().signOut();
        this.$store.commit('logout');
      }
    },
  },
  computed: {
    isLoggedInPage(): boolean {
      const route = (this as any).$route;
      const isLoggedOut = RouterService.isLoggedOutRoute(route);
      return !isLoggedOut;
    },
  },
});
