
import Vue from 'vue'
import { paidThroughDefault } from '../models/count'
export default Vue.extend({
    props: ['count'],
    computed: {
        hasPaidBefore() {
            return (this.$store as any).getters.paidThrough !== paidThroughDefault
        },
        warningText() {
            if (this.hasPaidBefore) {
                return `Warning: Only 5 submissions will be available until you renew your subscription.`
            }
            return `Warning: Only 5 submissions will be available while in trial mode.`
        }
    }
})
