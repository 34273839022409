
import Vue, { PropType } from 'vue';
import { FormType, FormWrapper } from '../../../../models/formstructure';
import { FormStructureService } from '../../../../services/pure/formstructure';
import { answer } from '../CreateSurveyDialog.vue';
export default Vue.extend({
  data() {
    return {
      form: null,
      loading: false,
    };
  },
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    },
  },
  computed: {
    isPIT(): boolean {
      if (this.answers.length < 1) return false;
      return this.answers[0].answer === 'PIT Count';
    },
    interviewForms(): FormWrapper[] {
      return this.forms.filter(ss => ss.Type === FormType.PITCountInterview);
    },
    otherForms(): FormWrapper[] {
      return this.forms.filter(ss => ss.Type === FormType.Other);
    },
    forms(): FormWrapper[] {
      const structures: FormWrapper[] = this.$store.getters.formStructures;
      return structures.sort((a, b) => b.Edited - a.Edited);
    },
  },
  methods: {
    selectAnswer() {
      if (this.form !== null) {
        this.advance({ stepID: 'interviewForm', answer: this.form as unknown as FormWrapper });
        this.loading = true;
      }
    },
    getTitle(survey: FormWrapper) {
      return FormStructureService.GetTitle(survey);
    },
  },
});
