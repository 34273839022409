
import { Survey, SurveyWithCode } from '@/models/count';
import { FirebaseRepository } from '@/repositories/firebase';
import GeographicService from '@/services/pure/geo';
import { getKMLData } from '@/services/pure/kmz';
import { FeatureCollection } from '@turf/turf';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      features: null as any,
    };
  },
  mounted() {
    const survey: SurveyWithCode = this.$store.get('survey/survey');
    const hotspots = this.$store.getters.hotspots(survey.code);
    this.loadHotspots(hotspots);
  },
  computed: {
    hotspots() {
      const survey: SurveyWithCode = this.$store.get('survey/survey');
      const hotspots = this.$store.getters.hotspots(survey.code);
      return hotspots;
    },
  },
  watch: {
    survey: {
      async handler(hotspots: string) {
        this.loadHotspots(hotspots);
      },
    },
  },
  methods: {
    async loadHotspots(kMZFileContentsAsString: string) {
      const bytes = this.str2ab(kMZFileContentsAsString);
      const kmlData = getKMLData(bytes);
      const result = GeographicService.getFeatureCollection(kmlData);
      this.features = result.features;
    },
    str2ab(str: string) {
      let buf = new ArrayBuffer(str.length);
      let bufView = new Uint8Array(buf);
      for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      return buf;
    },
    genericStyle(): (feature: any) => Style[] {
      return (feature: any) => {
        const style = new Style({
          image: new Icon({
            src: '/img/hotspot.png',
            scale: 1,
            anchor: [0.5, 1],
          }),
        });
        return [style];
      };
    },
  },
});
