import Store from '../store';
import { Both } from "../Both";
import { ConclusionService } from '../services/pure/conclusion';
import {
  SurveyStatus,
  AdminAdjustment,
  HousingStatus,
  Conclusion,
} from '../models/adminadjustment';
import { Household } from '../models/survey';
import { Survey } from '../models/count';
import { AnalyticsService } from './pure/analytics';
import { SurveyService } from './pure/surveys';
import _ from 'lodash';
export const EntryService = {
  GetAdminAdjustment(household: Household, surveyID: string): Conclusion {
    const guess = this.GetConclusionBestGuess(household, surveyID);
    const existing = SurveyService.GetAdminAdjustment(household, surveyID);
    const combined = { ...guess, ...existing.adminAdjustment };
    const allKnown = _.isEqual(existing.adminAdjustment, combined);
    return { isGuess: !allKnown, ...combined };
  },
  GetConclusionBestGuess(
    household: Household,
    surveyID: string,
  ): AdminAdjustment {
    const liveSurveys: string[] = Store.getters.liveSurveys(surveyID);
    return ConclusionService.GetConclusionBestGuessWithLiveSurveys(household, liveSurveys);
  },
  adminAdjustments(id: string, status?: SurveyStatus) {
    const both = this.both(id, status);
    return both.map(b => b.conclusion);
  },
  adminAdjustmentsBySurveyStatus(id: string, status: SurveyStatus) {
    return this.adminAdjustments(id, status).filter(
      (aa: AdminAdjustment) => aa.surveyStatus === status,
    );
  },
  adminAdjustmentsByHousingStatus(id: string, status: HousingStatus) {
    return this.adminAdjustments(id).filter(
      (aa: AdminAdjustment) => aa.housingStatus === status,
    );
  },
  guesses(id: string) {
    return this.both(id).filter(a => a.conclusion.isGuess);
  },
  confirmed(id: string) {
    const confirmed = this.both(id).filter(a => !a.conclusion.isGuess);
    const issueFree = confirmed.filter(
      b => !ConclusionService.HasIssue(b.conclusion),
    );
    const survey: Survey = Store.getters.Survey(id);
    return validLive(issueFree, survey);
  },
  unconfirmed(id: string): Both[] {
    const unconfirmed = this.guesses(id).filter(
      g => !ConclusionService.HasIssue(g.conclusion),
    );
    const survey: Survey = Store.getters.Survey(id);
    return validLive(unconfirmed, survey);
  },
  attentionNeeded(id: string): Both[] {
    const issues = this.both(id).filter(g =>
      ConclusionService.HasIssue(g.conclusion),
    );
    const survey: Survey = Store.getters.Survey(id);
    return filter(issues, survey);
  },
  both(id: string, status?: SurveyStatus): Both[] {
    const households: Household[] = AnalyticsService.getHouseholds(id) ?? [];
    const both = households.map(household => {
      return {
        household,
        conclusion: this.GetAdminAdjustment(household, id),
      };
    });
    if (status === undefined) return both;
    const toShow = both.filter(b => b.conclusion.surveyStatus === status);
    return toShow;
  },
  valid(id: string) {
    return this.confirmed(id).concat(this.unconfirmed(id));
  },
};
function filter(both: Both[], survey: Survey): Both[] {
  const actuallyHomeless = both.filter(
    c => c.conclusion.housingStatus !== 'Not Literally Homeless',
  );
  const status: SurveyStatus = survey.mode === 'Training' ? 'Training' : 'Live';
  return actuallyHomeless.filter(s => s.conclusion.surveyStatus === status);
}

function validLive(both: Both[], survey: Survey): Both[] {
  const actuallyHomeless = both.filter(
    c => c.conclusion.housingStatus !== 'Not Literally Homeless',
  );
  return actuallyHomeless.filter(s => s.conclusion.surveyStatus === 'Live');
}