import { render, staticRenderFns } from "./AllAdults.vue?vue&type=template&id=3edd9cd8&scoped=true&"
import script from "./AllAdults.vue?vue&type=script&lang=ts&"
export * from "./AllAdults.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edd9cd8",
  null
  
)

export default component.exports