
import { RegionMap } from '@/models/Organization';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Vue from 'vue';
export default Vue.extend({
  name: 'FileList',
  computed: {
    items() {
      return this.$store.get('organization/regions');
    },
  },
  methods: {
    select(item: RegionMap) {
      this.$router.push({ path: `/organization/regions/${item.ID}` });
    },
    async deleteMap(item: RegionMap) {
      const result = await FirebaseRepository.deleteRegion(item);
      if (result) {
        Notification.methods.send('success', 'Region deleted');
      } else {
        Notification.methods.send('error', 'Something went wrong');
      }
    },
  },
});
