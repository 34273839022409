
import Settings from '@/components/Utility/Settings.vue';
import Vue from 'vue';
import { hudPopulations } from '../../../models/all';
export default Vue.extend({
  components: { Settings },
  props: {
    value: {},
  },
  computed: {
    populations() {
      return hudPopulations;
    },
  },
});
