
import Vue from 'vue';
import { FirebaseRepository } from '@/repositories/firebase';
import Boundaries from '@/components/Maps/Components/Boundaries.vue';
export default Vue.extend({
  components: { Boundaries },
  props: {
    coc: {
      type: String,
    },
  },
  data() {
    return {
      addingHotspots: false,
      zoom: 4,
      center: [-97, 35.5],
      clicked: false,
      url: null,
      ready: false,
    };
  },
  async mounted() {
    if (this.coc) {
      await this.updateMap(this.coc);
    }
    this.ready = true;
  },
  watch: {
    coc: function(newValue, oldValue) {
      this.updateMap(newValue);
    },
  },
  methods: {
    async updateMap(cocValue: string) {
      const url = await FirebaseRepository.getCoCShapefileUrl(cocValue);
      this.url = url;
    },
    async updateCenter(center: any) {
      this.center = center;
      this.zoom = 7.5;
    },
  },
});
