'use strict';

const fs = require('fs');

/**
 * Converts an array of objects into a CSV file.
 */
class ObjectsToCsv {
  data: any;
  /**
   * Creates a new instance of the object array to csv converter.
   * @param {object[]} objectArray
   */
  constructor(objectArray: any) {
    if (!Array.isArray(objectArray)) {
      throw new Error(
        'The input to objects-to-csv must be an array of objects.',
      );
    }

    if (objectArray.length > 0) {
      if (objectArray.some(row => typeof row !== 'object')) {
        throw new Error(
          'The array must contain objects, not other data types.',
        );
      }
    }

    this.data = objectArray;
  }
  /**
   * Returns the CSV file as string.
   * @param {boolean} header - If false, omit the first row containing the
   * column names.
   * @param {boolean} allColumns - Whether to check all items for column names.
   *   Uses only the first item if false.
   * @returns {Promise<string>}
   */
  async toString(header = true, allColumns = false) {
    return await convert(this.data, header, allColumns);
  }
}

/**
 * Private method to run the actual conversion of array of objects to CSV data.
 * @param {object[]} data
 * @param {boolean} header - Whether the first line should contain column headers.
 * @param {boolean} allColumns - Whether to check all items for column names.
 *   Uses only the first item if false.
 * @returns {string}
 */
async function convert(data: any, header = true, allColumns = false) {
  if (data.length === 0) {
    return '';
  }

  const columnNames = allColumns
    ? [
        ...data.reduce((columns: any, row: any) => {
          // check each object to compile a full list of column names
          Object.keys(row).map(rowKey => columns.add(rowKey));
          return columns;
        }, new Set()),
      ]
    : Object.keys(data[0]); // just figure out columns from the first item in array

  const csvInput = [];
  const headersWithSurroundingQuotes = columnNames.map(c => `"${c}"`);
  if (header) {
    csvInput.push(headersWithSurroundingQuotes);
  }

  // Add all other rows:
  csvInput.push(...data.map((row: any) => columnNames.map(column => {
    if (typeof row[column] === 'undefined') return '';
    if (row[column] === null) return '';
    if (typeof row[column] !== 'string') row[column] = `${row[column]}`

    // remove possible escape problems
    const content = row[column].replaceAll("\\", "").replaceAll("\"", "");
    return `"${content}"`
})));
  const csvContent =  csvInput.map(e => e.join(",")).join("\n");
    return csvContent;
}

export const FormatService = {
  async jsonToCSV(objects: Record<string,string>[]): Promise<string> {
    return await new ObjectsToCsv(objects).toString(true, true);
  },
};
