import { FeatureCollection } from '@turf/turf';

export interface Organization {
  ID: string;
  Name: string;
  Address: string;
  Survey: string;
  SigninCode: string;
}

export type AdminID = {
  ID: string;
};

export type AdminPermission = {
  permission: OrganizationPermission;
};

export type AdminRegions = {
  regions?: string[];
};

export type Lead = AdminID & AdminRegions;

export type AdminWithoutID = AdminPermission & AdminRegions;

export type Admin = AdminID & AdminWithoutID;

export type RegionMap = {
  ID: string;
  name: string;
  regions: FeatureCollection;
};

export type OrganizationPermission = 'all' | 'read' | 'lead' | 'lead-read';

export const permissions: { text: string; value: OrganizationPermission }[] = [
  {
    text: 'Full',
    value: 'all',
  },
  {
    text: 'Read',
    value: 'read',
  },
  {
    text: 'PIT Lead',
    value: 'lead',
  },
  {
    text: 'PIT Lead (Read Only)',
    value: 'lead-read',
  },
];
