import { make } from 'vuex-pathify';
import _ from 'lodash';
const base = {
  survey: null,
};

function getEmptyState() {
  return _.cloneDeep(base);
}

const state = getEmptyState();

type State = typeof state;

const mutations = {
  ...make.mutations(state),
  resetState(theState: State) {
    Object.assign(theState, getEmptyState());
  },
};

const actions = {
  ...make.actions(state),
  reset({ commit }: any) {
    commit('resetState');
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
