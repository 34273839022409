
import { HorizontalBar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
export default {
        extends: HorizontalBar,
        mixins: [reactiveProp],
        props: {
            chartdata: {
                type: Object,
                default: null,
            },
        },
        mounted() {
            // Removes typescript error (need to do that to release to prod.)
            const a: any = this;
            a.renderChart(a.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
        xAxes: [{
          gridLines: {
            display: true
          },
          ticks: {
            fontSize: 16,
            fontStyle: "bold"
          }
        }],
        yAxes: [{
          gridLines: {
            display: true
          },
          ticks: {
           min: 2,
           max: 3,
            fontSize: 16,
          }
        }]
      }
    });
        }
}
