export interface FormWrapper {
  ID: string;
  Edited: number;
  Type: FormType;
  Structure: Form;
}
export interface Form {
  title: string;
  pages: Page[];
}

export interface Page {
  visible: boolean;
  visibleIf?: string;
  name: string;
  elements?: Ele[];
}

export interface Ele {
  visible: boolean;
  type: string;
  name: string;
  title: string | Multilanguage;
  choices: (Choice | string)[];
  templateElements: Ele[];
  // "{Where They Stayed Last Night} != \"Jail, hospital, treatment program\""
  visibleIf?: string;

  html?: string;
}

export interface Multilanguage {
  default: string;
  es: string;
}

export interface Choice {
  value: string;
  text: string | Multilanguage;
}

export enum FormType {
  PITCountObservation = 'Observational',
  PITCountInterview = 'Standard',
  Other = 'Other',
  VolunteerSignup = 'VolunteerSignup',
}
