
import Vue from 'vue';
import Individual from '@/components/Cleanup/Individual.vue';
import HouseholdDetails from '@/components/Cleanup/HouseholdDetails.vue';
import { Household } from '../../models/survey';
import { CountService } from '@/services/count';
import {
  HouseholdMemberConclusions,
  Conclusion,
  AdminAdjustment,
} from '@/models/adminadjustment';
import { ConclusionService } from '../../services/pure/conclusion';
import * as _ from 'lodash';
export default Vue.extend({
  components: {
    Individual,
    HouseholdDetails,
  },
  props: ['adminAdjustment', 'household', 'surveyID', 'markChange'],
  data() {
    return {
      adjustment: (null as any) as AdminAdjustment,
      panels: this.adminAdjustment.members.map((m: HouseholdMemberConclusions, i: number) => i)
    };
  },
  created(): void {
    this.reset();
    window.onbeforeunload = () => {
           return this.hasChange ? "Leaving will discard any changes. Are you sure?" : undefined;
    }
  },
  watch: {
    adminAdjustment: {
      deep: true,
      handler(newVal) {
        this.reset();
      },
    },
  },
  computed: {
    isProblem(): boolean {
      return (
        ConclusionService.HasIssue(this.adjustment) &&
        (this.adjustment.surveyStatus === 'Live' ||
          this.adjustment.surveyStatus === 'Training')
      );
    },
    hasChange(): boolean {
      const result = !_.isEqual(this.adjustment, this.adminAdjustment);
      if (this.markChange) this.markChange(result);
      return result;
    },
  },
  methods: {
    reset(): void {
      this.adjustment = _.cloneDeep(this.adminAdjustment);
      
    },
    handleIndividualChange({
      conclusions,
      id,
    }: {
      conclusions: HouseholdMemberConclusions;
      id: string;
    }) {
      const household: Household = this.household;
      // // Index of the member in the list of members is the same as the index of the conclusion in the list of conclusions.
      const indexOfMember = household.HouseholdMembers.findIndex(
        member => member['Personal ID'] === id,
      );
      this.adjustment.members[indexOfMember] = conclusions;

      // For some reason I do not understand, this is necessary to cause the 'valid' computed property to update.
      // If you put a watcher on 'adjustment' you'll see that it doesn't fire without it.
      this.adjustment.members = [...this.adjustment.members];
    },
    async saveHousehold() {
      await CountService.updateAdminAdjustment(this.surveyID, this.adjustment);
    },
  },
});
