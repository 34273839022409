import { HeadOfHousehold, HouseholdMember } from '@/models/survey';
import { NameQuality, HMISQuality } from './quality';
import { QualityExport } from '@/models/hmis/Individual/HMISClient';

export const HMISName = {
  mapFirst(member: HeadOfHousehold | HouseholdMember): string | null {
    const { first } = parseName(member);
    return first;
  },
  mapLast(member: HeadOfHousehold | HouseholdMember): string | null {
    const { last } = parseName(member);
    return last;
  },
  mapQuality(member: HeadOfHousehold | HouseholdMember): QualityExport {
    const { quality } = parseName(member);
    return HMISQuality.mapNameQuality(quality);
  }
}

function parseName(member: HeadOfHousehold | HouseholdMember) {
  const name = (member as HeadOfHousehold)["Initials Or Name"];
  if (!name) {
    return {
      first: null,
      last: null,
      quality: null
    };
  }
  const spaceIndex = name.indexOf(' ');
  if (spaceIndex === -1) {
    return {
      first: name,
      last: null,
      quality: NameQuality.Partial
    }
  }
  return {
    first: name.substring(0, spaceIndex),
    last: name.substr(spaceIndex + 1),
    quality: NameQuality.Full
  }
}