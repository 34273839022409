
import { Survey, SurveyWithCode } from '@/models/count';
import { Admin, RegionMap } from '@/models/Organization';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Vue from 'vue';
export default Vue.extend({
  computed: {
    teams() {
      const survey: Survey = this.$store.get('survey/survey');
      const regionMap: RegionMap = JSON.parse(survey.regionMap as string);
      return regionMap.regions.features.map(r => r.properties!.name);
    },
    leads(): Admin[] {
      const admins: Admin[] = this.$store.getters.admins;
      const survey: Survey = this.$store.get('survey/survey');
      return this.composeLeads(admins, survey);
    },
    tableHeaders() {
      let tableHeaders = [
        { text: 'Lead', value: 'ID' },
        { text: 'Teams', value: 'regions' },
      ];
      return tableHeaders;
    },
  },
  methods: {
    composeLeads(admins: Admin[], survey: Survey) {
      const leads = admins.filter(a => a.permission === 'lead' || a.permission === 'lead-read');
      const existingLeadMapping = survey.leads ?? [];
    return leads.map(l => {
        const thatAdmin = existingLeadMapping.find(e => e.ID === l.ID)
        const thatAdminsAssignments = thatAdmin ? thatAdmin.regions : [];
        return { ...l, regions: thatAdminsAssignments };
    });

    },
    async saveLeadRegions() {
      let wasFailure = false;
      try {
        const survey: SurveyWithCode = this.$store.get('survey/survey');
        const leads = this.leads.map(l => {
          const { permission, ...lead } = l;
          return lead;
        });
        const result = await FirebaseRepository.setLeadRegions(
          this.$store.getters.organizationID,
          survey.code,
          leads,
        );
        wasFailure = wasFailure || !result;
      } catch {
        wasFailure = true;
      }

      const status = wasFailure ? 'error' : 'success';
      const message = wasFailure ? 'Something went wrong' : 'Leads updated';
      Notification.methods.send(status, message);
    },
  },
});
