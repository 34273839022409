
import Vue from 'vue'
export default Vue.extend({
    props: {
        field: {
            type: String
        },
        count: {
            type: Number
        }
    }
    
})
