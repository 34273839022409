
import { ConclusionService } from '@/services/pure/conclusion';
import { Both } from '../../Both';
import Vue, { PropType } from 'vue';
import CleanupGrid from '@/components/PITCount/Extrapolation/CleanupGrid.vue';
export default Vue.extend({
  props: {
    filtered: {
      type: Array as PropType<Both[]>,
    },
  },
  components: {
    CleanupGrid,
  },
  computed: {
    survey() {
      return this.$store.get('survey/survey');
    },
    problematic() {
      return this.filtered.filter(b =>
        ConclusionService.HasIssue(b.conclusion),
      );
    },
  },
});
