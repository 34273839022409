
import { Survey } from '@/models/count';
import { RegionMap } from '@/models/Organization';
import { Feature } from '@turf/turf';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    selected: {
      type: Array as PropType<Feature[]>,
    },
  },
  computed: {
    survey(): Survey {
      return this.$store.get('survey/survey');
    },
    teams(): Array<Feature> {
      const survey = this.$store.get('survey/survey');
      if (!survey.regionMap) return [];
      const regionMap: RegionMap = JSON.parse(survey.regionMap as string);
      const teams = regionMap.regions.features;
      return teams.map(t => t?.properties?.name);
    },
  },
  methods: {
    onChange(newValue: any) {
      this.$emit('regionChange', newValue);
    },
  },
});
