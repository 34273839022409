
import Vue, { PropType } from 'vue';
import SummaryBox from '@/components/reporting/SummaryBox.vue';
import SummaryBoxSmall from '@/components/reporting/SummaryBoxSmall.vue';
import Report from '@/components/reporting/Report.vue';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
import { EntryService } from '@/services/entry';
import { CountService } from '@/services/count';
import Store from '@/store';
import { Both} from "../../Both"
import { Conclusion, SurveyStatus } from '@/models/adminadjustment';
import { Survey } from '@/models/count';
import HUD from '@/components/HUD.vue';
import CleanupWrapper from '@/components/Cleanup/CleanupWrapper.vue';
import HouseholdComponent from '@/components/Cleanup/Household.vue';
import { ConclusionService } from '@/services/pure/conclusion';
import WarningBanner from './WarningBanner.vue';
import Settings from '@/components/Utility/Settings.vue';
import { CountYear } from '../../services/pure/breakdown';
import { findReportingYearForBoths } from '@/services/year-finder';
import DashboardSettings from './Dashboard/DashboardSettings.vue';
export default Vue.extend({
  components: {
    SummaryBox,
    SummaryBoxSmall,
    Report,
    SurveyListener,
    HUD,
    CleanupWrapper,
    HouseholdComponent,
    WarningBanner,
    Settings,
    DashboardSettings,
  },
  props: {
    id: {
      type: String,
    },
    filtered: {
      type: Array as PropType<Both[]>,
    },
  },
  data() {
    return {
      isFiltered: false,
      reportType: 'hud',
    };
  },
  computed: {
    conclusions(): Conclusion[] {
      return this.both.map(b => b.conclusion);
    },
    year(): CountYear {
      return findReportingYearForBoths(this.both);
    },
    both(): Both[] {
      const highFilter = this.isFiltered
        ? this.filtered
        : EntryService.both(this.id as string, 'Live');
      return highFilter.filter(b => b?.conclusion?.housingStatus === 'Chronically Homeless' || b?.conclusion?.housingStatus === 'Homeless')
    },
    surveyID(): string {
      return (this as any).id;
    },
    attentionNeeded(): boolean {
      return this.needAttention.length > 0;
    },
    needAttention(): Both[] {
      return this.both.filter(c => ConclusionService.HasIssue(c.conclusion));
    },
    survey(): Survey {
      const survey = Store.getters.Survey(this.id);
      return survey;
    },
  },
});
