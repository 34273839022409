
import Vue from 'vue';
import { CountService } from '../services/count';
import { StringService } from '../services/misc/string';
import SurveyListItem from './SurveyListItem.vue';
export default Vue.extend({
  components: {
    SurveyListItem,
  },
  props: ['surveys', 'onClick'],
  methods: {
    getPrettyDate(date: number) {
      return StringService.getPrettyDate(date);
    },
  },
});
