
import { API } from '@/repositories/api';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Settings from '@/components/Utility/Settings.vue';
import Vue from 'vue';
import firebase from 'firebase';
import formRules from '@/services/misc/form-validation';
import { getKMLData } from '@/services/pure/kmz';
import GeographicService from '@/services/pure/geo';
import {
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Properties,
} from '@turf/turf';
export default Vue.extend({
  components: { Settings },
  data() {
    return {
      name: '',
      valid: false,
      nameRules: formRules.name,
      kmzRules: formRules.kmz,
      file: null,
      regionCollection: null as null | FeatureCollection<
        Geometry | GeometryCollection,
        Properties
      >,
    };
  },
  methods: {
    async add(e: any) {
      e.preventDefault();
      const result = await FirebaseRepository.addRegions(
        this.regionCollection,
        this.name,
      );
      if (result) {
        Notification.methods.send('success', 'Upload successful');
        this.$emit('close');
      } else {
        Notification.methods.send('error', 'Something went wrong');
      }
    },
  },
  watch: {
    file: {
      async handler(newVal: File) {
        if (!newVal) {
          this.regionCollection = ({
            features: [],
          } as unknown) as FeatureCollection<
            Geometry | GeometryCollection,
            Properties
          >;
          return;
        }
        let reader = new FileReader();
        reader.readAsArrayBuffer(newVal);
        reader.onload = async evt => {
          const content = evt.target!.result;
          const kmlData = getKMLData(content);
          const result = await GeographicService.getFeatureCollection(kmlData);
          this.regionCollection = result as any;
        };
      },
    },
  },
});
