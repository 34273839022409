const ReportingStringsService = {
  Households(report: boolean = false) {
    return report ? 'Households' : 'Total Number of Households';
  },
  Persons(report: boolean = false) {
    return report ? 'Persons' : 'Total Number of Persons';
  },
  Children(report: boolean = false) {
    return report ? 'Children (Under 18)' : 'Number of Persons (under age 18)';
  },
  Youth(report: boolean = false) {
    return report ? 'Young Adults (18-24)' : 'Number of Persons (18 - 24)';
  },
  Adults25Plus(report: boolean = false) {
    return report ? 'Adults (25+)' : 'Number of Persons (over age 24)';
  },
  Adults25To34(report: boolean = false) {
    return report ? 'Adults (25-34)' : 'Number of Persons (25 - 34)';
  },
  Adults35To44(report: boolean = false) {
    return report ? 'Adults (35-44)' : 'Number of Persons (35 - 44)';
  },
  Adults45To54(report: boolean = false) {
    return report ? 'Adults (45-54)' : 'Number of Persons (45 - 54)';
  },
  Adults55To64(report: boolean = false) {
    return report ? 'Adults (55-64)' : 'Number of Persons (55 - 64)';
  },
  Adults65Plus(report: boolean = false) {
    return report ? 'Adults (65+)' : 'Number of Persons (65+)';
  },
  UnknownAge(report: boolean = false) {
    return report ? 'Unknown Age' : 'Number of Persons (unknown age)';
  },
  Veterans(report: boolean = false) {
    return report ? 'Veterans' : 'Number of Veterans';
  },
  ParentingYouthUnder18(report: boolean = false) {
    return report
      ? 'Parenting Youth under 18'
      : 'Number of parenting youth (under age 18)';
  },
  ChildrenWithParentsUnder18(report: boolean = false) {
    return report
      ? 'Children with Parents under 18'
      : 'Children in households with parenting youth under age 18 (children under age 18 with parents under 18)';
  },
  ParentingYouthOlder(report: boolean = false) {
    return report
      ? 'Parenting Youth (18-24)'
      : 'Number of parenting youth (age 18 to 24)';
  },
  ChildrenWithOlderParents(report: boolean = false) {
    return report
      ? 'Children with Parents 18-24'
      : 'Children in households with parenting youth under age 18 to 24 (children under age 18 with parents age 18 to 24)';
  },
  UnaccompaniedChildren(report: boolean = false) {
    return report
      ? 'Unaccompanied Children (under 18)'
      : 'Number of unaccompanied children (under age 18)';
  },
  UnaccompaniedYouth(report: boolean = false) {
    return report
      ? 'Unaccompanied Youth (18-24)'
      : 'Number of unaccompanied youth (age 18 to 24)';
  },
  MentalHealth(report: boolean = false) {
    return report
      ? 'Mental Health Condition'
      : 'Adults with a Serious Mental Illness';
  },
  SubstanceAbuse(report: boolean = false) {
    return report
      ? 'Substance Abuse Issue'
      : 'Adults with a Substance Use Disorder';
  },
  HIV(report: boolean = false) {
    return report ? 'HIV/AIDS' : 'Adults with HIV/AIDS';
  },
  DV(report: boolean = false) {
    return report
      ? 'Survivor of Domestic Violence'
      : 'Adult Survivors of Domestic Violence (optional)';
  },
};

export default ReportingStringsService;
