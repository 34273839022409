
import Feature from 'ol/Feature';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Vue, { PropType } from 'vue';
export default Vue.extend({
    props: {
        features: {
            type: Array as PropType<Feature[]>
        }
    },
  methods: {
    genericStyle(): (feature: any) => Style[] {
      return (feature: any) => {
        const style = new Style({
          image: new Icon({
            src: '/img/blue_marker.png',
            scale: 1,
            anchor: [0.5, 1],
          }),
        });
        return [style];
      };
    },
  },
});
