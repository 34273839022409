
import Vue, { PropType } from 'vue';
import { Both } from '../../Both';
import SurveyExtrapolation from './SurveyExtrapolation.vue';
import Duplicates from './Tools/Duplicates.vue';
export default Vue.extend({
  components: {
    SurveyExtrapolation,
    Duplicates
  },
  props: {
    filtered: {
      type: Array as PropType<Both[]>,
    },
  },
  computed: {
    ooo(): { icon: string; text: string; value: string }[] {
      return [
        {
          icon: 'mdi-account-plus',
          text: 'Extrapolate',
          value: 'extrapolate',
        },
        {
          icon: 'mdi-file-find',
          text: 'Deduplicate',
          value: 'deduplicate',
        },
      ];
    },
  },
  methods: {
    navigate(selection: string) {
      const query = this.$route.query;
      const newQuery = { ...query, tool: selection };
      this.$router.push({ path: this.$route.path, query: newQuery });
    },
  },
});
