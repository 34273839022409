
import Vue, { PropType } from 'vue';
import ReportingTable from '@/components/reporting/ReportingTable.vue';
import _ from 'lodash';
import { BreakdownFactory, BreakdownWrapper, CurrentYear } from '@/services/pure/breakdown';
import Strings from './Strings';
import { Both } from '../../Both';
import { DataService } from '@/services/pure/data';
import { HUDSection, ReportSection } from '@/models/all';
import { Conclusion } from '@/models/adminadjustment';
import { findReportingYearForHousehold } from '@/services/year-finder';
export default Vue.extend({
  props: {
    both: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    report: {
      type: Boolean,
    },
    question: {
      type: String,
      required: true,
    },
  },
  components: { ReportingTable },
  computed: {
    breakdown(): BreakdownWrapper {
      const year = this.both.length > 0 ? findReportingYearForHousehold(this.both[0].household) : CurrentYear;
      return BreakdownFactory.getBreakdownWrapper(this.both.map(b => b.conclusion) as Conclusion[], year);
    },
    toDisplay() {
      const dict = DataService.getAnswerDict(this.both, this.question);
      const section: ReportSection = {
        title: '' as HUDSection,
        elements: dict.getAnswers(),
      };
      return [section];
    },
  },
});
