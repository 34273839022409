
import Vue, { Component, PropType } from 'vue';
import { DataService } from '../services/pure/data';
import HorizontalBarChart from '@/components/HorizontalBarChart.vue';
import { Analytics, SubAnalytics } from '../models/analytics';
import { HeadOfHousehold, Household, SubmissionWrapper } from '@/models/survey';
import SubAnalyticsVue from './SubAnalytics.vue';
import { StringService } from '../services/misc/string';
import { ConclusionService } from '@/services/pure/conclusion';
import _ from 'lodash';
import {Both} from '../Both'
interface DownloadOption {
  label: string;
  onSave(): void;
}
import { DownloadService } from '@/services/misc/download';
import { AnalyticsService } from '@/services/pure/analytics';
import html2pdf from 'html2pdf.js';
import { Conclusion } from '../models/adminadjustment';
import {
  Breakdown,
  BreakdownFactory,
  BreakdownWrapper,
  CountYear,
} from '@/services/pure/breakdown';
import AdditionalHomelessPopulations from './reporting/AdditionalHomelessPopulations.vue';
import AllBoth from './reporting/AllBoth.vue';
import { Survey } from '@/models/count';
import VeteranBoth from './reporting/VeteranBoth.vue';
import VeteranAdults from './reporting/VeteranAdults.vue';
import YouthParenting from './reporting/YouthParenting.vue';
import YouthUnaccompanied from './reporting/YouthUnaccompanied.vue';
import AllAdults from './reporting/AllAdults.vue';
import AllChildren from './reporting/AllChildren.vue';
import CustomQuestion from './reporting/CustomQuestion.vue';
import { HUDSection, ReportSection } from '@/models/all';
import ReportingService from '@/services/pure/reporting';
import ReportingTable from '@/components/reporting/ReportingTable.vue';
import { findReportingYearForBoths } from '../services/year-finder';

export default Vue.extend({
  props: {
    both: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    reportTitle: {
      type: String,
      required: true,
    },
    reportSubTitle: {
      type: String,
      required: true,
    },
    additionalQuestions: {
      type: Array as PropType<string[]>,
      required: true,
    },
    hudSectionNamesToInclude: {
      type: Array as PropType<HUDSection[]>,
      required: true,
    },
  },
  components: {
    HorizontalBarChart,
    subanalytics: SubAnalyticsVue,
    AdditionalHomelessPopulations,
    AllBoth,
    VeteranBoth,
    VeteranAdults,
    YouthUnaccompanied,
    AllAdults,
    AllChildren,
    YouthParenting,
    CustomQuestion,
    ReportingTable,
  },
  computed: {
    hudSections(): { title: string; elements: ReportSection[] }[] {
      const sections: { title: HUDSection; elements: ReportSection[] }[] = [
        {
          title: 'All Households with Children',
          elements: ReportingService.AllBoth(this.breakdown, true),
        },
        {
          title: 'All Households without Children',
          elements: ReportingService.AllAdults(this.breakdown, true),
        },
        {
          title: 'All Households with only Children',
          elements: ReportingService.AllChildren(this.breakdown, true),
        },
        {
          title: 'Veteran Households with Children',
          elements: ReportingService.VeteranBoth(this.breakdown, true),
        },
        {
          title: 'Veteran Households without Children',
          elements: ReportingService.VeteranAdults(this.breakdown, true),
        },
        {
          title: 'Parenting Youth',
          elements: ReportingService.YouthParenting(this.breakdown, true),
        },
        {
          title: 'Unaccompanied Youth',
          elements: ReportingService.YouthUnaccompanied(this.breakdown, true),
        },
        {
          title: 'Additional Homeless Populations',
          elements: ReportingService.AdditionalHomelessPopulations(
            this.breakdown,
            true,
          ),
        },
      ];
      const filtered = sections.filter(
        s => this.hudSectionNamesToInclude.indexOf(s.title) !== -1,
      );
      return filtered;
    },
    conclusions(): Conclusion[] {
      return this.both.map(b => b.conclusion);
    },
    year(): CountYear {
      return findReportingYearForBoths(this.both);
    },
    breakdown(): BreakdownWrapper {
      return BreakdownFactory.getBreakdownWrapper(this.conclusions, this.year);
    },
  },
});
