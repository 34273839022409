
import Vue from 'vue';
import { SurveyService } from '@/services/pure/surveys';
import { Household, HouseholdMember } from '@/models/survey';
import { AnalyticsService } from '@/services/pure/analytics';
import { ConclusionService } from '@/services/pure/conclusion';
import {
  HouseholdMemberConclusions,
  Conclusion,
  AdminAdjustment,
} from '@/models/adminadjustment';
import { HouseholdType } from '@/models/household-enums';
import { CountService } from '@/services/count';
import HouseholdComponent from '@/components/Cleanup/Household.vue';
import { EntryService } from '@/services/entry';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
export default Vue.extend({
  props: ['surveyID', 'householdID'],
  components: {
    HouseholdComponent,
    SurveyListener,
  },
  data() {
    return {
      hasChange: false
    };
  },
  created() {
  },
    beforeRouteLeave (to, from, next) {
      if (this.hasChange) {
    const answer = window.confirm('Leaving will discard any changes. Are you sure?')
  if (answer) {
    next()
  } else {
    next(false)
  }
      }
      else {
        next();
      }
   },
  computed: {
    adminAdjustment(): AdminAdjustment {
      const households = AnalyticsService.getHouseholds(this.surveyID) ?? [];
      const household = households.find(
        h => h.householdID === this.householdID,
      ) as Household;
      return EntryService.GetAdminAdjustment(
      household,
      this.surveyID,
    );

    },
    household(): Household {
      const households = AnalyticsService.getHouseholds(this.surveyID) ?? [];
      return households.find(
        h => h.householdID === this.householdID,
      ) as Household;
    },
  },
  methods: {
    markChange(changed: boolean) {
      this.hasChange = changed;
    }
  }
});
