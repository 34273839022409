
import Vue, { PropType } from 'vue';
import ReportingTable from '@/components/reporting/ReportingTable.vue';
import _ from 'lodash';
import { BreakdownWrapper, CountYear } from '@/services/pure/breakdown';
import ReportingService from '@/services/pure/reporting';

export default Vue.extend({
  props: {
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
      required: true,
    },
    report: {
      type: Boolean,
    },
  },
  components: { ReportingTable },
  computed: {
    toDisplay() {
      return ReportingService.AllAdults(
        this.breakdown,
        this.report,
      );
    },
  },
});
