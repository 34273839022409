
import SubRegionMap from '@/components/Maps/SubRegionMap.vue';
import Vue from 'vue';
import { ShelterSetWithId } from '@/models/all';
import { StringService } from '@/services/misc/string';
import ShelterList from '@/components/Shelters/ShelterList.vue';
export default Vue.extend({
  props: {
    id: {},
  },
  components: { SubRegionMap, ShelterList },
  computed: {
    shelter(): ShelterSetWithId | undefined {
      const shelters: ShelterSetWithId[] = this.$store.get(
        'organization/shelters',
      );
      const shelter = shelters.find(s => s.id === this.id);
      return shelter;
    },
  },
  methods: {
    prettyDate(date: number) {
      return StringService.getPrettyDate(date);
    },
  },
});
