
import Vue from 'vue';
import * as SurveyCreator from 'survey-creator';
import * as Survey from 'survey-knockout';
import { AnalyticsService } from '@/services/pure/analytics';
import * as _ from 'lodash';
import uuid from 'uuid/v4';
export default Vue.extend({
  props: ['surveyStructure', 'save', 'type', 'isAutosave', 'isReadonly'],
  mounted() {
    SurveyCreator.StylesManager.applyTheme('orange');
    (this as any).createAndEmbedSurveyEditor(this.type);
  },
  data() {
    return {
      previouslyValid: true,
      hasChange: false,
    };
  },
  computed: {
    allowedToAddAndRemovePages() {
      return this.type === 'Other';
    },
  },
  methods: {
    async createAndEmbedSurveyEditor(div: string): Promise<void> {
      const creatorOptions = {
        showJSONEditorTab: this.type === 'Other',
        showTranslationTab: true,
        showLogicTab: true,
        inplaceEditForValues: true,
        showPropertyGrid: true,
        questionTypes: [
          'text',
          'comment',
          'checkbox',
          'radiogroup',
          'dropdown',
          'html',
          'signaturepad',
        ],
      };
      const protectedQuestions = AnalyticsService.getProtectedQuestions(
        this.type,
      );
      Survey.JsonObject.metaData.findProperty(
        'itemvalue',
        'text',
      ).visible = false;
      Survey.JsonObject.metaData.findProperty(
        'questionbase',
        'name',
      ).readOnly = true;
      const creator = new SurveyCreator.SurveyCreator(
        this.type,
        creatorOptions,
      );
      if (this.isReadonly)
        creator.readOnly = true;
      creator.onQuestionAdded.add(function(sender, options) {
        var q = options.question;
        q.name = 'CustomQuestion' + uuid();
        q.title = 'New Question';
      });
      creator.showToolbox = 'right';
      creator.showPropertyGrid = 'right';
      creator.rightContainerActiveItem('toolbox');
      creator.allowModifyPages = false;
      creator.isAutoSave = this.isAutosave;
      creator.showState = true;
      creator.showTitlesInExpressions = true;
      creator.onModified.add(() => {
        this.hasChange = true;
      });
        SurveyCreator.removeAdorners([
          'choices-label',
          'select-choices',
          'choices-draggable',
        ]);
      creator.onCanShowProperty.add((editor, options) => {
        var obj = options.obj;
        if (!obj) return;
        const always = ['title', 'html', 'name'];
        let ifNotProtected = [
          'choices',
          'isRequired',
          'visibleIf',
          'inputType',
          'startWithNewLine',
          'logic',
        ];
        ifNotProtected = this.allowedToAddAndRemovePages
          ? [...ifNotProtected, 'pages']
          : ifNotProtected;
        const isProtected = protectedQuestions.indexOf(obj.name) !== -1;
        options.canShow = isProtected
          ? _.some(always, p => p === options.property.name)
          : _.some(
              always.concat(ifNotProtected),
              p => p === options.property.name,
            );
      });

      creator.onElementAllowOperations.add(function(editor, options) {
        var obj = options.obj;
        if (!obj) return;

        if (protectedQuestions.indexOf(obj.name) !== -1) {
          //disable edit
          options.allowEdit = false;
          //disable delete
          options.allowDelete = false;
          //disable changing type
          options.allowChangeType = false;
        }
        //you may check obj.getType();
      });
      creator.haveCommercialLicense = true;

      creator.text = (this.surveyStructure as any) as string;
      creator.saveSurveyFunc = (saveNo: number, callback: any) => {
        this.hasChange = false;
        this.save(creator.text, (this.surveyStructure as any) as string);
        if (callback) {
          callback(saveNo, true);
        }
      };
    },
  },
});
