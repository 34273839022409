import { CountYear } from './../../../breakdown';
import { HMISClient, ClientMilitary, ClientBase, ClientMetaData } from '@/models/hmis/Individual/HMISClient';
import { HeadOfHousehold, HouseholdMember, Household } from '@/models/survey';
import { HMISMilitary } from '../field/military';
import { HMISQuality, NameQuality } from '../field/quality';
import { HMISRace } from '../field/race';
import { HMISGender } from '../field/gender';
import { mapYesNo, parseYear, getTimeString } from '../../hmis-utility';
import { HMISName } from '../field/name';
import { HMISExportFieldMapping } from '@/models/count';
import {has} from 'lodash';

export const HMISClientService = {
  getClientRecord(member: HeadOfHousehold | HouseholdMember, head: HeadOfHousehold, personalID: string, userID: string, exportID: string, mappings: HMISExportFieldMapping[], year: CountYear): HMISClient {
    const clientBase: ClientBase = this.getClientBase(member, year);
    const clientMilitary: ClientMilitary = this.getClientMilitary(member as HeadOfHousehold);
    const clientMeta: ClientMetaData = this.getClientMetaData(member["Personal ID"] ?? personalID, userID, exportID);
    const userDefinedFields: any = this.getUserDefinedFields(member, head, mappings);
    const client = {
      ...clientBase,
      ...clientMilitary,
      ...clientMeta,
      ...userDefinedFields
    };
    return client as HMISClient;
  },
  getClientBase(member: HeadOfHousehold | HouseholdMember, year: CountYear): ClientBase {
    return {
      FirstName: HMISName.mapFirst(member),
      MiddleName: null,
      LastName: HMISName.mapLast(member),
      NameSuffix: null,
      NameDataQuality: HMISName.mapQuality(member),
      SSN: null,
      SSNDataQuality: HMISQuality.mapSSNQuality(null),
      DOB: null,
      DOBDataQuality: HMISQuality.mapDOBQuality(null),
      AmIndAKNative: HMISRace.mapIsIndianNative(member.Race),
      Asian: HMISRace.mapIsAsian(member.Race),
      BlackAfAmerican: HMISRace.mapIsBlackAfAmerican(member.Race),
      NativeHIOtherPacific: HMISRace.mapIsNativeHIOtherPacific(member.Race),
      OtherGender: null,
      White: HMISRace.mapIsWhite(member.Race),
      RaceNone: HMISRace.mapIsRaceNone(member.Race),
      Ethnicity: mapYesNo(member.Hispanic as string),
      Gender: HMISGender.mapGender(member.Gender),
    };
  },
  getClientMilitary(member: HeadOfHousehold): ClientMilitary {
    return {
      VeteranStatus: mapYesNo(member.Veteran as string),
      YearEnteredService: parseYear(member.MilitaryServiceYearEntered),
      YearSeparated: parseYear(member.MilitaryServiceYearLeft),
      WorldWarII: null,
      KoreanWar: null,
      VietnamWar: null,
      DesertStorm: null,
      AfghanistanOEF: null,
      IraqOIF: null,
      IraqOND: null,
      OtherTheater: null,
      MilitaryBranch: HMISMilitary.mapMilitaryBranch(member.MilitaryBranch),
      DischargeStatus: HMISMilitary.mapDischargeStatus(member.MilitaryDischargeStatus)
    };
  },
  getClientMetaData(personalID: string, userID: string, exportID: string): ClientMetaData {
    const time = getTimeString(new Date());

    return {
      PersonalID: personalID,
      DateCreated: time,
      DateUpdated: time,
      UserID: userID,
      DateDeleted: '',
      ExportID: exportID
    }
  },
  getUserDefinedFields(member: HeadOfHousehold | HouseholdMember, head: HeadOfHousehold, fields: HMISExportFieldMapping[]) {
    if (!member["Personal ID"] || member["Personal ID"].length === 0)
      return {};

    const userDefinedFields: any = {};
    const isHeadOfHousehold = member["Personal ID"] === head["Personal ID"];

    fields.forEach(field => {
      if (field.headOfHouseholdField || field.householdMemberField) {
        const fieldKey = isHeadOfHousehold ? field.headOfHouseholdField : field.householdMemberField;
        const fieldName = field.exportFieldName;
        if (has(member, fieldKey)) {
          const value = (member as any)[fieldKey];
          const afterAdjustment = parseFieldValue(value, fieldName);
          userDefinedFields[fieldName] = afterAdjustment;
          const quality = getQuality(afterAdjustment, fieldName);
          userDefinedFields[fieldName + 'DataQuality'] = HMISQuality.mapFieldQuality(quality, fieldName);
        }
      }
    });

    return userDefinedFields;
  }
};

function getQuality(value: string, fieldName: string) {
  if (value.length !== 11 && fieldName === 'SSN') return 'Approximate or partial reported'
  if (value.length !== 10 && fieldName === 'DOB') return 'Approximate or Partial DOB reported'
  return `Full ${fieldName} reported`;
}

function allNumbers(str: string) {
  return !isNaN(parseInt(str));
}

function parseFieldValue(value: string, key: string): string {
  if (key === 'SSN' && value.length === 9 && allNumbers(value)) return `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5)}`
  return value;
}


