export const RouterService = {
  isLoggedOutRoute(route: any): boolean {
    const path = route.path;
    return [
      '/register',
      '/login',
      '/forgotpassword',
      '/forgotpasswordhandler',
      '/',
      '/removed'
    ].includes(path);
  },
};
