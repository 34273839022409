import { CountYear, CurrentYear } from './../services/pure/breakdown';
import { Age, GenderList } from './household-enums';
import { Additional, All, Veteran, Youth } from '../services/pure/breakdown';

import { ShelterStatus } from './adminadjustment';

export const hudPopulations = [
  'All Households with Children',
  'All Households without Children',
  'All Households with only Children',
  'Veteran Households with Children',
  'Veteran Households without Children',
  'Parenting Youth',
  'Unaccompanied Youth',
  'Additional Homeless Populations',
  'All Households with Unknown Ages',
];
export type HUDSection = typeof hudPopulations[number];

export type ReportSectionTitle =
  | 'Gender'
  | 'Ethnicity'
  | 'Race'
  | 'Chronically Homeless';

export interface ReportSection {
  title: HUDSection | ReportSectionTitle;
  elements: ReportLine[] | ReportSection[];
}

export type ReportGenders = typeof GenderList[number];
export const ethnicitiesWithoutUnknown = ['Non-Hispanic', 'Hispanic'];
export const ethnicities = [...ethnicitiesWithoutUnknown, 'Unknown'];
export type ReportEthnicities = typeof ethnicities[number];

export const ages = (year: CountYear) => {
  if (year == '2021' || year == '2022') {
    return ['Under 18', '18-24', '25+', 'Unknown'];
  }
  return ['Under 18', '18-24', ...adultAges2023, 'Unknown'];
};

export const adultAges2023 = ['25-34', '35-44', '45-54', '55-64', '65+'];

type ReportChronic = 'Total number of households' | 'Total number of persons';
export type ReportTitle =
  | ReportGenders
  | ReportEthnicities
  | ReportChronic;
export interface ReportLine {
  title: ReportTitle;
  Total: number;
  Unsheltered: number;
  Emergency: number;
  Transitional: number;
  SafeHaven: number;
}

export type Column =
  | 'Total'
  | 'Unsheltered'
  | 'Emergency'
  | 'Transitional'
  | 'SafeHaven';

export type Category = 'All' | 'Additional' | 'Youth' | 'Veteran';
export type Property =
  | keyof Youth
  | keyof Veteran
  | keyof All
  | keyof Additional;

export type Section = {
  title: string;
  lastProperty: string;
};

export type ReportingConfig = {
  path: string[];
  sections: Section[];
  title: string;
};

export type AnswerDictionary = {
  [text in ReportTitle]: {
    'Transitional Housing': number;
    'Emergency Shelter': number;
    'Safe Haven': number;
    Unsheltered: number;
  };
};

export class AnswerDistribution {
  dictionary: AnswerDictionary;
  constructor() {
    this.dictionary = {} as any;
  }
  addAnswer(shelterStatus: ShelterStatus, answer: ReportTitle) {
    if (!(answer in this.dictionary)) {
      this.dictionary[answer] = {
        'Transitional Housing': 0,
        'Emergency Shelter': 0,
        'Safe Haven': 0,
        Unsheltered: 0,
      };
    } else this.dictionary[answer][shelterStatus] += 1;
  }
  getAnswers(): ReportLine[] {
    return Object.keys(this.dictionary).map(k => {
      const key = k as ReportTitle;
      const Unsheltered = this.dictionary[key].Unsheltered;
      const Emergency = this.dictionary[key]['Emergency Shelter'];
      const SafeHaven = this.dictionary[key]['Safe Haven'];
      const Transitional = this.dictionary[key]['Transitional Housing'];
      const Total = Unsheltered + Emergency + SafeHaven + Transitional;

      return {
        title: key,
        Unsheltered,
        Emergency,
        SafeHaven,
        Transitional,
        Total,
      };
    });
  }
}

export interface Feature {
  type: string;
  id: string;
  geometry: { coordinates: number[] | string; type: string };
}

export type Field =
  | 'Age'
  | 'Relation'
  | 'Veteran'
  | 'Ethnicity'
  | 'Race'
  | 'Gender';

export interface ShelterInformation {
  name: string;
  type: 'Emergency Shelter' | 'Transitional Housing' | 'Safe Haven';
  region?: string;
}

export type Shelter = ShelterInformation & id;

export type ShelterSet = {
  shelters: Shelter[];
  name: string;
  date: number;
  regionMapId?: string;
};

interface id {
  id: string;
}

export type ShelterSetWithId = ShelterSet & id;
