
import Vue from 'vue';
import { GroundTruthService } from '@/services/misc/ground-truth';
import Volunteer from '@/components/Cleanup/Volunteer.vue';
import Location from '@/components/Cleanup/Location.vue';
import Time from '@/components/Cleanup/Time.vue';
import HouseholdTypeDisplay from '@/components/Cleanup/HouseholdTypeDisplay.vue';
export default Vue.extend({
  name: 'HouseholdDetails',
  props: ['adminAdjustment', 'household', 'surveyID'],
  components: {
    Volunteer,
    Location,
    Time,
    HouseholdTypeDisplay,
  },
  computed: {
    surveyStatuses() {
      return GroundTruthService.GetSurveyStatuses();
    },
    housingStatuses() {
      return GroundTruthService.GetHousingStatuses();
    },
    shelterStatuses() {
      return GroundTruthService.GetShelterStatuses();
    }
  },
});
