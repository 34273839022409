import { FormatService } from '@/services/misc/format';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
export const DownloadService = {
  async downloadCSV(content: Record<string,string>[], filename: string) {
    const csv = await FormatService.jsonToCSV(content);
    downloadContent('data:text/json;charset=utf-8', csv, filename);
  },
  async downloadRaw(content: string, filename: string) {
    downloadContent('data:text/json;charset=utf-8', content, filename);
  },
  // downloadKmlStringAsKmz downloads the content as a kml inside a zipped folder
  // named .kmz, using rootName to name both the kml and kmz.
  async downloadKmlStringAsKmz(content: string, rootName: string) {
    const zip = new JSZip();
    zip.file(`${rootName}.kml`, content, {createFolders: false});
    const result = zip.generate({type: 'blob'});
    FileSaver.saveAs(result, `${rootName}.kmz`);
  }
};

function downloadContent(mimetype: string, content: string, filename: string) {
  const dataStr = `${mimetype},` + encodeURIComponent(content);
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', filename);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
