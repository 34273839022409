
import SheltersDataTable from '@/components/Shelters/SheltersExist.vue';
import SheltersGetStarted from '@/components/Shelters/SheltersGetStarted.vue';
import Vue from 'vue';
export default Vue.extend({
  components: { SheltersDataTable, SheltersGetStarted },
  computed: {
    hasShelters() {
      const shelters = this.$store.get('organization/shelters');
      return shelters && shelters.length > 0;
    },
  },
});
