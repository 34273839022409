
import Settings from '@/components/Utility/Settings.vue';
import { GroundTruthService } from '@/services/misc/ground-truth';
import { CurrentYear } from '@/services/pure/breakdown';
import Vue from 'vue';
export default Vue.extend({
  components: { Settings },
  props: {
    value: {},
  },
  computed: {
    races() {
      return GroundTruthService.GetRacesWithTitles(CurrentYear);
    },
  },
});
