
import Vue from 'vue';
import { DataService } from '@/services/pure/data';
import { ConclusionService } from '@/services/pure/conclusion';
import * as _ from 'lodash';
import {
  HouseholdMember,
  HeadOfHousehold,
  HeadOfHouseholdSubmission,
} from '../../../models/survey';
import CompareHouseholdDetails from '@/components/Cleanup/Compare/CompareHouseholdDetails.vue';
import CompareHouseholdMembers from '@/components/Cleanup/Compare/CompareHouseholdMembers.vue';
import CompareHouseholdButtons from '@/components/Cleanup/Compare/CompareHouseholdButtons.vue';
export default Vue.extend({
  props: ['one', 'two', 'surveyID'],
  components: {
    CompareHouseholdDetails,
    CompareHouseholdMembers,
    CompareHouseholdButtons,
  },
});
