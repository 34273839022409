
import { API } from '@/repositories/api';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Settings from '@/components/Utility/Settings.vue';
import Vue from 'vue';
import firebase from 'firebase';
import formRules from '@/services/misc/form-validation';
import { getKMLData } from '@/services/pure/kmz';
import GeographicService from '@/services/pure/geo';
import UploadCSV from '../Shelters/UploadCSV.vue';
import {
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Properties,
} from '@turf/turf';
export default Vue.extend({
  components: { Settings, UploadCSV },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
