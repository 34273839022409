import { Household } from './../models/survey';
import { Both } from "../Both";
import { CountYear } from './pure/breakdown';
export const findReportingYearForBoths = (both: Both[]): CountYear => {
    const latest = both.sort((a, b) => b.household.date - a.household.date)?.[0]?.household;
    return findReportingYearForHousehold(latest);
};

export const findReportingYearForHousehold = (household: Household): CountYear => {
    const in2021 = household?.date < new Date(2021, 8, 5).getTime();
    if (in2021) return '2021';
    const in2022 = household?.date < new Date(2022, 8, 5).getTime();
    if (in2022) return '2022';
    const in2023 = household?.date < new Date(2023, 8, 5).getTime();
    if (in2023) return '2023';
    return '2024';
}; 