import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import createPersistedState from 'vuex-persistedstate';
import { SurveyWithCode, paidThroughDefault } from './models/count';
import { Choice, Ele, Form, FormType, FormWrapper } from './models/formstructure';
import { SubmissionWrapper, VolunteerRegistration } from './models/survey';
import { CountyData } from './models/county';
import { Admin } from './models/Organization';
import pathify from "./plugins/vuex-pathify";
import * as modules from "./store/modules";
import { dispatch } from 'vuex-pathify';
import { getPrettyNameDict } from './services/shared/surveys';
Vue.use(Vuex);
const state = {
  user: null,
  admins: [],
  forms: [],
  name: '',
  surveys: [],
  users: {},
  formStructures: [],
  individualFormStructures: [],
  location: [0, 0],
  submissions: {},
  locationLookup: {},
  liveSurveys: {},
  volunteerRegistrations: {},
  adminAdjustments: {},
  hotspots: {},
  duplicates: {},
  events: {},
  dashboards: {},
  initialLoad: false,
  hasObservation: false,
  paidThrough: null,
  organizationID: null,
  counties: [],
  CoC: null,
  preferences: {},
  preferencesLoaded: false,
  notification: {},
}

function getEmptyState() {
  return _.cloneDeep(state);
}

export default new Vuex.Store({
  modules,
  plugins: [createPersistedState({
    paths: ["user", "organizationID", "CoC", "surveys", "paidThrough", "users"]
  }), pathify.plugin],
  state: getEmptyState(),
  getters: {
    toast(state: any) {
      return state.notification;
    },
    volunteerRegistrations(state: any): VolunteerRegistration[] {
      return state.volunteerRegistrations;
    },
    preferencesLoaded(state: any): boolean {
      return state.preferencesLoaded;
    },
    organizationID(state: any): string {
      return state.organizationID;
    },
    CoC(state: any): string | null {
      return state.CoC;
    },
    preferences(state: any): any {
      return state.preferences;
    },
    hasWriteAccess(state: any): boolean {
      const me = state.admins.filter(
        (a: Admin) => a.ID === state.user.email,
      )[0];

      // For first load;
      if (!me) return false;
      return me.permission === 'all';
    },
    hasAdminAdjustmentWriteAccess(state: any): boolean {
      const me: Admin = state.admins.filter(
        (a: Admin) => a.ID === state.user.email,
      )[0];

      // For first load;
      if (!me) return false;
      return me.permission === 'all' || me.permission === 'lead';
    },
    isLead(state: any): boolean {
      const me = state.admins.filter(
        (a: Admin) => a.ID === state.user.email,
      )[0];

      // For first load;
      if (!me) return false;
      return me.permission === 'lead' || me.permission === 'lead-read';
    },
    admins(state: any): Admin[] {
      return state.admins;
    },
    isTrialAccount(state: any): boolean {
      return state.paidThrough < Date.now();
    },
    paidThrough(state: any): number {
      return !state.paidThrough ? paidThroughDefault : state.paidThrough;
    },
    initialLoad(state: any): boolean {
      return state.initialLoad;
    },
    Survey: (state: any) => (code: string) => {
      const surveysWithCode = state.surveys.filter(
        (c: SurveyWithCode) => c.code === code,
      );
      return surveysWithCode[0];
    },
    submissions: (state: any) => {
      return state.submissions;
    },
    locationLookup: (state: any) => (code: string) => {
      const newLocation = state.locationLookup[code] ?? {};
      const survey = state.surveys.filter(
        (c: SurveyWithCode) => c.code === code,
      );
      const oldLocation = survey[0]?.locationLookup ?? {};
      return { ...oldLocation, ...newLocation };
    },
    adminAdjustments: (state: any) => (code: string) => {
      if (code in state.adminAdjustments) {
        return state.adminAdjustments[code];
      }
      return [];
    },
    hotspots: (state: any) => (code: string) => {
      if (code in state.hotspots && state.hotspots[code].length > 0) {
        console.log(state.hotspots);
        return state.hotspots[code];
      }
      const surveys: SurveyWithCode[] = state.surveys.filter(
        (c: SurveyWithCode) => c.code === code,
      );
      if (surveys.length === 0) return [];
      return surveys[0].hotspots ?? [];
    },
    dashboard: (state: any) => (code: string) => {
      if (code in state.dashboards) {
        return state.dashboards[code];
      }
      return null;
    },
    liveSurveys: (state: any) => (code: string) => {
      if (code in state.liveSurveys) {
        return state.liveSurveys[code];
      }
      return [];
    },
    duplicates: (state: any) => (code: string) => {
      if (code in state.duplicates) {
        return state.duplicates[code];
      }
      return [];
    },
    events: (state: any) => {
      return state.events;
    },
    surveys(state: any, getters: any): SurveyWithCode[] {
      if (getters.isLead) {
        // The most important thing is to prevent leads from reading data outside of their team.
        // That's already being handled by rules on the backend. The purpose of this is a convenience --
        // to only show them the PIT counts where they are assigned as leads. If they were to mess
        // with the javascript to allow them to view these counts they'd just see empty counts with
        // no data.
        const whereImLead = (state.surveys as SurveyWithCode[]).filter(s => {
          if (!s.leads) return false;
          const me = s.leads.find(l => l.ID === state.user.email);
          if (!me) return false;
          const myRegions = s.leads.find(l => l.ID === state.user.email)?.regions;
          if (!myRegions || myRegions.length === 0) return false;
          return true;
        });
        return whereImLead;
      }
      return state.surveys;
    },
    location(state: any) {
      return state.location;
    },
    loggedIn(state: any) {
      return state.user != null;
    },
    user(state: any) {
      return state.user;
    },
    organizationName(state: any) {
      return state.name;
    },
    hasObservation(state: any) {
      return typeof state.hasObservation === 'undefined'
        ? true
        : state.hasObservation;
    },
    formStructures(state: any) {
      // On the first save with the new code we'll write all structures
      // individually, but we still fall back to the organization level.
      const initial = state.formStructures;
      const individual = state.individualFormStructures;
      
      if (!!individual && individual.length > 0) return individual;
      return initial;
    },
    getPrettyNameDict: (state: any) => (stringStructure: string) => {
      return getPrettyNameDict(stringStructure);
    },
    counties(state: any): CountyData[] {
      return state.counties;
    },
    enterprise(state: any) {
      return state.enterprise;
    },
  },
  mutations: {
    logout(state: any) {
      Object.assign(state, getEmptyState())
      dispatch('organization/reset');
      dispatch('subregionscreen/reset');
      dispatch('survey/reset');
    },
    setNotification(state: any, { type, message }) {
      Vue.set(state, 'notification', { type, message, time: Date.now() });
    },
    setCountyData(state: any, counties: CountyData[]) {
      Vue.set(state, 'counties', counties);
    },
    organizationID(state: any, organizationID) {
      state.organizationID = organizationID;
    },
    setSubmissions(state: any, { code, submissions }) {
      if (
        state.submissions[code] &&
        state.submissions[code].length === submissions.length
      )
        return;
      Vue.set(state.submissions, code, submissions);
    },
    setLocationLookup(state: any, { code, locationLookup }) {
      if (
        state.locationLookup[code] &&
        state.locationLookup[code].length === locationLookup.length
      )
        return;
      Vue.set(state.locationLookup, code, locationLookup);
    },
    unionSubmissions(state: any, { code, submissions }) {
      const u = _.unionBy(state.submissions[code], submissions, (submission: SubmissionWrapper) => submission.householdID);
      Vue.set(state.submissions, code, u);
    },
    setDuplicates(state: any, { code, duplicates }) {
      if (
        duplicates &&
        state.duplicates &&
        state.duplicates[code] &&
        state.duplicates[code].length === duplicates.length &&
        _.isEqual(duplicates, state.duplicates[code])
      )
        return;
      Vue.set(state.duplicates, code, duplicates);
    },
    setEvents(state: any, { code, events }) {
      if (
        events &&
        state.events &&
        state.events[code] &&
        state.events[code].length === events.length &&
        _.isEqual(events, state.events[code])
      )
        return;
      Vue.set(state.events, code, events);
    },
    setDashboard(state: any, { code, dashboard }) {
      if (
        dashboard &&
        state.dashboards &&
        state.dashboards[code] &&
        _.isEqual(dashboard, state.dashboards[code])
      )
        return;
      Vue.set(state.dashboards, code, dashboard);
    },
    setLiveSurveys(state: any, { code, liveSurveys }) {
      if (
        liveSurveys &&
        state.liveSurveys &&
        state.liveSurveys[code] &&
        state.liveSurveys[code].length === liveSurveys.length &&
        _.isEqual(liveSurveys, state.liveSurveys[code])
      )
        return;
      Vue.set(state.liveSurveys, code, liveSurveys);
    },
    setVolunteerRegistrations(state: any, { code, volunteerRegistrations }) {
      if (
        volunteerRegistrations &&
        state.volunteerRegistrations &&
        state.volunteerRegistrations[code] &&
        state.volunteerRegistrations[code].length === volunteerRegistrations.length &&
        _.isEqual(volunteerRegistrations, state.liveSurveys[code])
      )
        return;
      Vue.set(state.volunteerRegistrations, code, volunteerRegistrations);
    },
    setAdminAdjustments(state: any, { code, adminAdjustments }) {
      if (
        adminAdjustments &&
        state.adminAdjustments &&
        state.adminAdjustments[code] &&
        state.adminAdjustments[code].length === adminAdjustments.length &&
        _.isEqual(adminAdjustments, state.adminAdjustments[code])
      )
        return;
      Vue.set(state.adminAdjustments, code, adminAdjustments);
    },
    setHotspots(state: any, { code, hotspots }) {
      if (
        hotspots &&
        state.hotspots &&
        state.hotspots[code] &&
        state.hotspots[code].length === hotspots.length &&
        _.isEqual(hotspots, state.hotspots[code])
      )
        return;
      Vue.set(state.hotspots, code, hotspots);
    },
    updateLocation(state: any, location: number[]) {
      if (location) {
        state.location = location;
      }
    },
    updateFormStructures(state: any, formStructures: string[]) {
      state.formStructures = formStructures;
    },
    updateUser(state: any, user: firebase.User) {
      state.user = user;
    },
    setAdmins(state: any, admins: Admin[]) {
      state.admins = admins;
    },
    updateForms(state: any, { forms }: { forms: Form[] }) {
      state.individualFormStructures = forms;
    },
    updateOrganization(
      state: any,
      { name, formStructures, hasObservation, paidThrough, enterprise, CoC },
    ) {
      if (name) {
        state.name = name;
      }
      if (formStructures) {
        state.formStructures = formStructures;
      }
      if (paidThrough) {
        state.paidThrough = paidThrough;
      }
      if (enterprise) {
        state.enterprise = enterprise;
      } else {
        state.enterprise = false;
      }

      if (CoC) {
        state.CoC = CoC;
      }

      state.hasObservation = hasObservation;
    },
    updateSurveys(state: any, { surveys }) {
      state.surveys = surveys;
      state.initialLoad = true;
    },
    updatePreferences(state: any, { preferences }) {
      state.preferences = preferences;
      state.preferencesLoaded = true;
    },
  },
  actions: {},
});
