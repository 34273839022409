
type Color = 'Green' | 'Blue' | 'Red' | 'Black';
import { Survey, SurveyWithCode } from '@/models/count';
import { FormType } from '@/models/formstructure';
import { Household, SurveyMapIcon } from '@/models/survey';
import { EntryService } from '@/services/entry';
import { SurveyService } from '@/services/pure/surveys';
import { Feature } from '@turf/turf';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Vue, { PropType } from 'vue';
import { RegionMap } from '../../../models/Organization';
export default Vue.extend({
  props: {
    surveys: {
      type: Array as PropType<Household[]>,
    },
    surveyID: {
      type: String,
    },
    survey: {
      type: Object as PropType<SurveyWithCode>,
    },
    filterBy: {
      type: String,
    },
  },
  computed: {
    isPITCount() {
      return this.survey.type === 'PIT Count';
    },
    teams(): string[] {
      const survey = this.$store.get('survey/survey');
      if (!survey.regionMap) return [];
      const regionMap: RegionMap = JSON.parse(survey.regionMap as string);
      const teams = regionMap.regions.features;
      return teams.map(t => t?.properties?.name);
    },
    icons(): SurveyMapIcon[] {
      const iconsMap = {
        'Household Type': {
          Youth: 'Green',
          Adults: 'Blue',
          Both: 'Red',
        },
        Veteran: {
          Standard: 'Blue',
          Veteran: 'Red',
        },
        Observation: {
          Standard: 'Blue',
          Observation: 'Green',
        },
        'Chronically Homeless': {
          Standard: 'Blue',
          'Chronically Homeless': 'Black',
        },
      };
      const category = (iconsMap as any)[this.filterBy];
      const options = Object.entries(category);
      const fullPaths = SurveyService.getIconPaths();
      const mapped = options.map(o => {
        const color = o[1] as Color;
        return {
          Name: o[0],
          ImagePath: fullPaths[color],
        };
      });
      return mapped;
    },
  },
  methods: {
    veteran(survey: Household): string {
      const icons = SurveyService.getIconPaths();
      const aa = EntryService.GetAdminAdjustment(survey, this.surveyID);
      const hasVeteran = aa.members.filter(m => m.Veteran === 'Yes').length > 0;
      return hasVeteran ? icons.Red : icons.Blue;
    },
    observation(survey: Household): string {
      const icons = SurveyService.getIconPaths();
      return survey.type === FormType.PITCountInterview
        ? icons.Blue
        : icons.Green;
    },
    team(household: Household): string {
      const index = this.teams.findIndex(t => t === household.teamName);
      const icons = SurveyService.getIconPaths();
      const iconCount = Object.keys(icons).length;
      const iconIndex = index % iconCount; // will always be between e.g. 0 -> 3
      const key = Object.keys(icons)[iconIndex];
      return (icons as any)[key];
    },
    chronicallyHomeless(survey: Household): string {
      const icons = SurveyService.getIconPaths();
      const aa = EntryService.GetAdminAdjustment(survey, this.surveyID);
      return aa.housingStatus === 'Chronically Homeless'
        ? icons.Black
        : icons.Blue;
    },
    generic(survey: Household): string {
      const icons = SurveyService.getIconPaths();
      return icons.Blue;
    },
    styleFactory(iconMethod: any) {
      return (feature: any) => {
        const householdIndex = feature.id_;
        const household = this.surveys.find(
          s => s.householdID === householdIndex,
        );
        const style = new Style({
          image: new Icon({
            src: iconMethod(household),
            scale: 1,
            anchor: [0.5, 1],
          }),
        });
        return [style];
      };
    },
    typeStyle() {
      return this.styleFactory(this.fromHousehold);
    },
    veteranStyle() {
      return this.styleFactory(this.veteran);
    },
    chronicStyle() {
      return this.styleFactory(this.chronicallyHomeless);
    },
    genericStyle() {
      return this.styleFactory(this.generic);
    },
    observationStyle() {
      return this.styleFactory(this.observation);
    },
    teamStyle() {
      return this.styleFactory(this.team);
    },
    fromHousehold(household: Household): string {
      const icons = SurveyService.getIconPaths();
      const aa = EntryService.GetAdminAdjustment(household, this.surveyID);
      const childCount = aa.members
        .map(m => m.Age)
        .filter(a => a === 'Under 18' || a === '18-24').length;
      if (childCount === aa.members.length) return icons.Green;
      if (childCount > 0) return icons.Red;
      return icons.Blue;
    },
  },
});
