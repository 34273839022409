
import Vue, { PropType } from 'vue';
import GeneralSettings from '@/components/PITCount/Settings/GeneralSettings.vue';
import LeadSettings from './Settings/LeadSettings.vue';
import { Survey } from '@/models/count';
import HotspotSettings from './Settings/HotspotSettings.vue';
export default Vue.extend({
  props: {
    survey: {
      type: Object as PropType<Survey>,
    },
  },
  components: {
    GeneralSettings,
    LeadSettings,
    HotspotSettings,
  },
});
