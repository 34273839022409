
import Settings from '@/components/Utility/Settings.vue';
import Vue from 'vue';
import { CurrentYear } from '@/services/pure/breakdown';
import { GroundTruthService } from '@/services/misc/ground-truth';
export default Vue.extend({
  components: { Settings },
  props: {
    value: {},
  },
  computed: {
    genders() {
      return GroundTruthService.GetGendersWithTitles(CurrentYear);
    },
  },
});
