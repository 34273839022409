
import Vue from 'vue';
import SubRegions from './SubRegions.vue';
import Settings from '@/components/Utility/Settings.vue';
import { getKMLData } from '@/services/pure/kmz';
import GeographicService from '@/services/pure/geo';
import SubRegionMap from '@/components/Maps/SubRegionMap.vue';
import { sync } from 'vuex-pathify';
import {
  Feature,
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Properties,
} from '@turf/turf';
import SheltersList from '@/components/Shelters/SheltersList.vue';
import NewShelterDialog from '@/components/Dialogs/NewShelterDialog.vue';
export default Vue.extend({
  components: { SubRegionMap, Settings, SheltersList, NewShelterDialog },
  data() {
    return {
      file: null,
      regionCollection: ({ features: [] } as unknown) as FeatureCollection<
        Geometry | GeometryCollection,
        Properties
      >,
      asArrayBuffer: null as null | ArrayBuffer | string,
      name: '',
      dialog: false,
    };
  },
});
