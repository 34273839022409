
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import { DataService } from '@/services/pure/data';
import Vue from 'vue';
import RegionMap from '../Maps/RegionMap.vue';
import SubRegions from './SubRegions.vue';
import Settings from '@/components/Utility/Settings.vue';
export default Vue.extend({
  components: { SubRegions, RegionMap, Settings },
  data() {
    return {
      cocs: DataService.getCoCList(),
      coc: (null as any) as string,
    };
  },
  mounted() {
    this.coc = this.$store.getters.CoC;
  },
  methods: {
    updateCoC(e: any) {
      const result = FirebaseRepository.setCoC(this.coc);
      if (result) {
        Notification.methods.send('success', 'CoC Updated');
      }
    },
  },
});
