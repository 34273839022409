
import Vue, { PropType } from 'vue';
import RegionSelection from '@/components/PITCount/Regions/RegionSelection.vue';
import { Survey } from '@/models/count';
import { RegionMap } from '@/models/Organization';
import SubRegionMap from '../Maps/SubRegionMap.vue';
import { SubmissionWrapper } from '@/models/survey';
export default Vue.extend({
  components: { RegionSelection, SubRegionMap },
  data() {
    return {
      feature: null,
    };
  },
  props: {
    survey: {
      type: Object as PropType<Survey>,
    },
    submissions: {
      type: Array as PropType<SubmissionWrapper[]>,
    },
  },
  computed: {
    regions(): RegionMap[] {
      return this.survey.regionMap ? JSON.parse(this.survey.regionMap) : null;
    },
  },
  methods: {
    regionChange(newValue: any) {
      this.feature = newValue;
    },
    getFirst(regionMap: any) {
      return regionMap.regions.features[0];
    }
  },
});
