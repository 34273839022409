
import Vue, { PropType } from 'vue';
import { FormType, FormWrapper } from '../../../../models/formstructure';
import { FormStructureService } from '../../../../services/pure/formstructure';
import { answer } from '../CreateSurveyDialog.vue';
export default Vue.extend({
  data() {
    return {
      form: null,
    };
  },
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    },
  },
  computed: {
    volunteerSignUpForms(): FormWrapper[] {
      return (this.forms as FormWrapper[]).filter(
        ss => ss.Type === FormType.VolunteerSignup,
      );
    },
    forms() {
      const structures: FormWrapper[] = this.$store.getters.formStructures;
      return structures.sort((a, b) => b.Edited - a.Edited);
    },
  },
  methods: {
    selectAnswer() {
      this.advance({ stepID: 'volunteerSignUpForm', answer: this.form });
    },
    getTitle(survey: FormWrapper) {
      return FormStructureService.GetTitle(survey);
    },
  },
});
