
import Vue, { PropType } from 'vue';
import VolunteerURL from '@/components/VolunteerURL.vue';
import { Survey, SurveyMode, SurveyWithCode } from '@/models/count';
import { CountService } from '@/services/count';
import { Notification } from '@/services/misc/notification';
import Settings from '@/components/Utility/Settings.vue';
export default Vue.extend({
  props: {
    survey: {
      type: Object as PropType<SurveyWithCode>,
      required: true,
    },
  },
  data() {
    return {
      name: this.survey.name,
      mode: this.survey.mode,
      nameRules: [(v: any) => !!v || 'Name required'],
    };
  },
  components: {
    VolunteerURL,
    Settings,
  },
  computed: {
    isChange(): boolean {
      return this.survey.name !== this.name || this.survey.mode !== this.mode;
    },
    modes(): SurveyMode[] {
      if (!!this.survey.volunteerSignupSurvey) {
        return ['Volunteer Registration', 'Training', 'Live', 'Finished'];
      }
      return ['Training', 'Live', 'Finished'];
    },
  },
  methods: {
    async save() {
      const newSurvey = this.survey;
      newSurvey.mode = this.mode;
      newSurvey.name = this.name;
      await CountService.update(newSurvey);
      Notification.methods.send('success', 'Survey updated');
    },
  },
});
