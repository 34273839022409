import { HeadOfHousehold, HouseholdMember } from '@/models/survey';
import { NoYesMissing, NoYesReasons } from '@/models/hmis/HMISShared';
import { HoHRelationship } from '@/models/hmis/Individual/HMISEnrollment';
import { AnalyticsService } from '@/services/pure/analytics';
export function mapRelation(member: HouseholdMember): HoHRelationship {
    switch (member['Relation']) {
        case 'Child':
            return 2;
        case 'Spouse':
        case 'Non-Married Partner':
            return 3;
        case 'Other Family':
            return 4;
        case 'Other, Non-Family':
            return 5;
        default:
            return 1;
    }
}
export function mapActiveMilitaryParent(member: (HouseholdMember | HeadOfHousehold), head: HeadOfHousehold): NoYesMissing {
    const asMember = member as HouseholdMember;
    if (!asMember['Relation']) {
        return 99;
    }
    return asMember['Relation'] === 'Child' && head.Veteran ? 1 : 0;
}
