import { DataService } from '@/services/pure/data';
import { getDateString, getTimeString, mapYesNo } from '../../hmis-utility';
import { HMISEnrollment } from '@/models/hmis/Individual/HMISEnrollment';
import { HMISDisabilities } from '@/models/hmis/Individual/HMISDisabilities';
import { HMISHealthAndDV } from '@/models/hmis/Individual/HMISHealthAndDV';
import { HeadOfHousehold, HouseholdMember } from '@/models/survey';

export const HMISHealthAndDVService = {
  getHealthAndDVRecord(enrollment: HMISEnrollment, householdMember: (HeadOfHousehold | HouseholdMember)): HMISHealthAndDV {
    const headOfHousehold = householdMember as HeadOfHousehold;

    const healthAndDVID = DataService.generateRandomId();
    const currentDate = new Date();
    const time = getTimeString(currentDate);
    const date = getDateString(currentDate);

    return {
      HealthAndDVID: healthAndDVID,
      DomesticViolenceVictim: mapYesNo(headOfHousehold["Experienced Domestic Violence"] as string),
      WhenOccurred: null,
      GeneralHealthStatus: null,
      DentalHealthStatus: null,
      MentalHealthStatus: null,
      PregnancyStatus: null,
      DueDate: date,
      InformationDate: date,
      DataCollectionStage: 5,
      ProjectEntryID: enrollment.ProjectEntryID,
      PersonalID: enrollment.PersonalID,
      DateCreated: time,
      DateUpdated: time,
      UserID: enrollment.UserID,
      DateDeleted: null,
      ExportID: enrollment.ExportID,
    }
  }
}