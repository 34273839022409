
import Vue, { PropType } from 'vue';
import { Survey } from '../../models/count';
import FullDataReport from '@/components/FullDataReport.vue';
import html2pdf from 'html2pdf.js';
import { Both } from '../../Both';
import { DataService } from '@/services/pure/data';
import { Conclusion } from '@/models/adminadjustment';
import Settings from '../Utility/Settings.vue';
import { HUDSection } from '../../models/all';
const allHUDSections: HUDSection[] = [
  'All Households with Children',
  'All Households without Children',
  'All Households with only Children',
  'Veteran Households with Children',
  'Veteran Households without Children',
  'Parenting Youth',
  'Unaccompanied Youth',
  'Additional Homeless Populations',
];
export default Vue.extend({
  props: {
    survey: {
      type: Object as PropType<Survey>,
      required: true,
    },
    both: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FullDataReport,
    Settings,
  },
  data() {
    return {
      showDialog: false,
      selectedQuestions: [],
      reportTitle: this.survey.name,
      reportSubTitle: this.survey.organizationName,
      selectedHUDSections: allHUDSections,
      waitingForDownload: false
    };
  },
  computed: {
    hudSections(): HUDSection[] {
      return allHUDSections;
    },
    conclusions(): Conclusion[] {
      return this.both.map(b => b.conclusion);
    },
    questions(): string[] {
      const households = this.both.map(b => b.household);
      const questions = DataService.getQuestions(households);
      const viableQuestions = questions.filter(q => {
        const answers = DataService.getAnswers(households, q);
        if (answers.length > 9) return false;
        const alreadyCovered = [
          'Veteran',
          'Race',
          'Gender',
          'Hispanic',
          'Relation',
          '"Mental Health Concern',
          'HIV/AIDS',
          'Substance Use',
          'Physical Disability',
        ];
        if (alreadyCovered.indexOf(q) !== -1) return false;
        return true;
      });
      return viableQuestions;
    },
  },
  methods: {
    downloadPDF() {
      this.waitingForDownload = true;
      const pages = [...document.querySelectorAll('.page')];
      const opt = {
        margin: 0.2,
        filename: 'survey_data_report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        // scrollX, scrollY prevent white space from being added to top
        // https://github.com/eKoopmans/html2pdf.js/issues/175#issuecomment-634832966
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };
      // We have to separate into multiple pages to get around maximum canvas size.
      var worker = html2pdf()
        .set(opt)
        .from(pages[0])
        .toPdf();
      pages.slice(1).forEach(function(page) {
        worker = worker
          .get('pdf')
          .then(function(pdf: any) {
            pdf.addPage();
          })
          .from(page)
          .toContainer()
          .toCanvas()
          .toPdf();
      });
      worker = worker.save().then(() => this.waitingForDownload = false)
    },
  },
});
