
import Vue from 'vue';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import { asArray, asString } from 'ol/color';
export default Vue.extend({
  name: 'Subregion',
  props: {
    features: {}
  },
  methods: {
      style() {
        return (feature: any) => {
          const [r, g, b] = Array.from(asArray(feature.values_.fill));
          const withAlpha = asString([r, g, b, .5]);
          // Use default stroke style and no fill style.
          return [new Style({
              stroke: new Stroke({
                  color: feature.values_.stroke,
                  width: 1.25
              }),
              fill: new Fill({
                color: withAlpha
              })
          })]
          }
      }
  }
});
