import { Condition } from './../../../../../models/adminadjustment';
import { ConclusionService } from '@/services/pure/conclusion';
import { DataService } from '@/services/pure/data';
import { getDateString, getTimeString, mapYesNo } from '../../hmis-utility';
import { HMISEnrollment } from '@/models/hmis/Individual/HMISEnrollment';
import { HMISDisabilities, HMISDisabilitiesHeaderRow, DisabilityType, DisabilityResponse } from '@/models/hmis/Individual/HMISDisabilities';
import { HeadOfHousehold, HouseholdMember } from '@/models/survey';

export const HMISDisabilitiesService = {
  getDisabilitiesRecords(enrollment: HMISEnrollment, householdMember: (HeadOfHousehold | HouseholdMember)): HMISDisabilities[] | null {
    const disabilities: HMISDisabilities[] = [];
    // Year doesn't actually matter here, just using for <18.
    const rawDisabilities = ConclusionService.GetConditions(householdMember, ConclusionService.GetAge(householdMember, '2022'));
    if (rawDisabilities.find(d => d === 'Physical Disability')){
      disabilities.push(this.getDisabilitiesRecord(enrollment, 5, hmisAnswerFromList(rawDisabilities, 'Physical Disability')));
    }
    if (rawDisabilities.find(d => d === 'Serious Mental Illness')){
      disabilities.push(this.getDisabilitiesRecord(enrollment, 9, hmisAnswerFromList(rawDisabilities, 'Serious Mental Illness')));
    }
    if (rawDisabilities.find(d => d === 'HIV/AIDS')){
      disabilities.push(this.getDisabilitiesRecord(enrollment, 8, hmisAnswerFromList(rawDisabilities, "HIV/AIDS")));
    }
    if (rawDisabilities.find(d => d === 'Substance Abuse Disorder')){
      disabilities.push(this.getDisabilitiesRecord(enrollment, 10,hmisAnswerFromList(rawDisabilities, 'Substance Abuse Disorder')));
    }

    return disabilities.length > 0 ? disabilities : null;
  },
  getDisabilitiesRecord(enrollment: HMISEnrollment, disabilityType: DisabilityType, disabilityResponse: DisabilityResponse): HMISDisabilities {
    const disabilitiesID = DataService.generateRandomId();
    const currentDate = new Date();
    const time = getTimeString(currentDate);
    const date = getDateString(currentDate);

    return {
      DisabilitiesID: disabilitiesID,
      InformationDate: date,
      DisabilityType: disabilityType,
      DisabilityResponse: disabilityResponse,
      IndefiniteAndImpairs: null,
      DocumentationOnFile: null,
      ReceivingServices: null,
      PATHHowConfirmed: null,
      PATHSMIInformation: null,
      DataCollectionStage: 5,
      ProjectEntryID: enrollment.ProjectEntryID,
      PersonalID: enrollment.PersonalID,
      DateCreated: time,
      DateUpdated: time,
      UserID: enrollment.UserID,
      DateDeleted: null,
      ExportID: enrollment.ExportID,
    }
  },
  getDisabilitiesHeaderRow(): HMISDisabilitiesHeaderRow {
    return {
      DisabilitiesID: null,
      InformationDate: null,
      DisabilityType: null,
      DisabilityResponse: null,
      IndefiniteAndImpairs: null,
      DocumentationOnFile: null,
      ReceivingServices: 0,
      PATHHowConfirmed: null,
      PATHSMIInformation: null,
      DataCollectionStage: null,
      ProjectEntryID: null,
      PersonalID: null,
      DateCreated: null,
      DateUpdated: null,
      UserID: null,
      DateDeleted: null,
      ExportID: null
    }
  }
}

function hmisAnswerFromList(list: Condition[], item: Condition) {
  if (list.find(l => l === item)) {
    return 1
  }

  if (list.find(l => l === 'None')) {
    return 0
  }

  return 99
}