
import { RegionMap } from '@/models/Organization';
import { Feature, FeatureCollection, Geometry, Properties } from '@turf/turf';
import SubRegionMap from '@/components/Maps/SubRegionMap.vue';
import Vue from 'vue';
export default Vue.extend({
  props: {
    id: {},
  },
  components: { SubRegionMap },
  computed: {
    region(): FeatureCollection {
      const regions = this.$store.get('organization/regions');
      return regions.find((r: RegionMap) => r.ID === this.id).regions;
    },
  },
});
