
import Vue from 'vue';
import SubRegions from './SubRegions.vue';
import Settings from '@/components/Utility/Settings.vue';
import { getKMLData } from '@/services/pure/kmz';
import GeographicService from '@/services/pure/geo';
import SubRegionMap from '@/components/Maps/SubRegionMap.vue';
import { sync } from 'vuex-pathify';
import { Feature, FeatureCollection, Geometry, GeometryCollection, Properties } from '@turf/turf';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import FileList from '@/components/Organization/SubRegions/FileList.vue';
import NewRegionMapDialog from '@/components/Dialogs/NewRegionMapDialog.vue';
export default Vue.extend({
  components: { SubRegionMap, Settings, FileList, NewRegionMapDialog },
  data() {
    return {
      file: null,
      regionCollection: { features: [] } as unknown as FeatureCollection<Geometry | GeometryCollection, Properties>,
      asArrayBuffer: null as null | ArrayBuffer | string,
      name: '',
      dialog: false
    }
  },
});
