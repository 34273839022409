
import { Admin, OrganizationPermission } from '@/models/Organization';
import { FirebaseRepository } from '@/repositories/firebase';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import { Notification } from '@/services/misc/notification';
import AddUserDialog from '@/components/Dialogs/AddUserDialog.vue';
import Vue from 'vue';
import ChangePermissionDropdown from './Users/ChangePermissionDropdown.vue';
export default Vue.extend({
  components: {
    ConfirmationDialog,
    AddUserDialog,
    ChangePermissionDropdown,
  },
  data() {
    return {
      showConfirmDialog: false,
      showAddDialog: false,
      selected: [],
      headers: [
        {
          text: 'User',
          align: 'start',
          value: 'ID',
        },
        { text: 'Permissions', value: 'permission' },
      ],
    };
  },
  computed: {
    admins() {
      const admins: Admin[] = this.$store.getters.admins;
      return admins;
    },
    me() {
      if (this.selected.length === 0) return false;
      if (
        ((this.selected[0] as any) as Admin).ID ===
        this.$store.getters.user.email
      )
        return true;
      return false;
    },
  },
  methods: {
    displayPermission(permission: OrganizationPermission) {
      switch (permission) {
        case 'all':
          return 'Full';
        case 'read':
          return 'Read-only';
        case 'lead':
          return 'PIT Lead';
        case 'lead-read':
          return 'PIT Lead (Read-only)';
      }
    },
    async revokeAccess(): Promise<void> {
      if (this.selected.length === 0) return;
      const selected = (this.selected[0] as any) as Admin;
      const id = selected.ID;
      const organizationID = this.$store.getters.organizationID;
      await FirebaseRepository.removeUserFromOrganization(id, organizationID);
      Notification.methods.send('success', 'User removed.');
      this.selected = [];
      this.showConfirmDialog = false;
    },
  },
});
