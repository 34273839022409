
import Vue from 'vue';
import { StringService } from '../services/misc/string';
import { CountService } from '@/services/count';
import { FormWrapper, FormType } from '@/models/formstructure';
import { FormStructureService } from '@/services/pure/formstructure';
import _ from 'lodash';
import { DataService } from '@/services/pure/data';
import { FirebaseRepository } from '@/repositories/firebase';

export default Vue.extend({
  props: ['survey', 'elevation', 'classes'],
  data() {
    return {
      deleteDialog: false,
    };
  },
  methods: {
    prettyDate(date: number) {
      return StringService.getPrettyDate(date);
    },
    formType(formType: FormType) {
      return this.isObservation(formType) ? 'Observation' : 'Interview';
    },
    surveyTitle(survey: FormWrapper) {
      return FormStructureService.GetTitle(survey);
    },
    isObservation(formType: FormType) {
      return formType === FormType.PITCountObservation;
    },
    isDefault(survey: FormWrapper) {
      return FormStructureService.IsDefaultSurvey(survey);
    },
    startDelete() {
      (this as any).deleteDialog = true;
    },
    finishDelete() {
      const forms = this.$store.getters.formStructures;
      const formRemoved: FormWrapper = this.survey;
      const index = forms.indexOf(this.survey);
      forms.splice(index, 1);
      FirebaseRepository.saveFormStructures(forms, [formRemoved.ID]);
    },
    navigateToSurvey() {
      this.$router.push({ path: `/edit-form/${this.survey.ID}` });
    },
  },
});
