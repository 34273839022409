
import Vue from 'vue';
import Map from '@/components/Maps/Map.vue';
import { Household } from '@/models/survey';
import _ from 'lodash';
import { Feature } from '@/models/all';

export default Vue.extend({
  props: ['id', 'submissions', 'submissionsGroupedByVolunteer'],
  components: {
    Map,
  },
  computed: {
    latestSubmissionForEachVolunteer(): Household[] {
      const s: _.Dictionary<Household[]> = this.submissionsGroupedByVolunteer;
      const latestList: Household[] = [];
      for (let volunteerIdentifier in this.submissionsGroupedByVolunteer) {
        const latest = (_(s[volunteerIdentifier])
          .orderBy('date')
          .last() as any) as Household;
        latestList.push(latest);
      }
      return latestList;
    },
    zoom(): number {
      const locations = this.knownLocations
        .map(s => s.latlng)
        .filter(s => Array.isArray(s));
      return locations.length > 0 ? 7 : 4.5;
    },
    location(): number[] {
      const locations = this.knownLocations
        .map(s => s.latlng)
        .filter(s => Array.isArray(s));
      if (locations.length > 0) {
        const lat = _.mean(locations.map(l => l[0]));
        const long = _.mean(locations.map(l => l[1]));
        return [lat, long];
      }
      return [-97, 35];
    },
    features(): any {
      const f = this.knownLocations.map((s: Household) => {
        return <Feature>{
          type: 'Feature',
          id: s.householdID,
          geometry: {
            type: 'Point',
            coordinates: s.latlng,
          },
        };
      });
      return f;
    },
    knownLocations(): Household[] {
      return this.latestSubmissionForEachVolunteer.filter(
        (s: Household) => s.latlng !== 'Unknown',
      );
    },
  },
});
