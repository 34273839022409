import {
  Conclusion,
  HouseholdMemberConclusions,
  HouseholdMemberConclusions2023,
  HouseholdMemberConclusions2024,
} from '../../models/adminadjustment';
import {
  Ethnicity,
  GenderTotals,
  GenderAnalytics2021,
  GenderAnalytics2022,
  Race,
  Race2024 as Race2024Anayltics,
  GenderAnalytics2024,
  Race2023,
} from '../../models/analytics';
import _, { filter } from 'lodash';
import { HUDSection } from '../../models/all';
import { ConclusionService, isAdultOrYouth } from './conclusion';
import { Gender2024, Race2024 } from '../../models/household-enums';

export type EndProperty =
  | 'TotalNumberHouseholds'
  | 'TotalNumberPeople'
  | 'TotalNumberPeopleChild'
  | 'TotalNumberPeopleYouth'
  | 'TotalNumberPeopleAdult'
  | 'Gender'
  | 'Ethnicity'
  | 'Race'
  | 'ChronicHouseholds'
  | 'ChronicMembers';

export type OneAdultAndOneChild =
  | OneAdultAndOneChild2022
  | OneAdultAndOneChild2023
  | OneAdultAndOneChild2024;
export interface OneAdultAndOneChild2022 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  TotalNumberPeopleChild: number;
  TotalNumberPeopleYouth: number;
  TotalNumberPeopleAdult: number;
  TotalNumberPeopleUnknownAge: number;
  Gender: GenderTotals;
  Ethnicity: Ethnicity;
  Race: Race;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export interface OneAdultAndOneChild2023 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  TotalNumberPeopleChild: number;
  TotalNumberPeopleYouth: number;
  TotalNumberPeople25To34: number;
  TotalNumberPeople35To44: number;
  TotalNumberPeople45To54: number;
  TotalNumberPeople55To64: number;
  TotalNumberPeople65Plus: number;
  TotalNumberPeopleUnknownAge: number;
  Gender: GenderTotals;
  Ethnicity: Ethnicity;
  Race: Race2023;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export interface OneAdultAndOneChild2024 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  TotalNumberPeopleChild: number;
  TotalNumberPeopleYouth: number;
  TotalNumberPeople25To34: number;
  TotalNumberPeople35To44: number;
  TotalNumberPeople45To54: number;
  TotalNumberPeople55To64: number;
  TotalNumberPeople65Plus: number;
  TotalNumberPeopleUnknownAge: number;
  Gender: GenderTotals;
  Race: Race2024Anayltics;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export type OnlyChildren = OnlyChildren2023 | OnlyChildren2024;

export interface OnlyChildren2023 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  Gender: GenderTotals;
  Ethnicity: Ethnicity;
  Race: Race2023;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export interface OnlyChildren2024 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  Gender: GenderTotals;
  Race: Race;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export type OnlyAdults = OnlyAdults2022 | OnlyAdults2023 | OnlyAdults2024;

export interface OnlyAdults2022 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  TotalNumberPeopleYouth: number;
  TotalNumberPeopleAdult: number;
  Gender: GenderTotals;
  Ethnicity: Ethnicity;
  Race: Race;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export interface OnlyAdults2023 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  TotalNumberPeopleYouth: number;
  TotalNumberPeople25To34: number;
  TotalNumberPeople35To44: number;
  TotalNumberPeople45To54: number;
  TotalNumberPeople55To64: number;
  TotalNumberPeople65Plus: number;
  TotalNumberPeopleUnknownAge: number;
  Gender: GenderTotals;
  Ethnicity: Ethnicity;
  Race: Race2023;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export interface OnlyAdults2024 {
  TotalNumberHouseholds: number;
  TotalNumberPeople: number;
  TotalNumberPeopleYouth: number;
  TotalNumberPeople25To34: number;
  TotalNumberPeople35To44: number;
  TotalNumberPeople45To54: number;
  TotalNumberPeople55To64: number;
  TotalNumberPeople65Plus: number;
  TotalNumberPeopleUnknownAge: number;
  Gender: GenderTotals;
  Race: Race2024;
  ChronicHouseholds: number;
  ChronicMembers: number;
}

export interface All {
  UnknownAge: OneAdultAndOneChild;
  OneAdultAndOneChild: OneAdultAndOneChild;
  OnlyChildren: OnlyChildren;
  OnlyAdults: OnlyAdults;
}

export interface Additional {
  MentalIllness: number;
  SubstanceAbuse: number;
  HIV: number;
  DomesticViolence: number;
}

export interface Veteran {
  OneAdultAndOneChild: OneAdultAndOneChild & { TotalNumberVeterans: number };
  OnlyAdults: OnlyAdults & { TotalNumberVeterans: number };
}

export interface ParentingYouth {
  TotalNumberHouseholds: number;
  TotalNumberPeopleParentingYouthU18: number;
  TotalNumberPeopleChildrenParentsU18: number;
  TotalNumberPeopleParentingYouth1824: number;
  TotalNumberPeopleChildrenParents1824: number;
}

export interface Youth {
  Parenting: ParentingYouth & OneAdultAndOneChild;
  Unaccompanied: OneAdultAndOneChild;
}

export interface Breakdown {
  All: All;
  Additional: Additional;
  Youth: Youth;
  Veteran: Veteran;
}

export interface BreakdownWrapper {
  Total: Breakdown;
  Unsheltered: Breakdown;
  Emergency: Breakdown;
  Transitional: Breakdown;
  SafeHaven: Breakdown;
  UnknownAgeHead: number;
  year: CountYear;
}

export const CurrentYear: CountYear = '2024';
export type CountYear = '2021' | '2022' | '2023' | '2024';

export const BreakdownFactory = {
  getBreakdownWrapper(
    conclusions: Conclusion[],
    year: CountYear,
  ): BreakdownWrapper {
    const unsheltered = conclusions.filter(
      c => c.shelterStatus === 'Unsheltered',
    );
    const emergency = conclusions.filter(
      c => c.shelterStatus === 'Emergency Shelter',
    );
    const transitional = conclusions.filter(
      c => c.shelterStatus === 'Transitional Housing',
    );
    const safeHaven = conclusions.filter(c => c.shelterStatus === 'Safe Haven');
    return {
      Total: getBreakdown(conclusions, year),
      Unsheltered: getBreakdown(unsheltered, year),
      Emergency: getBreakdown(emergency, year),
      Transitional: getBreakdown(transitional, year),
      SafeHaven: getBreakdown(safeHaven, year),
      UnknownAgeHead: conclusions.filter(c => c.members[0].Age === 'Unknown')
        .length,
      year: year,
    };
  },
};

export function HasPopulationMembers(
  conclusion: Conclusion,
  population: HUDSection,
): boolean {
  switch (population) {
    case 'All Households without Children':
      return hasOnlyAdults(conclusion);
    case 'All Households with only Children':
      return hasOnlyChildren(conclusion);
    case 'All Households with Children':
      return hasAdultAndChild(conclusion);
    case 'Veteran Households with Children':
      return hasVeteranAndChildren(conclusion);
    case 'Veteran Households without Children':
      return hasVeteranWithoutChildren(conclusion);
    case 'Parenting Youth':
      return hasParentingYouth(conclusion);
    case 'Unaccompanied Youth':
      return hasUnaccompaniedYouth(conclusion);
    case 'Additional Homeless Populations':
      return hasAdditionalHomelessPopulations(conclusion);
    case 'All Households with Unknown Ages':
      return hasUnknownAges(conclusion);
    default:
      return false;
  }
}

function getBreakdown(conclusions: Conclusion[], year: CountYear): Breakdown {
  return {
    All: getAll(conclusions, year),
    Additional: getAdditional(conclusions),
    Youth: getYouth(conclusions, year),
    Veteran: getVeteran(conclusions, year),
  };
}
function getYouth(conclusions: Conclusion[], year: CountYear): Youth {
  const parentingYouth = conclusions.filter(
    c => ConclusionService.GetYouthHouseholdType(c) === 'Parenting Youth',
  );
  const unaccompaniedYouth = conclusions.filter(
    c => ConclusionService.GetYouthHouseholdType(c) === 'Unaccompanied Youth',
  );
  return {
    Parenting: getParenting(parentingYouth, year),
    Unaccompanied: getBothBreakdown(unaccompaniedYouth, year),
  };
}

function hasParentOver18(conclusion: Conclusion): boolean {
  return (
    conclusion.members.filter(m => m.Relation === 'Head' && m.Age === '18-24')
      .length > 0
  );
}
function childrenCount(conclusions: Conclusion[]): number {
  let total = 0;
  for (const conclusion of conclusions) {
    total += conclusion.members.filter(m => m.Relation === 'Child').length;
  }
  return total;
}
function parentsCount(conclusions: Conclusion[]): number {
  let total = 0;
  for (const conclusion of conclusions) {
    total += conclusion.members.filter(
      m =>
        m.Relation === 'Head' ||
        m.Relation === 'Spouse' ||
        m.Relation === 'Non-Married Partner',
    ).length;
  }
  return total;
}

function getParenting(
  conclusions: Conclusion[],
  year: CountYear,
): ParentingYouth & OneAdultAndOneChild {
  const householdsWithOlderParents = conclusions.filter((h: Conclusion) =>
    hasParentOver18(h),
  );
  const householdsWithYoungerParents = conclusions.filter(
    (h: Conclusion) => !hasParentOver18(h),
  );

  const parentFn = (c: HouseholdMemberConclusions) =>
    c.Relation === 'Head' ||
    c.Relation === 'Spouse' ||
    c.Relation === 'Non-Married Partner';
  return {
    ...getBothBreakdown(conclusions, year, parentFn),
    TotalNumberPeopleChildrenParents1824: childrenCount(
      householdsWithOlderParents,
    ),
    TotalNumberPeopleChildrenParentsU18: childrenCount(
      householdsWithYoungerParents,
    ),
    TotalNumberPeopleParentingYouth1824: parentsCount(
      householdsWithOlderParents,
    ),
    TotalNumberPeopleParentingYouthU18: parentsCount(
      householdsWithYoungerParents,
    ),
    TotalNumberHouseholds: conclusions.length,
  };
}

function getAdditional(conclusions: Conclusion[]): Additional {
  const members = _.flatten(conclusions.map(c => c.members));
  return {
    DomesticViolence: members.filter(m => m.DomesticViolenceSurvivor === 'Yes')
      .length,
    HIV: members.filter(m => m.Conditions.includes('HIV/AIDS')).length,
    SubstanceAbuse: members.filter(m =>
      m.Conditions.includes('Substance Abuse Disorder'),
    ).length,
    MentalIllness: members.filter(m =>
      m.Conditions.includes('Serious Mental Illness'),
    ).length,
  };
}

function getVeteran(conclusions: Conclusion[], year: CountYear): Veteran {
  const veteranHouseholds = conclusions.filter(c =>
    _.some(c.members.map(m => m.Veteran), v => v === 'Yes'),
  );
  const filterFn = (c: HouseholdMemberConclusions) => c.Veteran === 'Yes';

  //  TODO: filter members to veterans before getting analytics
  const both = getOneAdultAndOneChild(veteranHouseholds, year, filterFn);
  const bothHouseholds = veteranHouseholds.filter(c => {
    return (
      c.members.find(m => m.Age === 'Under 18') &&
      c.members.find(m => isAdultOrYouth(m.Age))
    );
  });
  const bothMemberVeterans = _.flatten(
    bothHouseholds.map(h => h.members),
  ).filter(m => m.Veteran === 'Yes').length;
  const adultHouseholds = veteranHouseholds.filter(c => {
    return _.every(c.members, m => isAdultOrYouth(m.Age));
  });
  const adultMemberVeterans = _.flatten(
    adultHouseholds.map(h => h.members),
  ).filter(m => m.Veteran === 'Yes').length;
  const onlyAdults = getOnlyAdults(veteranHouseholds, year, filterFn);
  return {
    OneAdultAndOneChild: { ...both, TotalNumberVeterans: bothMemberVeterans },
    OnlyAdults: { ...onlyAdults, TotalNumberVeterans: adultMemberVeterans },
  };
}

function getAll(conclusions: Conclusion[], year: CountYear): All {
  return {
    UnknownAge: getUnknownAge(conclusions, year),
    OneAdultAndOneChild: getOneAdultAndOneChild(conclusions, year),
    OnlyAdults: getOnlyAdults(conclusions, year),
    OnlyChildren: getOnlyChildren(conclusions, year),
  };
}

function hasAdultAndChild(c: Conclusion) {
  return (
    !!c.members.find(m => m.Age === 'Under 18') &&
    !!c.members.find(m => isAdultOrYouth(m.Age))
  );
}

function hasOnlyAdults(c: Conclusion) {
  return _.every(c.members, m => isAdultOrYouth(m.Age));
}

function hasOnlyChildren(c: Conclusion) {
  return _.every(c.members, m => m.Age === 'Under 18');
}

function hasChildren(c: Conclusion) {
  return _.some(c.members, m => m.Age === 'Under 18');
}

function hasVeteran(c: Conclusion) {
  return _.some(c.members, m => m.Veteran === 'Yes');
}

function hasVeteranAndChildren(c: Conclusion) {
  return hasVeteran(c) && hasChildren(c);
}

function hasVeteranWithoutChildren(c: Conclusion) {
  return hasVeteran(c) && hasOnlyAdults(c);
}

function hasParentingYouth(c: Conclusion) {
  return ConclusionService.GetYouthHouseholdType(c) === 'Parenting Youth';
}

function hasUnaccompaniedYouth(c: Conclusion) {
  return ConclusionService.GetYouthHouseholdType(c) === 'Unaccompanied Youth';
}

function hasAdditionalHomelessPopulations(c: Conclusion) {
  return _.some(
    c.members,
    m =>
      m.DomesticViolenceSurvivor === 'Yes' ||
      (m.Conditions &&
        m.Conditions.length > 1 &&
        m.Conditions.some(c => c !== 'None')),
  );
}

function hasUnknownAges(c: Conclusion) {
  return _.some(c.members, m => m.Age === 'Unknown');
}

// filterFn is an optional function that can be used to filter conclusions
// that are reported for gender, race, and ethnicity.
function getOneAdultAndOneChild(
  conclusions: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OneAdultAndOneChild {
  const relevant = conclusions.filter(c => {
    return hasAdultAndChild(c);
  });
  return getBothBreakdown(relevant, year, filterFn);
}

function getUnknownAge(
  conclusions: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OneAdultAndOneChild {
  const relevant = conclusions.filter(c => {
    return c.members.filter(m => m.Age === 'Unknown').length > 0;
  });
  return getBothBreakdown(relevant, year, filterFn);
}

export function getBothBreakdown(
  relevant: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OneAdultAndOneChild {
  if (year == '2021' || year == '2022') {
    return getBothBreakdown2022(relevant, year, filterFn);
  }
  if (year === '2023') return getBothBreakdown2023(relevant, year, filterFn);
  return getBothBreakdown2024(relevant, year, filterFn);
}

export function getBothBreakdown2022(
  relevant: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OneAdultAndOneChild2022 {
  const members = _.flatten(
    relevant.map(c => c.members),
  ) as HouseholdMemberConclusions2023[];
  const chronicHouseholds = relevant.filter(
    a => a.housingStatus === 'Chronically Homeless',
  );

  const demoMembers = filterFn ? members.filter(filterFn) : members;
  const chronicMembers = _.flatten(chronicHouseholds.map(c => c.members));
  const chronicWithFilter = filterFn
    ? chronicMembers.filter(filterFn)
    : chronicMembers;
  return {
    TotalNumberHouseholds: relevant.length,
    TotalNumberPeople: members.length,
    TotalNumberPeopleAdult: members.filter(m => m.Age === '25+').length,
    TotalNumberPeopleChild: members.filter(m => m.Age === 'Under 18').length,
    TotalNumberPeopleYouth: members.filter(m => m.Age === '18-24').length,
    TotalNumberPeopleUnknownAge: members.filter(m => m.Age === 'Unknown')
      .length,
    Gender: getGender(demoMembers, year),
    Ethnicity: getEthnicity(demoMembers),
    Race: getRace(demoMembers, year),
    ChronicHouseholds: chronicHouseholds.length,
    ChronicMembers: chronicWithFilter.length,
  };
}

export function getBothBreakdown2023(
  relevant: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OneAdultAndOneChild2023 {
  const members = _.flatten(
    relevant.map(c => c.members),
  ) as HouseholdMemberConclusions2023[];
  const chronicHouseholds = relevant.filter(
    a => a.housingStatus === 'Chronically Homeless',
  );

  const demoMembers = filterFn ? members.filter(filterFn) : members;
  const chronicMembers = _.flatten(chronicHouseholds.map(c => c.members));
  const chronicWithFilter = filterFn
    ? chronicMembers.filter(filterFn)
    : chronicMembers;
  return {
    TotalNumberHouseholds: relevant.length,
    TotalNumberPeople: members.length,
    TotalNumberPeople25To34: members.filter(m => m.Age === '25-34').length,
    TotalNumberPeople35To44: members.filter(m => m.Age === '35-44').length,
    TotalNumberPeople45To54: members.filter(m => m.Age === '45-54').length,
    TotalNumberPeople55To64: members.filter(m => m.Age === '55-64').length,
    TotalNumberPeople65Plus: members.filter(m => m.Age === '65+').length,
    TotalNumberPeopleChild: members.filter(m => m.Age === 'Under 18').length,
    TotalNumberPeopleYouth: members.filter(m => m.Age === '18-24').length,
    TotalNumberPeopleUnknownAge: members.filter(m => m.Age === 'Unknown')
      .length,
    Gender: getGender(demoMembers, year),
    Ethnicity: getEthnicity(demoMembers),
    Race: getRace(demoMembers, year) as Race2023,
    ChronicHouseholds: chronicHouseholds.length,
    ChronicMembers: chronicWithFilter.length,
  };
}

export function getBothBreakdown2024(
  relevant: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OneAdultAndOneChild2024 {
  const members = _.flatten(
    relevant.map(c => c.members),
  ) as HouseholdMemberConclusions2024[];
  const chronicHouseholds = relevant.filter(
    a => a.housingStatus === 'Chronically Homeless',
  );

  const demoMembers = filterFn ? members.filter(filterFn) : members;
  const chronicMembers = _.flatten(chronicHouseholds.map(c => c.members));
  const chronicWithFilter = filterFn
    ? chronicMembers.filter(filterFn)
    : chronicMembers;
  return {
    TotalNumberHouseholds: relevant.length,
    TotalNumberPeople: members.length,
    TotalNumberPeople25To34: members.filter(m => m.Age === '25-34').length,
    TotalNumberPeople35To44: members.filter(m => m.Age === '35-44').length,
    TotalNumberPeople45To54: members.filter(m => m.Age === '45-54').length,
    TotalNumberPeople55To64: members.filter(m => m.Age === '55-64').length,
    TotalNumberPeople65Plus: members.filter(m => m.Age === '65+').length,
    TotalNumberPeopleChild: members.filter(m => m.Age === 'Under 18').length,
    TotalNumberPeopleYouth: members.filter(m => m.Age === '18-24').length,
    TotalNumberPeopleUnknownAge: members.filter(m => m.Age === 'Unknown')
      .length,
    Gender: getGender(demoMembers, year),
    Race: getRace(demoMembers, year) as Race2024Anayltics,
    ChronicHouseholds: chronicHouseholds.length,
    ChronicMembers: chronicWithFilter.length,
  };
}

function getOnlyAdults(
  conclusions: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OnlyAdults {
  const relevant = conclusions.filter(c => {
    return hasOnlyAdults(c);
  });
  if (year == '2021' || year == '2022') {
    return getOnlyAdultsBreakdown2022(relevant, year, filterFn);
  }
  return getOnlyAdultsBreakdown2023(relevant, year, filterFn);
}

export function getOnlyAdultsBreakdown2022(
  conclusions: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OnlyAdults2022 {
  const relevant = conclusions.filter(c => hasOnlyAdults(c));
  const members = _.flatten(
    relevant.map(c => c.members),
  ) as HouseholdMemberConclusions2023[];
  const chronicHouseholds = relevant.filter(
    a => a.housingStatus === 'Chronically Homeless',
  );

  const demoMembers = filterFn ? members.filter(filterFn) : members;
  const chronicMembers = _.flatten(chronicHouseholds.map(c => c.members));
  const chronicWithFilter = filterFn
    ? chronicMembers.filter(filterFn)
    : chronicMembers;
  return {
    TotalNumberHouseholds: relevant.length,
    TotalNumberPeople: members.length,
    TotalNumberPeopleAdult: members.filter(m => m.Age === '25+').length,
    TotalNumberPeopleYouth: members.filter(m => m.Age === '18-24').length,
    Gender: getGender(demoMembers, year),
    Ethnicity: getEthnicity(demoMembers),
    Race: getRace(demoMembers, year),
    ChronicHouseholds: chronicHouseholds.length,
    ChronicMembers: chronicWithFilter.length,
  };
}

export function getOnlyAdultsBreakdown2023(
  relevant: Conclusion[],
  year: CountYear,
  filterFn?: (c: HouseholdMemberConclusions) => boolean,
): OnlyAdults2023 {
  const members = _.flatten(
    relevant.map(c => c.members),
  ) as HouseholdMemberConclusions2023[];
  const chronicHouseholds = relevant.filter(
    a => a.housingStatus === 'Chronically Homeless',
  );

  const demoMembers = filterFn ? members.filter(filterFn) : members;
  const chronicMembers = _.flatten(chronicHouseholds.map(c => c.members));
  const chronicWithFilter = filterFn
    ? chronicMembers.filter(filterFn)
    : chronicMembers;
  return {
    TotalNumberHouseholds: relevant.length,
    TotalNumberPeople: members.length,
    TotalNumberPeople25To34: members.filter(m => m.Age === '25-34').length,
    TotalNumberPeople35To44: members.filter(m => m.Age === '35-44').length,
    TotalNumberPeople45To54: members.filter(m => m.Age === '45-54').length,
    TotalNumberPeople55To64: members.filter(m => m.Age === '55-64').length,
    TotalNumberPeople65Plus: members.filter(m => m.Age === '65+').length,
    TotalNumberPeopleYouth: members.filter(m => m.Age === '18-24').length,
    TotalNumberPeopleUnknownAge: members.filter(m => m.Age === 'Unknown')
      .length,
    Gender: getGender(demoMembers, year),
    Ethnicity: getEthnicity(demoMembers),
    Race: getRace(demoMembers, year) as Race2023,
    ChronicHouseholds: chronicHouseholds.length,
    ChronicMembers: chronicWithFilter.length,
  };
}

function getOnlyChildren(
  conclusions: Conclusion[],
  year: CountYear,
): OnlyChildren {
  const relevant = conclusions.filter(c => {
    return _.every(c.members, m => m.Age === 'Under 18');
  });
  const members = _.flatten(relevant.map(c => c.members));
  const chronicHouseholds = relevant.filter(
    a => a.housingStatus === 'Chronically Homeless',
  );
  const chronicMembers = _.flatten(chronicHouseholds.map(c => c.members));
  if (['2021', '2022', '2023'].includes(year)) {
    const members2023 = members as HouseholdMemberConclusions2023[];
    return {
      TotalNumberHouseholds: relevant.length,
      TotalNumberPeople: members.length,
      Gender: getGender(members, year),
      Ethnicity: getEthnicity(members2023),
      Race: getRace(members2023, year),
      ChronicHouseholds: chronicHouseholds.length,
      ChronicMembers: chronicMembers.length,
    };
  }
  const members2024 = members as HouseholdMemberConclusions2024[];
  return {
    TotalNumberHouseholds: relevant.length,
    TotalNumberPeople: members.length,
    Gender: getGender(members, year),
    Race: getRace(members2024, year),
    ChronicHouseholds: chronicHouseholds.length,
    ChronicMembers: chronicMembers.length,
  };
}

function getGender(
  members: HouseholdMemberConclusions[],
  year: CountYear,
): GenderTotals {
  if (year === '2021') return getGender2021(members);
  if (year === '2022' || year === '2023') return getGender2022(members);
  return getGender2024(members as HouseholdMemberConclusions2024[]);
}

function getGender2021(
  members: HouseholdMemberConclusions[],
): GenderAnalytics2021 {
  return {
    Male: members.filter(m => m.Gender === 'Male').length,
    Female: members.filter(m => m.Gender === 'Female').length,
    Transgender: members.filter(
      m =>
        m.Gender === 'Transgender FTM' ||
        m.Gender === 'Transgender MTF' ||
        m.Gender === 'Transgender',
    ).length,
    Nonconforming: members.filter(m => m.Gender === 'Gender Non-Conforming')
      .length,
    Unknown: members.filter(m => m.Gender === 'Unknown').length,
  };
}

function getGender2022(
  members: HouseholdMemberConclusions[],
): GenderAnalytics2022 {
  return {
    Male: members.filter(m => m.Gender === 'Male').length,
    Female: members.filter(m => m.Gender === 'Female').length,
    Transgender: members.filter(
      m =>
        m.Gender === 'Transgender FTM' ||
        m.Gender === 'Transgender MTF' ||
        m.Gender === 'Transgender',
    ).length,
    Questioning: members.filter(m => m.Gender === 'Questioning').length,
    Nonconforming: members.filter(m => m.Gender === 'Gender Non-Conforming')
      .length,
    Multiple: members.filter(m => m.Gender === 'Multiple').length,
    Unknown: members.filter(m => m.Gender === 'Unknown').length,
  };
}

function getGender2024(
  members: HouseholdMemberConclusions2024[],
): GenderAnalytics2024 {
  const isOnly = (m: HouseholdMemberConclusions2024, answer: Gender2024) => {
    return (!Array.isArray(m.Gender) && m.Gender === answer) || (Array.isArray(m.Gender) && m.Gender.length === 1 && m.Gender[0] === answer);
  };
  const isMultipleAndIncludes = (m: HouseholdMemberConclusions2024, answer: Gender2024) => {
    return Array.isArray(m.Gender) && m.Gender.length > 1 && m.Gender.includes(answer);
  };
  return {
    Male: members.filter(m => isOnly(m, 'Male')).length,
  Female: members.filter(m => isOnly(m, 'Female')).length,
  Transgender: members.filter(
    m =>
      isOnly(m, 'Transgender')
  ).length,
  Questioning: members.filter(m => isOnly(m, 'Questioning')).length,
  CulturallySpecificIdentity: members.filter(m =>
    isOnly(m, 'Culturally Specific Identity')
  ).length,
  DifferentIdentity: members.filter(m => isOnly(m, 'Other')).length,
  Nonconforming: members.filter(m =>
    isOnly(m, 'Gender Non-Conforming')
  ).length,
    Multiple: members.filter(m => Array.isArray(m.Gender) && m.Gender.length > 1).length,
    Unknown:  members.filter(m => m.Gender.includes('Unknown')).length,
    MultipleIncludesWoman: members.filter(m => isMultipleAndIncludes(m, 'Female'))
    .length,
  MultipleIncludesMan: members.filter(m => isMultipleAndIncludes(m, 'Male'))
    .length,
  MultipleIncludesCulturallySpecificIdentity: members.filter(m =>
    isMultipleAndIncludes(m, 'Culturally Specific Identity')
  ).length,
  MultipleIncludesTransgender: members.filter(
    m =>
      isMultipleAndIncludes(m, 'Transgender')
  ).length,
  MultipleIncludesNonConforming: members.filter(
    m => isMultipleAndIncludes(m, 'Gender Non-Conforming')
  ).length,
  MultipleIncludesQuestioning: members.filter(m =>
    isMultipleAndIncludes(m, 'Questioning')
  ).length,
  MultipleIncludesDifferentIdentity: members.filter(m =>
    isMultipleAndIncludes(m, 'Other')).length,
  };
}

function getEthnicity(members: HouseholdMemberConclusions2023[]): Ethnicity {
  return {
    Hispanic: members.filter(m => m.Ethnicity === 'Hispanic').length,
    Non: members.filter(m => m.Ethnicity === 'Non-Hispanic').length,
    Unknown: members.filter(m => m.Ethnicity === 'Unknown').length,
  };
}

function getRace(members: HouseholdMemberConclusions[], year: CountYear): Race {
  if (['2021', '2022', '2023'].includes(year)) {
    return getRace2023(members as HouseholdMemberConclusions2023[]);
  }
  return getRace2024(members as HouseholdMemberConclusions2024[]);
}

function getRace2023(members: HouseholdMemberConclusions2023[]): Race {
  return {
    White: members.filter(m => m.Race === 'White').length,
    Black: members.filter(m => m.Race === 'Black/African American').length,
    Asian: members.filter(m => m.Race === 'Asian').length,
    PacificIslander: members.filter(
      m => m.Race === 'Native Hawaiian/Other Pacific Islander',
    ).length,
    Indian: members.filter(m => m.Race === 'American Indian/Alaskan Native')
      .length,
    Multiple: members.filter(m => m.Race === 'Multiple').length,
    Unknown: members.filter(m => m.Race === 'Unknown').length,
  };
}

function getRace2024(members: HouseholdMemberConclusions2024[]): Race {
  const isOnly = (m: HouseholdMemberConclusions2024, answer: Race2024) => {
    return (!Array.isArray(m.Race) && m.Race === answer) || (Array.isArray(m.Race) && m.Race.length === 1 && m.Race[0] === answer);
  };
  const isOnlyAndHispanic = (
    m: HouseholdMemberConclusions2024,
    answer: Race2024,
  ) => {
    return (
      Array.isArray(m.Race) && 
      m.Race.length === 2 &&
      m.Race.includes(answer) &&
      m.Race.includes('Hispanic/Latina/e/o')
    );
  };
  const multipleNotHispanic = (m: HouseholdMemberConclusions2024) => {
    return Array.isArray(m.Race) && m.Race.length > 1 && !m.Race.includes('Hispanic/Latina/e/o');
  };
  const multipleHispanic = (m: HouseholdMemberConclusions2024) => {
    return Array.isArray(m.Race) && m.Race.length > 2 && m.Race.includes('Hispanic/Latina/e/o');
  };
  return {
    White: members.filter(m => isOnly(m, 'White')).length,
    Black: members.filter(m => isOnly(m, 'Black/African American')).length,
    Asian: members.filter(m => isOnly(m, 'Asian')).length,
    PacificIslander: members.filter(m =>
      isOnly(m, 'Native Hawaiian/Other Pacific Islander'),
    ).length,
    Indian: members.filter(m => isOnly(m, 'American Indian/Alaskan Native'))
      .length,
    Hispanic: members.filter(m => isOnly(m, 'Hispanic/Latina/e/o')).length,
    MiddleEastern: members.filter(m =>
      isOnly(m, 'Middle Eastern or North African'),
    ).length,
    MultipleNotHispanic: members.filter(m => multipleNotHispanic(m)).length,
    WhiteAndHispanic: members.filter(m => isOnlyAndHispanic(m, 'White')).length,
    BlackAndHispanic: members.filter(m =>
      isOnlyAndHispanic(m, 'Black/African American'),
    ).length,
    AsianAndHispanic: members.filter(m => isOnlyAndHispanic(m, 'Asian')).length,
    PacificIslanderAndHispanic: members.filter(m =>
      isOnlyAndHispanic(m, 'Native Hawaiian/Other Pacific Islander'),
    ).length,
    IndianAndHispanic: members.filter(m =>
      isOnlyAndHispanic(m, 'American Indian/Alaskan Native'),
    ).length,
    MiddleEasternAndHispanic: members.filter(m =>
      isOnlyAndHispanic(m, 'Middle Eastern or North African'),
    ).length,
    MultipleAndHispanic: members.filter(m => multipleHispanic(m)).length,
    Unknown: members.filter(m => isOnly(m, 'Unknown')).length,
  };
}
