import { LocationRecord, SurveyWithCode } from "../../models/count";
import { Ele, FormType } from "../../models/formstructure";
import { SubmissionWrapper, Household, HeadOfHousehold } from "../../models/survey";
import { ConclusionService } from "../pure/conclusion";
import _ from 'lodash';
import { Age } from "../../models/household-enums";
import * as Survey from 'survey-knockout';
export const getHouseholdsFromSubmissionWrappers = (
  submissionWrappers: SubmissionWrapper[],
  survey: SurveyWithCode,
  locationLookup: Record<string,LocationRecord>
): Household[] => {
  const mainForm = new Survey.SurveyModel(survey.interviewSurvey);
  const households = [] as Household[];
  const prettyNameDict = getPrettyNameDict(survey);
  for (const [index, wrapper] of submissionWrappers.entries()) {
    if (!wrapper || !wrapper.survey) continue;
    const household = getHousehold(
      wrapper,
      survey,
      prettyNameDict,
      index,
      mainForm,
      locationLookup,
    );
    households.push(household);
  }
  return households;
}

function getPrettyNameDict(survey: SurveyWithCode) {
  const dict = {} as any;
  function getDisplayNameFromElement(element: Ele) {
    if (_.isObject(element.title)) {
      return !!element.title.default ? element.title.default : element.name;
    } else {
      return !!element.title ? element.title : element.name;
    }
  }
  function addElement(element: Ele) {
    dict[element.name] = getDisplayNameFromElement(element);

    if (element.templateElements) {
      for (const templateElement of element.templateElements) {
        addElement(templateElement);
      }
    }
  }
  function add(obj: any) {
    for (const page of obj.pages) {
      if (!page.elements) continue;
      for (const element of page.elements) {
        addElement(element);
      }
    }
  }

  if (survey.observationalSurvey) add(JSON.parse(survey.observationalSurvey));
  add(JSON.parse(survey.interviewSurvey));
  return dict;
}

export const hasDisability = (head: HeadOfHousehold): boolean | null => {
  // Year doesn't actually matter here, just used for < 18.
  const results = ConclusionService.GetConditions(head, ConclusionService.GetAge(head, '2022'));
  if (results.length === 1 && results[0] === 'None') return false;
  if (results.length === 0) return null;
  return true;
};

export const getHousehold = (
  wrapper: SubmissionWrapper,
  survey: SurveyWithCode,
  prettyNameDict: any,
  index: number,
  form: Survey.SurveyModel,
  locationLookup: Record<string,LocationRecord>,
): Household => {
  wrapper = mapOldProperties(wrapper);

  const volunteerID = wrapper.identifier;
  let { identifier, ...household }: any = { ...wrapper };

  // Provide blank answers for all questions so that even if no one answers them they show up in the data export.
  const questionNames = form.getAllQuestions().map(q => q.name);
  let answers: Record<string, any> = {};
  for (let name of questionNames) {
    answers[name] = '';
  }
  for (let question of Object.keys(wrapper.survey)) {
    answers[question] = (wrapper.survey as any)[question];
  }
  // let answers = {} as any;

  household.volunteerID = volunteerID;
  const latlng = household.location;
  delete household.location;
  setLocationInfoOnHousehold(household, locationLookup, latlng);
  delete household.head;
  const isObservational = isObservation(wrapper);
  const isOldStyle = !answers['Age'] && survey.created < Date.UTC(2022, 5, 1);
  if (isObservational && isOldStyle) {
    const noHouseholdMembers =
      !answers.HouseholdMembers ||
      answers.HouseholdMembers.length === 0;
    if (noHouseholdMembers) answers.HouseholdMembers = [{} as any];
    household = { ...household, ...answers };
  } else {
    household.HouseholdMembers = [{ ...answers }];
    delete household.HouseholdMembers[0].HouseholdMembers;
    if (answers.HouseholdMembers) {
      household.HouseholdMembers = household.HouseholdMembers.concat(
        answers.HouseholdMembers,
      );
    }
  }
  delete household.survey;
  household.IsObservationSurvey = isObservational;
  // one based for user consumption
  household.index = index + 1;
  const householdPrettyCustomQuestions = mapPrettyCustomQuestions(
    household,
    prettyNameDict,
  );

  return householdPrettyCustomQuestions;
}

export const isObservation = (wrapper: SubmissionWrapper): boolean => {
  if (!!wrapper.type) return wrapper.type === FormType.PITCountObservation;
  return 'Observation Survey Reason' in wrapper.survey;
}


const mapOldProperties = (wrapper: SubmissionWrapper): SubmissionWrapper => {
  for (const conversion of getConversions()) {
    wrapper.survey = mapProperty(
      wrapper.survey as any,
      conversion[0],
      conversion[1],
    );
    if (wrapper.survey.HouseholdMembers) {
      for (let member of wrapper.survey.HouseholdMembers) {
        member = mapProperty(member as any, conversion[0], conversion[1]);
      }
    }
  }
  return wrapper;
};

function getKey(latlng: number[]): string {
  const long = latlng[0];
  const lat = latlng[1];
  return `${long}|${lat}`.split('.').join(' ');
}

function setLocationInfoOnHousehold(
  household: Household,
  locationLookup: Record<string,LocationRecord>,
  latlng: number[] | string | null,
) {
  household.county = 'Unknown';
  household.address = 'Unknown';
  household.zipcode = 'Unknown';
  household.city = 'Unknown';
  if (_.isEqual(latlng, [-87, 41]) || !latlng || !Array.isArray(latlng)) {
    household.latlng = 'Unknown';
    return;
  }
  household.latlng = latlng;
  const key = getKey(latlng as number[]);
  if (!locationLookup || !(key in locationLookup)) return;
  const location = locationLookup[key];
  if (location.administrative_area_level_2)
    household.county = location.administrative_area_level_2;
  if (location.formatted_address)
    household.address = location.formatted_address;
  if (location.locality) household.city = location.locality;
  if (location.postal_code) household.zipcode = location.postal_code;
}

function mapPrettyCustomQuestions(household: Household, dict: any) {
  const copyOfHousehold = _.cloneDeep(household);
  replaceQuestionsInPlaceList(copyOfHousehold.HouseholdMembers as any, dict);
  return copyOfHousehold;
}

const mapProperty = (survey: any, oldProp: string, newProp: string) => {
  if (!!survey[oldProp] && oldProp !== newProp) {
    survey[newProp] = survey[oldProp];
    delete survey[oldProp];
  }
  return survey;
};
const getConversions = () => {
  const baseConversions = [
    ['WhereTheyStayedLastNight', 'Where They Stayed Last Night'],
    ['CompletedSurveyBefore', 'Completed Survey Before'],
    ['InFacilityLessThan90Days', 'Was In Facility Less Than 90 Days'],
    [
      'WhereTheyStayedBeforeInstitution',
      'Where They Stayed Before Institution',
    ],
    [
      'HomelessBeforeFacilityForAYearOrMore',
      'Homeless Before Institution For a Year Or More',
    ],
    ['Initials', 'Initials Or Name'],
    ['FirstTimeBeingHomeless', 'First Time Being Homeless'],
    ['HomelessnessDuration', 'Homelessness Duration'],
    ['TimesHomelessInLastThreeYears', 'Times Homeless In Last Three Years'],
    ['MonthsHomelessInLastThreeYears', 'Months Homeless In Last Three Years'],
    ['VeteranStatus', 'Veteran'],
    ['UsesDrugsOrAlcohol', 'Substance Use'],
    ['HasAPsychiatricCondition', 'Mental Health Concern'],
    ['HasPhysicalDisability', 'Physical Disability'],
    ['HasAIDSOrRelatedIllness', 'HIV/AIDS'],
    ['ExperiencedDomesticViolence', 'Experienced Domestic Violence'],
    ['otherCharacteristics', 'Notes'],
    ['whyUsingObservational', 'Observation Survey Reason'],
    ['HouseholdRelation', 'Relation'],
    ['personalID', 'Personal ID'],
    // These are for the below cases.
    ['Gender', 'Gender'],
    ['Race', 'Race'],
    ['Hispanic', 'Hispanic'],
    ['Age', 'Age'],
    ['Substance Use', 'Substance Use'],
    ['Mental Health Concern', 'Mental Health Concern'],
    ['Physical Disability', 'Physical Disability'],
    ['HIV/AIDS', 'HIV/AIDS'],
    ['Experienced Domestic Violence', 'Experienced Domestic Violence'],
    ['Veteran', 'Veteran'],
    ['Notes', 'Notes'],
    ['HasDisability', 'HasDisability'],
    ['DisabilityType', 'DisabilityType']
  ];
  // Some edge case old surveys.
  const ones = baseConversions.map(bc => [bc[0] + '1', bc[1]]);
  const household = baseConversions.map(bc => [bc[0] + 'Household', bc[1]]);
  return baseConversions.concat(ones).concat(household);
};

export const replaceQuestionsInPlaceList = (
  entries: Record<string, string>[],
  dict: Record<string, string>,
) => {
  for (const entry of entries) {
    for (const [key, val] of Object.entries(entry)) {
      if (key.indexOf('CustomQuestion') !== -1 && dict[key]) {
        const title = dict[key];
        delete (entry as any)[key];
        (entry as any)[title] = val;
      }
    }
  }
};
