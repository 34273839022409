
import Vue from 'vue';
export default Vue.extend({
  props: {
    message: {
      type: String,
    },
    isFiltered: {
      type: Boolean,
    },
  },
  methods: {
    filterToProblematic() {
      const oldQuery = this.$route.query;
      const newParams = { approvalStatus: 'Need Attention', tab: 'Data' };

      let newQuery = this.isFiltered
        ? { ...oldQuery, ...newParams }
        : { surveyStatus: 'Live', ...newParams };
      this.$router.push({ path: this.$route.path, query: newQuery });
    },
    routeToExtrapolationTool() {
      const oldQuery = this.$route.query;
      const newParams = { tab: 'Tools', tool: 'extrapolate' };

      let newQuery = this.isFiltered
        ? { ...oldQuery, ...newParams }
        : { surveyStatus: 'Live', ...newParams };
      this.$router.push({
        path: this.$route.path,
        query: newQuery,
      });
    },
  },
});
