
import Vue from 'vue';
import _ from 'lodash';
import { Household } from '@/models/survey';
import { DataService } from '@/services/pure/data';
import { FormType } from '@/models/formstructure';
import FilterButton from '@/components/Cleanup/FilterButton/FilterButton.vue';
import DownloadButton from '@/components/Cleanup/DownloadButton.vue';
import EditButton from '@/components/Cleanup/EditButton.vue';
import { Display } from '@/models/cleanup';
import CompareButton from '@/components/Cleanup/CompareButton.vue';
import DuplicatesButton from '@/components/Cleanup/DuplicatesButton.vue';
import { Both } from '../../Both';
import { EntryService } from '@/services/entry';
import { missing } from '../../services/pure/conclusion';
import TrialWarning from '../TrialWarning.vue';

export default Vue.extend({
  name: 'DeDuplication',
  props: ['submissions', 'survey', 'filtered'],
  components: {
    FilterButton,
    DownloadButton,
    EditButton,
    CompareButton,
    DuplicatesButton,
    TrialWarning,
  },
  watch: {
    selected: function (newValue, oldValue) {
      const selectedHouseholds = newValue.map((selected: Display) => {
        return selected.wrapper;
      });
      this.$emit('selectedHouseholds', selectedHouseholds);
    },
  },
  data() {
    return {
      items: [] as Display[],
      selected: [] as Display[],
      sortBy: 'Completed',
      sortDesc: true,
      search: '',
    };
  },
  computed: {
    hasMissing(): boolean {
      return missing(this.filtered).length > 0;
    },
    missingCount(): number {
      return missing(this.filtered).length;
    },
    tableHeaders() {
      let tableHeaders = [
        { text: 'ID', value: 'DisplayID' },
        { text: 'Status', value: 'SurveyStatus' },
        { text: 'Head of Household', value: 'Head of Household' },
        { text: 'Household Size', value: 'Household Size' },
        { text: 'Type', value: 'Type' },
        { text: 'Volunteer', value: 'Volunteer' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Details', value: 'actions', sortable: false },
      ];
      if (!this.isPITCount)
        tableHeaders = tableHeaders.filter(h => h.text !== 'Type');
      return tableHeaders;
    },
    isPITCount(): boolean {
      return this.survey.type === 'PIT Count';
    },
    householdsWithAdminAdjustments(): Both[] {
      return this.submissions.map((household: Household) => {
        const adminAdjustment = EntryService.GetAdminAdjustment(
          household,
          this.survey.code,
        );
        return {
          household,
          conclusion: adminAdjustment,
        };
      });
    },
    data(): Display[] {
      const mapped = this.filtered.map((wrapper: Both) => {
        const displayId = this.$store.getters.preferences?.useUUIDs ? wrapper.household.householdID.slice(0, 8) : wrapper.household.index;
        return {
          ID: wrapper.household.householdID,
          DisplayID: displayId,
          SurveyStatus: wrapper.conclusion.surveyStatus,
          'Head of Household': (wrapper.household.HouseholdMembers[0] as any)[
            'Initials Or Name'
          ],
          housingStatus: wrapper.conclusion.housingStatus,
          shelterStatus: wrapper.conclusion.shelterStatus,
          Completed: new Date(wrapper.household.date),
          Type:
            wrapper.household.type === FormType.PITCountInterview
              ? 'Interview'
              : 'Observation',
          Volunteer: `${wrapper.household.volunteerName
            } ${DataService.formatPhone(wrapper.household.volunteerPhone)}`,
          'Household Size': wrapper.household.HouseholdMembers.length,
          wrapper,
        };
      });
      return mapped;
    },
    selectedHouseholds(): Both[] {
      const selected = this.selected as Display[];
      const selectedHouseholds = selected.map((selected: Display) => {
        return selected.wrapper;
      });
      return selectedHouseholds;
    },
    filteredAfterSearch(): Display[] {
      return (this.$refs.table as any).$children[0].filteredItems;
    },
    filteredHouseholds() {
      const filteredAfterSearch: Display[] = this.filteredAfterSearch;
      return filteredAfterSearch.map(selected => {
        return selected.wrapper.household;
      });
    },
  },
  methods: {
    filterToMissing() {
      this.$router.push({
        path: this.$route.path,
        query: { missingInformation: 'Yes' },
      });
    },
    navigateTo(survey: any) {
      this.$router.push({
        path: `/survey/${this.survey.code}/household/${survey.ID}`,
      });
    },
  },
});
