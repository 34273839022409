import { NameDataQuality, SSNDataQuality, DOBDataQuality } from '@/models/hmis/quality';
import { QualityExport } from '@/models/hmis/Individual/HMISClient';

export enum NameQuality {
  Full = 'Full name reported',
  Partial = 'Partial, street, anonymous, or code name reported',
};
export const HMISQuality = {
  mapNameQuality(nameQuality?: NameDataQuality): QualityExport {
    if (!nameQuality) return 99;
    switch (nameQuality) {
      case NameQuality.Full:
        return 1;
      case NameQuality.Partial:
        return 2;
      default:
        return this.baseQualityMap(nameQuality)
    }
  },
  mapSSNQuality(ssnQuality?: SSNDataQuality): QualityExport {
    if (!ssnQuality) return 99;
    switch (ssnQuality) {
      case 'Full SSN reported':
        return 1;
      case 'Approximate or partial reported':
        return 2;
      default:
        return this.baseQualityMap(ssnQuality)
    }
  },
  mapDOBQuality(dobQuality?: DOBDataQuality): QualityExport {
    if (!dobQuality) return 99;
    switch (dobQuality) {
      case 'Full DOB reported':
        return 1;
      case 'Approximate or Partial DOB reported':
        return 2;
      default:
        return this.baseQualityMap(dobQuality)
    }
  },
  mapFieldQuality(quality: string, field: string): QualityExport {
    switch (field) {
      case 'DOB': return this.mapDOBQuality(quality as DOBDataQuality);
      case 'SSN': return this.mapSSNQuality(quality as SSNDataQuality);
      case 'Name': return this.mapNameQuality(quality as NameDataQuality);
      default: return this.baseQualityMap(quality);
    }
  },
  baseQualityMap(base: string) {
    switch (base) {
      case 'Client doesn\'t know':
        return 8;
      case 'Client refused':
        return 9;
      default:
        return 99;
    }
  }
};
