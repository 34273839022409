
import { API } from '@/repositories/api';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Vue from 'vue';
import firebase from 'firebase';
import { permissions } from '@/models/Organization';
import { LoggingService } from '@/services/logging';
export default Vue.extend({
  data() {
    return {
      email: '',
      permission: permissions[0],
      loading: false,
    };
  },
  computed: {
    inviteDisabled() {
      if (!this.email || !this.permission) return true;
      return false;
    },
    options() {
      return permissions;
    },
  },
  methods: {
    async invite(e: any) {
      e.preventDefault();
      this.loading = true;
      const email = this.email.trim();
      let { success: result, error } = await API.methods.createUser(email);
      if (!result) {
        Notification.methods.send(
          'error',
          error as string,
        );
        this.loading = false;
        return;
      }
      LoggingService.Log('Created user');
      result = await FirebaseRepository.setUserPermissionsInOrganization(
        email,
        this.$store.getters.organizationID,
        this.permission.value,
      );
      if (!result) {
        Notification.methods.send(
          'error',
          'Something went wrong while adding the user',
        );
        this.loading = false;
        return;
      }
      LoggingService.Log('Set user permissions');

      let result2 = await FirebaseRepository.sendResetEmail(
        firebase.auth(),
        email,
      );
      if (!result2.success) {
        Notification.methods.send('error', result2.errorMessage);
        this.loading = false;
        return;
      }
      LoggingService.Log('Sent reset email');
      Notification.methods.send('success', 'User invited.');
      this.loading = false;
      this.$emit('close');
    },
  },
});
