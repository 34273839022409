
import Vue from 'vue';
import { Household } from '@/models/survey';
import * as _ from 'lodash';
import Store from '@/store';
export default Vue.extend({
  props: ['submissions'],
  data() {
    return { location: [0, 0] };
  },
  mounted() {
    let locations = (this.submissions as Household[])
      .filter(s => Array.isArray(s.latlng))
      .map(s => s.latlng) as number[][];
    if (locations.length !== 0) {
      const lat = _.mean(locations.map((l: number[]) => l[0]));
      const long = _.mean(locations.map((l: number[]) => l[1]));

      this.location = [lat, long];
      return;
    }
    this.location = [0, 0];
  },
  computed: {
    rotation() {
      return 0;
    },
    zoom() {
      let locations = (this.submissions as Household[])
        .filter(s => Array.isArray(s.latlng))
        .map(s => s.latlng) as number[][];
      if (locations.length < 1) return 7;
      if (locations.length < 2) return 8;
      const latDistance = Math.abs(locations[1][1] - locations[0][1]);
      const longDistance = Math.abs(locations[0][0] - locations[1][0]);
      const maxDistance = Math.max(latDistance, longDistance);
      if (maxDistance < 0.25) return 10;
      if (maxDistance < 0.5) return 9;
      if (maxDistance < 1) return 8;
      if (maxDistance < 1.5) return 7.5;
      return 7;
    },
    submissionsWithLocations(): Household[] {
      return this.getSubmissionsWithLocations(this.submissions);
    },
  },
  methods: {
    getSubmissionsWithLocations(submissions: Household[]) {
      return submissions.filter(s => Array.isArray(s.latlng));
    },
  },
});
