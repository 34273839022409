
import {
  Admin,
  OrganizationPermission,
  permissions,
} from '@/models/Organization';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    selected: {
      type: Array as PropType<Admin[]>,
    },
    me: {},
  },
  methods: {
    async select(selected: { text: string; value: OrganizationPermission }) {
      try {
        const organizationID = this.$store.getters.organizationID;
        await FirebaseRepository.setUserPermissionsInOrganization(
          this.selected[0].ID,
          organizationID,
          selected.value,
        );
        Notification.methods.send('success', 'User permission updated.');
      } catch (err) {
        Notification.methods.send('error', 'Something went wrong');
      }
    },
  },
  computed: {
    permissions() {
      return permissions;
    },
  },
});
