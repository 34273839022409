
import Vue, { PropType } from 'vue';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
import { Both} from "../../../Both"
import CompareComponent from '@/components/Cleanup/Compare/Compare.vue';
import { DeDuplicationCluster } from '../../../models/DeDuplicationResult';
import { DeduplicatonService } from '../../../services/pure/deduplication';
import { EntryService } from '../../../services/entry';
import DoneCleaning from '@/components/Cleanup/DoneCleaning.vue';

export default Vue.extend({
  components: {
    SurveyListener,
    CompareComponent,
    DoneCleaning,
  },
  props: {
    filtered: {
      type: Array as PropType<Both[]>,
    },
  },
  computed: {
    surveyID() {
      return this.$route.params.id;
    },
    duplicates(): DeDuplicationCluster[] {
      const duplicates = DeduplicatonService.methods.findDuplicates(
        this.filtered,
        this.surveyID,
      );
      return duplicates;
    },
    one(): Both | null {
      if (this.duplicates.length === 0) return null;
      return this.getBoth(this.duplicates[0].originalID) as Both;
    },
    two(): Both | null {
      if (this.duplicates.length === 0) return null;
      return this.getBoth(this.duplicates[0].duplicateIDs[0]) as Both;
    },
  },
  methods: {
    getBoth(householdID: string) {
      const both: Both[] = this.filtered;
      return both.find(b => b.household.householdID === householdID);
    },
  },
});
