
import Vue, { PropType } from 'vue';
import { GroundTruthService } from '@/services/misc/ground-truth';
import { SurveyService } from '@/services/pure/surveys';
import { ConclusionService } from '@/services/pure/conclusion';
import IndividualDetails from '@/components/Cleanup/IndividualDetails.vue';
import { ages } from '../../models/all';
import { Gender } from '../../models/household-enums';
import { Household, HouseholdMember } from '../../models/survey';
import { findReportingYearForHousehold } from '../../services/year-finder';
import { AdminAdjustment } from '../../models/adminadjustment';

export default Vue.extend({
  props: {
    householdMember: {
      type: Object as PropType<HouseholdMember>,
    },
    adminAdjustment: {
      type: Object as PropType<AdminAdjustment>,
    },
    household: {
      type: Object as PropType<Household>,
    },
  },
  name: 'Individual',
  components: {
    IndividualDetails,
  },
  mounted(): void {
    // @ts-ignore
    this.conclusions = this.adminAdjustment;
  },
  watch: {
    conclusions: {
      deep: true,
      handler(newVal) {
        // @ts-ignore
        this.$emit('change', {
          conclusions: newVal,
          // @ts-ignore
          id: this.householdMember['Personal ID'],
        });
      },
    },
    adminAdjustment: {
      deep: true,
      handler(newVal) {
        // @ts-ignore
        this.conclusions = this.adminAdjustment;
      },
    },
  },
  data() {
    return {
      householdMember: null,
      adminAdjustment: null,
      conclusions: {
        Gender: null,
        Race: null,
        Age: null,
        Ethnicity: null,
        Veteran: null,
        SeriousMentalIllness: null,
        SubstanceAbuseDisorder: null,
        Hivaids: null,
        DomesticViolenceSurvivor: null,
        Conditions: [],
      },
    };
  },
  computed: {
    shouldIncludeEthnicity(): boolean {
      // @ts-ignore
      return ['2021', '2022', '2023'].includes(findReportingYearForHousehold(this.household))
    },
    name(): string {
      // @ts-ignore
      const member = this.householdMember as HouseholdMember;
      const nameFromSurvey = ConclusionService.GetName(member);
      return !!nameFromSurvey ? nameFromSurvey : 'Unknown Name';
    },
    genders(): { value: Gender; text: string }[] {
      // @ts-ignore
      const year = findReportingYearForHousehold(this.household);
      return GroundTruthService.GetGendersWithTitlesConclusions(year);
    },
    ages() {
      // @ts-ignore
      const year = findReportingYearForHousehold(this.household);
      return ages(year);
    },
    ethnicities() {
      return GroundTruthService.GetEthnicities();
    },
    races() {
      // @ts-ignore
      const year = findReportingYearForHousehold(this.household);
      return GroundTruthService.GetRacesWithTitlesConclusions(year);
    },
    relations() {
      return GroundTruthService.GetRelations();
    },
    conditions() {
      return GroundTruthService.GetConditions();
    },
    yesNo() {
      return ['Yes', 'No'];
    },
  },
});
