import { LoggingService } from './../services/logging';
import axios, { AxiosResponse } from 'axios';
import firebase, { auth } from 'firebase';
import Store from '@/store';
import { FirebaseRepository } from './firebase';
export interface RegisterPayload {
  email: string;
  password: string;
  name: string;
  phoneNumber: string;
  organizationID: string;
}

export interface CreateUserResult {
  success: boolean;
  error?: string;
}
export const API = {
  methods: {
    async createPITCountBackendMapping(
      organizationID: string,
      code: string,
    ): Promise<string> {
      try {
        const createMapping = firebase
          .functions()
          .httpsCallable('createRoomMapping');
        const payload = {
          organizationId: organizationID,
          code,
        };
        const result = await createMapping(payload);
        return result.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getCode(): Promise<string> {
      try {
        const createMapping = firebase.functions().httpsCallable('getCode');
        const result = await createMapping();
        return result.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async checkCode(code: string): Promise<boolean> {
      try {
        const checkCode = firebase.functions().httpsCallable('checkCode');
        const payload = {
          code,
        };
        const result = await checkCode(payload);
        return result.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async loginAdmin(
      auth: firebase.auth.Auth,
      email: string,
      password: string,
    ): Promise<AuthResult> {
      return login(auth, email.toLowerCase(), password);
    },
    async createUser(email: string): Promise<CreateUserResult> {
      try {
        const addUser = firebase.functions().httpsCallable('addUser');
        const payload = {
          email: email.trim(),
          organizationID: Store.getters.organizationID,
        };
        const result = (await addUser(payload)).data;
        return { success: result, error: undefined };
      } catch (err) {
        LoggingService.Log('error creating user');
        LoggingService.Log((err as any).message);
        LoggingService.Log(err);
        return { success: false, error: (err as any).message };
      }
    },
    async registerAdmin(
      auth: firebase.auth.Auth,
      email: string,
      password: string,
      organizationName: string,
    ): Promise<AuthResult> {
      try {
        const register = firebase.functions().httpsCallable('register');
        const payload = {
          email,
          password,
          organizationName,
        };
        const result = (await register(payload)).data;
        if (result !== Result.Success) return result;
        const credentials = await auth.signInWithEmailAndPassword(
          email,
          password,
        );

        return {
          result: Result.Success,
          credentials,
        };
      } catch (error) {
        console.log(error);
        const errorCode = error.code;
        // Catch this errorCode to know if user exists
        if (errorCode === 'auth/email-already-in-use') {
          // Call function X to sign user in instead
          return {
            credentials: undefined,
            result: Result.AccountExists,
          };
        }
        const response: AxiosResponse<any> = error.response;
        if (response.status === 409) {
          return {
            credentials: undefined,
            result: Result.AccountExists,
          };
        }
        return {
          credentials: undefined,
          result: Result.InternalServerError,
        };
      }
    },
  },
};

async function login(
  auth: firebase.auth.Auth,
  email: string,
  password: string,
): Promise<AuthResult> {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(credentials => {
      return {
        credentials,
        result: Result.Success,
      };
    })
    .catch(err => {
      console.log(err);
      if (err.code === 'auth/wrong-password') {
        return {
          credentials: undefined,
          result: Result.IncorrectPassword,
        };
      }
      return {
        credentials: undefined,
        result: Result.InternalServerError,
      };
    });
}

export enum Result {
  Success = 0,
  AccountExists,
  IncorrectPassword,
  InternalServerError,
}

interface AuthResult {
  credentials?: firebase.auth.UserCredential;
  result: Result;
}
