
import Vue, { PropType } from 'vue';
import VeteranBoth from '@/components/reporting/VeteranBoth.vue';
import VeteranAdults from '@/components/reporting/VeteranAdults.vue';
import { BreakdownWrapper } from '@/services/pure/breakdown';

export default Vue.extend({
  props: {
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
      required: true,
    },
    report: {
      type: Boolean,
    },
  },
  components: { VeteranBoth, VeteranAdults },
});
