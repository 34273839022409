
import { Shelter } from '@/models/all';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    shelters: {
      type: Array as PropType<Shelter[]>,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Type', value: 'type' },
        { text: 'Region', value: 'region' },
      ],
    };
  },
});
