
import Vue, { PropType } from 'vue';
import AllHomelessPopulations from '@/components/reporting/AllHomelessPopulations.vue';
import AdditionalHomelessPopulations from '@/components/reporting/AdditionalHomelessPopulations.vue';
import YouthPopulations from '@/components/reporting/YouthPopulations.vue';
import VeteranPopulations from '@/components/reporting/VeteranPopulations.vue';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
import { BreakdownFactory, BreakdownWrapper, CountYear } from '@/services/pure/breakdown';
import { Conclusion } from '../models/adminadjustment';
export default Vue.extend({
  components: {
    AllHomelessPopulations,
    AdditionalHomelessPopulations,
    YouthPopulations,
    VeteranPopulations,
    SurveyListener,
  },
  props: {
    conclusions: {
      type: Array as PropType<Conclusion[]>,
    },
    year: {
      type: String as PropType<CountYear>,
    }
  },
  computed: {
    breakdown(): BreakdownWrapper {
      return BreakdownFactory.getBreakdownWrapper(this.conclusions, this.year);
    }
  }
});
