
import Vue from 'vue';
import FormEditor from '@/components/Forms/FormEditor.vue';
import { Form, FormType, FormWrapper } from '@/models/formstructure';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import { Survey, SurveyType } from '@/models/count';
import { structureFromSurvey } from '../services/shared/surveys';
export default Vue.extend({
  props: ['id', 'type'],
  components: {
    FormEditor
  },
  // Pass in the actual structure
  // Control where it's saved to and how saving works (automatically vs not)
  computed: {
    structure(): Form {
      return structureFromSurvey(this.survey, this.type) as any as Form;
    },
    formType(): FormType {
      if (this.survey.type === 'Other') return FormType.Other;
      if (this.type === FormType.PITCountInterview) return FormType.PITCountInterview;
      if (this.type === FormType.VolunteerSignup) return FormType.VolunteerSignup;
      return FormType.PITCountObservation;
    },
    survey(): Survey {
      return (this as any).$store.getters.Survey(this.id);
    },
    saveMethod() {
      return () => {}
  }
  }
});
