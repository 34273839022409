
import Vue from 'vue';
import { FeatureCollection } from '@turf/turf';
import GeographicService from '@/services/pure/geo';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';

export default Vue.extend({
  name: 'Boundaries',
  props: {
    url: {},
    resetCenter: {
      type: Boolean,
    },
  },
  watch: {
    url: {
      async handler(newVal: string) {
        this.updateCenter(newVal);
      },
    },
  },
  methods: {
    async updateCenter(url: string) {
      if (!this.resetCenter) return;
      const response = await fetch(url);
      const json: FeatureCollection = await response.json();
      const center = GeographicService.getCenter(json);
      this.$emit('setCenter', center.geometry.coordinates);
    },
    style() {
      return (feature: any) => {
        // Use default stroke style and no fill style.
        return [
          new Style({
            stroke: new Stroke({
              color: '#3399CC',
              width: 2.5,
            }),
          }),
        ];
      };
    },
  },
});
