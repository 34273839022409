import { ConclusionService } from '@/services/pure/conclusion';
import { HouseholdMember } from '@/models/survey';
import { NoYesReasons } from '@/models/hmis/HMISShared';
import _ from 'lodash';
import { Age } from '../../../../../models/household-enums';
export function mapDisablingCondition(member: HouseholdMember): NoYesReasons {
  // Year doesn't actually matter here, just used for < 18.
  const results = ConclusionService.GetConditions(member, ConclusionService.GetAge(member, '2022'));

  if (results.length === 1 && results[0] === 'None') return 0;
  if (results.length === 0) return 8;
  return 1;
}
