
import Vue, { PropType } from 'vue';
import AllBoth from '@/components/reporting/AllBoth.vue';
import AllChildren from '@/components/reporting/AllChildren.vue';
import AllAdults from '@/components/reporting/AllAdults.vue';
import { BreakdownWrapper } from '@/services/pure/breakdown';
import AllUnknownAges from './AllUnknownAge.vue';

export default Vue.extend({
  components: { AllBoth, AllChildren, AllAdults, AllUnknownAges},
  props: {
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
      required: true,
    },
    report: {
      type: Boolean,
    },
  },
});
