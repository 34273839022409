import { FormWrapper } from '@/models/formstructure';
import _ from 'lodash';

export const FormStructureService = {
  IsDefaultSurvey(wrapper: FormWrapper) {
    const preloaded = ['2021Interview', '2021InterviewAlternate', '2021Observation',
    '2022Interview', '2022InterviewAlternate', '2022Observation', '2023VolunteerSignup'];
    return preloaded.includes(wrapper.ID);
  },
  GetTitle(wrapper: FormWrapper) {
    try {
      const element = JSON.parse(wrapper.Structure as any);
      if (_.isObject(element.title)) {
        return !!element.title.default ? element.title.default : element.name;
      } else {
        return !!element.title ? element.title : 
          !!element.name ? element.name : '';
      }
    } catch(err) {
      return 'INVALID QUESTION FORM'
    }
  },
};
