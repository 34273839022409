
import Vue from 'vue';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import { CountService } from '@/services/count';
import UserList from '@/components/Organization/userlist.vue';
import Region from '@/components/Organization/Region.vue';
import SubRegions from '@/components/Organization/SubRegions.vue';
export default Vue.extend({
  components: {
    SubRegions,
    UserList,
    Region,
  },
  data() {
    return {
      organizationName: this.$store.getters.organizationName,
      hasObservation: this.$store.getters.hasObservation,
    };
  },
  methods: {
    async updateOrganization(e: any) {
      e.preventDefault();
      const result = await FirebaseRepository.setOrganizationName(
        this.organizationName,
      );
      if (result) {
        Notification.methods.send('success', 'Organization Name Updated');
      }
    },
  },
});
