
import Vue from 'vue'
import CompareDialog from '@/components/Dialogs/CompareDialog.vue';
import { HeadOfHousehold } from '../../../models/survey';
import * as _ from 'lodash';
export default Vue.extend({
   props: ['one', 'two'],
   components: {
    CompareDialog,
   },
     data() {
    return {
      showDialog: false,
      members: [],
    };
  },
  methods: {
    compare(members: any) {
      this.members = members;
      this.showDialog = true;
    },
    getName(member: HeadOfHousehold) {
      return member['Initials Or Name']
        ? member['Initials Or Name']
        : 'Unknown Name';
    },
  },
  computed: {
    memberZip() {
      const one = this.one.household.HouseholdMembers;
      const two = this.two.household.HouseholdMembers;
      return _.zip(one, two);
    },
  },
})
