
import Vue from 'vue';
import { HouseholdType } from '@/models/household-enums';
import { ConclusionService } from '@/services/pure/conclusion';
import DetailBox from '@/components/Cleanup/DetailBox.vue';

export default Vue.extend({
  components: {
    DetailBox,
  },
  props: ['adminAdjustment'],
  computed: {
    householdType(): HouseholdType {
      return ConclusionService.GetHouseholdType(this.adminAdjustment);
    },
  },
});
