import {
  Feature,
  FeatureCollection,
  GeometryCollection,
  Point,
  Polygon,
} from '@turf/turf';
import * as turf from '@turf/turf';
import { kml } from './geo/kml';
const GeographicService = {
  getCenter(content: Feature | FeatureCollection): Feature<Point> {
    return turf.centerOfMass(content);
  },
  isInside(collection: FeatureCollection, point: number[]) {
    if (
      !collection.features ||
      collection.features.length === 0 ||
      !collection.features[0].geometry
    )
      return false;
    const geometry = collection.features[0].geometry;
    if (geometry.type === 'Polygon')
      return this.isInsidePolygon(geometry as Polygon, point);
    if (geometry.type === 'GeometryCollection') {
      const geometryCollection = geometry as GeometryCollection;
      for (const geo of geometryCollection.geometries) {
        if (geo.type === 'Polygon' && this.isInsidePolygon(geo, point))
          return true;
      }
      return false;
    }
    return turf.inside(
      {
        type: 'Point',
        coordinates: point,
      },
      geometry as Polygon,
    );
  },
  isInsidePolygon(polygon: Polygon, point: number[]) {
    return turf.inside(
      {
        type: 'Point',
        coordinates: point,
      },
      polygon,
    );
  },
 getFeatureCollection(plainText: any) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(plainText, 'text/xml');
    const result = kml(xmlDoc);
    return result;
  },
};

export default GeographicService;
