
import { ShelterSetWithId } from '@/models/all';
import { RegionMap } from '@/models/Organization';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
import Vue from 'vue';
export default Vue.extend({
  name: 'FileList',
  computed: {
    items() {
      return this.$store.get('organization/shelters');
    },
  },
  methods: {
    select(item: ShelterSetWithId) {
      this.$router.push({ path: `/organization/shelters/${item.id}` });
    },
    async deleteMap(item: ShelterSetWithId) {
      const result = await FirebaseRepository.deleteShelter(item);
      if (result) {
        Notification.methods.send('success', 'Shelter deleted');
      } else {
        Notification.methods.send('error', 'Something went wrong');
      }
    },
  },
});
