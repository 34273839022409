
import { Survey } from '@/models/count';
import { RegionMap } from '@/models/Organization';
import Vue from 'vue';
import LeadAssignment from './LeadAssignment.vue';
export default Vue.extend({
  components: { LeadAssignment },
  computed: {
    regionMap(): RegionMap | null {
      const survey: Survey = this.$store.get('survey/survey');
      if (!survey.regionMap) return null;
      return JSON.parse(survey.regionMap);
    },
  },
});
