
import Vue from 'vue';
import { SubAnalytics } from '../models/analytics';
import { AnalyticsService } from '@/services/pure/analytics';

export default Vue.extend({
  name: 'subanalytics',
  props: {
    title: String,
    subdata: {},
  },
});
