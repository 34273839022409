
import Vue, { PropType } from 'vue';
import { RegionMap } from '../../../../models/Organization';
import { API } from '../../../../repositories/api';
import { answer } from '../CreateSurveyDialog.vue';
export default Vue.extend({
  data() {
    return {
      selectedZones: null,
    };
  },
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    },
  },
  computed: {
    regions(): RegionMap {
      return this.$store.get('organization/regions');
    },
  },
  methods: {
    selectAnswer() {
        this.advance({ stepID: 'zone', answer: this.selectedZones });
    },
  },
});
