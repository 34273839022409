import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors';

const settings = {
  theme: {
    themes: {
      light: {
        primary: '#ff7a05',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#F8F8F8'
      },
    },
  },
};

Vue.use(Vuetify, settings);


export default new Vuetify(settings);
