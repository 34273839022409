
import Vue from 'vue';
import { API } from '../repositories/api';
import { FirebaseRepository } from '../repositories/firebase';
import { Notification } from '../services/misc/notification';
import firebase from 'firebase/app';
export default Vue.extend({
  name: 'forgotpassword',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async sendResetEmail(event: any) {
      event.preventDefault();
      const {
        errorMessage,
        success,
      } = await FirebaseRepository.sendResetEmail(
        firebase.auth(),
        this.email,
      );
      if (!success) {
        Notification.methods.send('error', errorMessage);
      } else {
        Notification.methods.send('success', 'Recovery email successfully sent.');
      }
    },
  },
});
