import Papa from 'papaparse';
import _ from 'lodash';
import { ShelterInformation } from '@/models/all';
const ParseSheltersService = {
  parse(content: string) {
    const papad = Papa.parse(content.trim());
    return this.parsePapa(papad);
  },
  parsePapa(result: Papa.ParseResult<unknown>) {
    if (result.data.length < 2) throw 'Not enough records';
    if (result.errors.length > 0) throw result.errors[0].message;

    const first = result.data[0];
    if (!Array.isArray(first) || (first.length !== 3 && first.length !== 2))
      throw 'Header row malformed. Expected two to three columns: Name, Type, and maybe Region.';
    const expectedProperties = ['name', 'type'];
    const propertyIndices = expectedProperties.map(p =>
      first.findIndex(v => v.toLowerCase() === p),
    );
    const cantFindSome = _.some(propertyIndices, v => v === -1);
    if (cantFindSome)
      throw "Couldn't find one or more of the header elements: Name or Type";
    const records = result.data.map((d: any) => {
      const nameIndex = propertyIndices[0];
      const typeIndex = propertyIndices[1];
      const name = d[nameIndex];
      const type = d[typeIndex];
      let record: ShelterInformation = { name, type };
      const regionIndex = first.findIndex(v => v.toLowerCase() === 'region');
      if (regionIndex !== -1) record = { ...record, region: d[regionIndex] };
      return record;
    });
    const withoutHeaders = records.slice(1);
    const values = _.flatten(withoutHeaders.map(r => Object.values(r)));
    if (_.some(values, (v: string) => !v)) throw 'Some fields were blank';
    return withoutHeaders;
  },
};

export default ParseSheltersService;
