
import Vue, { PropType } from 'vue';
import YouthUnaccompanied from '@/components/reporting/YouthUnaccompanied.vue';
import YouthParenting from '@/components/reporting/YouthParenting.vue';
import { BreakdownWrapper } from '@/services/pure/breakdown';

export default Vue.extend({
  components: { YouthUnaccompanied, YouthParenting },
  props: {
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
      required: true,
    },
    report: {
      type: Boolean,
    },
  },
});
