
import Vue from 'vue';
import firebase from 'firebase/app';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '../services/misc/notification';
export default Vue.extend({
  data() {
    return {
      loading: false,
      verifyEmailButtonText: "Resend Verification Email",
      verificationButtonDisabled: false
    }
  },
  computed: {
    isTrialAccount() {
      return this.$store.getters.isTrialAccount;
    },
    isVerifiedUser() {
      return this.$store.getters.user?.emailVerified;
    }
  },
  methods: {
    navigateHome() {
      (this as any).$router.push({ path: '/home' });
    },
    logout() {
      firebase.auth().signOut();
    },
    async sendVerification() {
      this.loading = true;
      const user = this.$store.getters.user;
      const emailSent = await FirebaseRepository.sendVerificationEmail(user);
      this.loading = false;
      if (emailSent){
        this.verifyEmailButtonText = "Email Verification Sent";
        this.verificationButtonDisabled = true;
      } else {
        Notification.methods.send('error', 'Something went wrong. Please try again later.')
      }
    }
  },
});
