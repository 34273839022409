
import Vue, { PropType } from 'vue';
import { FirebaseRepository } from '@/repositories/firebase';
import Subregion from '@/components/Maps/Components/Subregion.vue';
import Submissions from '@/components/Maps/Components/Submissions.vue';
import Boundaries from '@/components/Maps/Components/Boundaries.vue';
import GeographicService from '@/services/pure/geo';
import { Feature } from '@/models/all';
import { Household, SubmissionWrapper } from '@/models/survey';
import { FeatureCollection } from '@turf/turf';
export default Vue.extend({
  props: {
    featureCollection: {
      type: Object as PropType<FeatureCollection>,
    },
    submissions: {
      type: Array as PropType<SubmissionWrapper[]>,
    },
  },
  components: { Boundaries, Subregion, Submissions },
  data() {
    return {
      addingHotspots: false,
      zoom: 9,
      center: [0, 0],
      clicked: false,
      url: null,
      ready: false,
    };
  },
  mounted() {
    this.setCenterAndZoom(this.featureCollection);
  },
  watch: {
    featureCollection: {
      handler(newVal) {
        this.setCenterAndZoom(newVal);
      },
    },
  },
  methods: {
    setCenterAndZoom(featureCollection: FeatureCollection) {
      if (featureCollection.features.length === 0) return;
      this.center = GeographicService.getCenter(
        featureCollection,
      ).geometry.coordinates;
      this.zoom = 9;
    },
  },
});
