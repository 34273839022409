
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import { Household, Survey } from '@/models/survey';
import { Display } from '@/models/cleanup';
import { Both } from '../../../Both';
import Extrapolation from '@/components/PITCount/Extrapolation/Extrapolation.vue';
import LookalikeProperties from './LookalikeProperties.vue';
import Settings from '@/components/Utility/Settings.vue';
import Filter from 'ol/format/filter/Filter';

export default Vue.extend({
  props: {
    problematic: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    all: {
      type: Array as PropType<Both[]>,
      required: true,
    },
    survey: {
      type: Object as PropType<Survey>,
      required: true,
    },
  },
  components: {
    Extrapolation,
    LookalikeProperties,
    Settings,
  },

  data() {
    return {
      items: [] as Display[],
      selected: [] as Display[],
      search: '',
      missingSelected: 'All' as Filter | 'All',
      missingOptions: [
        'All',
        'Age',
        'Relation',
        'Veteran',
        'Conditions',
        'Ethnicity',
        'Race',
        'Gender',
      ],
      lookalikeSelection: ['Age', 'Ethnicity', 'Race', 'Gender'],
      fields: ['Veteran', 'Age', 'Ethnicity', 'Race', 'Gender'],
      field: 'Veteran'
    };
  },
  watch: {
    field: {
      handler(val) {
        // When they update the field that makes some lookalike selection values
        // invalid.
        this.lookalikeSelection = this.lookalikeSelection.filter(s => s !== val);
      }
    }
  },
  computed: {
    lookalikeItems(): string[] {
      const fieldsThatArentTheOneWereLookingAt = this.fields.filter(field => field !== this.field);
      return fieldsThatArentTheOneWereLookingAt;
    },
    tableHeaders() {
      let tableHeaders = [
        { text: 'ID', value: 'DisplayID' },
        { text: 'Status', value: 'SurveyStatus' },
        { text: 'Head of Household', value: 'Head of Household' },
        { text: 'Household Size', value: 'Household Size' },
        { text: 'Type', value: 'Type' },
        { text: 'Volunteer', value: 'Volunteer' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Details', value: 'actions', sortable: false },
      ];
      return tableHeaders;
    },
    selectedHouseholds(): Household[] {
      const selected = this.selected as Display[];
      const selectedHouseholds = selected.map((selected: Display) => {
        return selected.wrapper.household;
      });
      return selectedHouseholds;
    },
    filteredAfterSearch(): Display[] {
      return (this.$refs.table as any).$children[0].filteredItems;
    },
    filteredHouseholds() {
      const filteredAfterSearch: Display[] = this.filteredAfterSearch;
      return filteredAfterSearch.map(selected => {
        return selected.wrapper.household;
      });
    },
  },
});
