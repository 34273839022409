
import Vue from 'vue';
import { CountService } from '@/services/count';

import { AnalyticsService } from '../../services/pure/analytics';
import ListeningComponent from './ListeningComponent.vue';
export default Vue.extend({
  components: {
    ListeningComponent,
  },
  props: {
    id: String,
  },
  computed: {
    ready(): boolean {
      return AnalyticsService.getHouseholds(this.id) !== null;
    },
  },
});
