const formRules = {
  email: [
    (v: string) => !!v || 'E-mail is required',
    (v: string) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      'E-mail must be valid',
  ],
  password: [
    (v: string) => !!v || 'Password is required',
    (v: string) => v.length > 6 || 'Password must be more than 6 characters',
  ],
  rating: [
    (v: number) => !!v || 'Rating required',
    (v: number) =>
      (v <= 5 && v >= 0.5) || 'Rating must be between .5 and 5 stars',
  ],
  kmz: [
    (v: File) => !!v || 'KMZ required',
    (value: File) =>
      !value || value.size < 1000000 || 'KMZ size should be less than 1 MB!',
  ],
  csv: [
    (v: File) => !!v || 'CSV required',
    (value: File) =>
      !value || value.size < 1000000 || 'CSV size should be less than 1 MB!',
  ],
  name: [(v: number) => !!v || 'Name required'],
};
export default formRules;
