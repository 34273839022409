
import Vue from 'vue';
import VolunteerURL from '@/components/VolunteerURL.vue';
import CountMap from '@/components/Maps/CountMap.vue';
import PITCountSettingsDialog from '@/components/Dialogs/PITCountSettingsDialog.vue';
import { SubmissionWrapper, Household } from '@/models/survey';
import { CountService } from '@/services/count';
import { AnalyticsService } from '@/services/pure/analytics';
import { Survey } from '@/models/count';
import { Both } from "../../Both"
import { EntryService } from '@/services/entry';
import { SurveyStatus } from '@/models/adminadjustment';
import SurveySettings from '@/components/PITCount/SurveySettings.vue';
import FilterButton from '@/components/Cleanup/FilterButton/FilterButton.vue';
import DownloadButton from '@/components/Cleanup/DownloadButton.vue';
import { ConclusionService } from '@/services/pure/conclusion';
import DataCleanup from '@/components/Cleanup/DataCleanup.vue';
import SurveyReports from '@/components/PITCount/SurveyReports.vue';
import SurveyForms from '@/components/PITCount/SurveyForms.vue';
import SurveyVolunteers from '@/components/PITCount/SurveyVolunteers.vue';
import SurveyRegions from '@/components/PITCount/SurveyRegions.vue';
import SurveyExtrapolation from '@/components/PITCount/SurveyExtrapolation.vue';
import SurveyScreenSkeletons from '@/components/PITCount/SurveyScreenSkeletons.vue';
import SurveyTools from '@/components/PITCount/SurveyTools.vue';
import Chips from '@/components/PITCount/Chips/Chips.vue';
export type FilterFromQuery = {
  key: string;
  value: string;
  toDisplay: string;
};
export default Vue.extend({
  components: {
    VolunteerURL,
    CountMap,
    PITCountSettingsDialog,
    SurveySettings,
    DownloadButton,
    FilterButton,
    DataCleanup,
    SurveyReports,
    SurveyForms,
    SurveyVolunteers,
    SurveyRegions,
    SurveyScreenSkeletons,
    SurveyExtrapolation,
    Chips,
    SurveyTools,
  },
  props: {
    id: String,
  },
  mounted() {
    this.updateFilteredSurveys(this.householdsWithAdminAdjustments);
  },
  data() {
    return {
      filtered: [] as Both[],
      selected: [] as Both[],
      showDialog: false,
    };
  },
  computed: {
    tab: {
      set(tab: any) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    ready(): boolean {
      return AnalyticsService.getHouseholds(this.id) !== null;
    },
    tabs(): { name: string; icon: string }[] {
      const tabs = [
        {
          name: 'Data',
          icon: 'mdi-magnify',
        },
        {
          name: 'Map',
          icon: 'mdi-map',
        },
        {
          name: 'Reports',
          icon: 'mdi-poll-box',
        },
        {
          name: 'Volunteers',
          icon: 'mdi-account-multiple',
        },
        {
          name: 'Questions',
          icon: 'assignment',
        },
        {
          name: 'Tools',
          icon: 'mdi-chart-donut',
        },
        {
          name: 'Settings',
          icon: 'mdi-settings',
        },
      ];

      if (!this.postRegistration) {
        return [
          {
            name: 'Volunteers',
            icon: 'mdi-account-multiple',
          },
          {
            name: 'Settings',
            icon: 'mdi-settings',
          },
        ];
      }
      return this.isPITCount
        ? tabs
        : tabs.filter(t => t.name !== 'Reports' && t.name !== 'Tools');
    },
    householdsWithAdminAdjustments(): Both[] {
      return this.submissions.map((household: Household) => {
        const adminAdjustment = EntryService.GetAdminAdjustment(
          household,
          this.id,
        );
        return {
          household,
          conclusion: adminAdjustment,
        };
      });
    },
    postRegistration(): boolean {
      return this.survey.mode !== 'Volunteer Registration';
    },
    isPITCount(): boolean {
      return this.survey.type === 'PIT Count';
    },
    organizationName(): string {
      return (this as any).$store.getters.organizationName;
    },
    survey(): Survey {
      const loaded = this.$store.getters.Survey(this.id);
      this.$store.set('survey/survey', loaded);
      return loaded;
    },
    submissions(): Household[] {
      const entries = EntryService.both(this.id);
      const households = entries.map(b => b.household);
      return households;
    },
    boths(): Both[] {
      return EntryService.both(this.id);
    },
  },
  methods: {
    updateFilteredSurveys(filtered: Both[]) {
      this.filtered = filtered;
    },
    updateSelectedHouseholds(selected: Both[]) {
      this.selected = selected;
    },
  },
});
