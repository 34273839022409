import Vue from 'vue';
import _ from 'lodash';
import Store from '../../store';
export const Notification = {
  methods: {
    send(type: 'success' | 'error', message: string) {
      Store.commit('setNotification', { type, message });
    },
  },
};
