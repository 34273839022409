
import Vue from 'vue';
import { CountService } from '@/services/count';
import { FormWrapper } from '@/models/formstructure';
import { FormStructureService } from '@/services/pure/formstructure';
import { StringService } from '@/services/misc/string';
import { Notification } from '../../services/misc/notification';
export default Vue.extend({
  props: ['survey'],
  mounted() {
    this.name = this.survey.name;
  },
  data() {
    return {
      name: this.survey.name,
      mode: this.survey.mode,
      nameRules: [(v: any) => !!v || 'Name required'],
      interviewSurvey: null,
      observationSurvey: null,
      includeObservation: false,
    };
  },
  computed: {
    hasChange(): boolean {
      return this.name !== this.survey.name || this.mode !== this.survey.mode;
    },
  },
  methods: {
    async saveCount(event: any) {
      event.preventDefault();
      const newSurvey = this.survey;
      newSurvey.mode = this.mode;
      newSurvey.name = this.name;
      await CountService.update(newSurvey);
      Notification.methods.send('success', 'Survey updated');
      this.$emit('disableDialog');
    },
  },
});
