
import Vue from 'vue';
import { CountService } from '@/services/count';
import { FormWrapper, Form, FormType } from '@/models/formstructure';
import { FormStructureService } from '@/services/pure/formstructure';
import { StringService } from '@/services/misc/string';
import { FirebaseRepository } from '@/repositories/firebase';
import _ from 'lodash';
import { DataService } from '@/services/pure/data';
export default Vue.extend({
  props: ['forms'],
  data() {
    return { template: null, approach: '' };
  },
  methods: {
    async createForm(event: any) {
      event.preventDefault();
      const form = this.getFormWrapper();
      this.saveForm(form);
    },
    getTitle(survey: FormWrapper) {
      return FormStructureService.GetTitle(survey);
    },
    async saveForm(form: FormWrapper) {
      const forms = this.$store.getters.formStructures.slice();
      forms.push(form);
      await FirebaseRepository.saveFormStructures(forms, []);
      this.$emit('disableDialog');
    },
    getFormWrapper(): FormWrapper {
      const edited = Date.now();
      const id = DataService.generateRandomId();
      if (this.approach === 'Start From Scratch') {
        return {
          Edited: edited,
          ID: id,
          Type: FormType.Other,
          Structure: (JSON.stringify({
            title: 'New Form',
            pages: [
              {
                name: 'page1',
              },
            ],
          }) as any) as Form,
        };
      }
      const newForm = (_.cloneDeep(this.template) as any) as FormWrapper;
      newForm.ID = id;
      newForm.Edited = edited;
      return newForm;
    },
  },
});
