import { CountYear } from './breakdown';
import {
  Age,
  Ethnicity,
  Gender,
  YouthHouseholdType,
  Race,
  Relation,
  YesNoUnknown,
  HouseholdType,
  Race2023,
  Race2024,
  Races2024,
  Genders2024,
  Gender2024,
} from '../../models/household-enums';
import {
  ChronicHomelessDisabilityChoice,
  ChronicHomelessVISPIDAT,
  HeadOfHousehold,
  Household,
  HouseholdMember,
} from '../../models/survey';
import _, { cond } from 'lodash';
import {
  AdminAdjustment,
  Condition,
  HouseholdMemberConclusions,
  HousingStatus,
  SurveyStatus,
  Conclusion,
  ShelterStatus,
  HouseholdMemberConclusions2023,
  HouseholdMemberConclusions2024,
} from '../../models/adminadjustment';
import { hasDisability } from '../shared/analytics';
import { findReportingYearForHousehold } from '../year-finder';
import { adultAges2023 } from '../../models/all';
import { Both } from '../../Both';
export const ConclusionService = {
  GetConclusionBestGuessWithLiveSurveys(
    household: Household,
    liveSurveys: string[],
  ): AdminAdjustment {
    const surveyStatus = this.GetSurveyStatus(household, liveSurveys);
    const housingStatus = this.GetHousingStatus(household);
    const shelterStatus = this.GetShelterStatus(household);
    const year = findReportingYearForHousehold(household);
    const bestGuess: AdminAdjustment = {
      householdID: household.householdID,
      surveyStatus,
      housingStatus,
      shelterStatus,
      members: household.HouseholdMembers.map((hm: HouseholdMember) => {
        return ConclusionService.GetBestGuessForHouseholdMember(
          hm,
          household.HouseholdMembers[0] as HeadOfHousehold,
          year,
        );
      }),
    };
    return bestGuess;
  },
  GetHousingStatus(household: Household): HousingStatus {
    const isChronically = this.GetChronicallyHomeless(household
      .HouseholdMembers[0] as HeadOfHousehold);
    if (isChronically === 'Yes') return 'Chronically Homeless';
    const isLiterally = this.GetLiterallyHomeless(household
      .HouseholdMembers[0] as HeadOfHousehold);
    if (isLiterally === 'Yes') return 'Homeless';
    if (isLiterally === 'No') return 'Not Literally Homeless';
    return 'Unknown';
  },
  GetShelterStatus(household: Household): ShelterStatus {
    const head = household.HouseholdMembers[0] as HeadOfHousehold;
    const whereTheyStayed = head['Where They Stayed Last Night'];

    if (whereTheyStayed === 'Transitional housing')
      return 'Transitional Housing';
    if (
      whereTheyStayed === 'Emergency shelter' ||
      whereTheyStayed === 'Motel/hotel paid by agency' ||
      whereTheyStayed === 'Cold night shelter'
    )
      return 'Emergency Shelter';
    if (whereTheyStayed === 'Safe Haven') return 'Safe Haven';
    return 'Unsheltered';
  },
  GetSurveyStatus(household: Household, liveSurveys: string[]): SurveyStatus {
    const head = household.HouseholdMembers[0] as HeadOfHousehold;
    if (
      !!head['Completed Survey Before'] &&
      head['Completed Survey Before'] === 'Yes'
    )
      return 'Invalid';

    if (!!household.version && household.version >= 2 && !!household.surveyStatus) return household.surveyStatus;
    if (this.isInInvalidList(household, liveSurveys)) return 'Training';
    return 'Live';
  },
  isInInvalidList(survey: Household, liveSurveys: string[]) {
    return liveSurveys.filter(s => s === survey.householdID).length === 0;
  },
  GetBestGuessForHouseholdMember(
    householdMember: HouseholdMember,
    headOfHousehold: HeadOfHousehold,
    countYear: CountYear,
  ): HouseholdMemberConclusions {
    if (['2021', '2022', '2023'].includes(countYear)) {
      return this.GetBestGuessForHouseholdMember2023(
        householdMember,
        headOfHousehold,
        countYear,
      );
    }
    return this.GetBestGuessForHouseholdMember2024(
      householdMember,
      headOfHousehold,
      countYear,
    );
  },
  GetBestGuessForHouseholdMember2023(
    householdMember: HouseholdMember,
    headOfHousehold: HeadOfHousehold,
    countYear: CountYear,
  ): HouseholdMemberConclusions2023{
    const age = this.GetAge(householdMember, countYear);
    const guess: HouseholdMemberConclusions2023 = {
      Gender: this.GetGender(householdMember, countYear),
      Race: this.GetRace(householdMember, countYear) as Race2023,
      Age: age,
      Ethnicity: this.GetEthnicity(householdMember),
      Veteran: this.ParseYesNoUnknown(householdMember.Veteran, age),
      Conditions: this.GetConditions(householdMember, age),
      DomesticViolenceSurvivor: this.ParseYesNoUnknown(
        householdMember['Experienced Domestic Violence'],
        age,
      ),
      Relation: this.GetRelation(householdMember, headOfHousehold),
    };
    return guess;
  },
  GetBestGuessForHouseholdMember2024(
    householdMember: HouseholdMember,
    headOfHousehold: HeadOfHousehold,
    countYear: CountYear,
  ): HouseholdMemberConclusions2024{
    const age = this.GetAge(householdMember, countYear);
    const guess: HouseholdMemberConclusions = {
      Gender: this.GetGender(householdMember, countYear),
      Race: this.GetRace(householdMember, countYear) as Races2024,
      Age: age,
      Veteran: this.ParseYesNoUnknown(householdMember.Veteran, age),
      Conditions: this.GetConditions(householdMember, age),
      DomesticViolenceSurvivor: this.ParseYesNoUnknown(
        householdMember['Experienced Domestic Violence'],
        age,
      ),
      Relation: this.GetRelation(householdMember, headOfHousehold),
    };
    return guess;
  },
  GetConditions(individual: HouseholdMember, age: Age): Condition[] {
    if ('HasDisability' in individual)
      return this.GetConditionsDisabilityChoice(individual, age);
    return this.GetConditionsVISPIDAT(individual, age);
  },
  GetConditionsVISPIDAT(individual: HouseholdMember, age: Age): Condition[] {
    const conditions = [] as Condition[];

    const choices = individual as ChronicHomelessVISPIDAT;
    if (
      !choices['Mental Health Concern'] &&
      !choices['Substance Use'] &&
      !individual['HIV/AIDS'] &&
      !choices['Physical Disability']
    ) {
      if (age === 'Under 18') return ['None'];
      return [];
    }
    if (choices['Mental Health Concern'] === 'Yes')
      conditions.push('Serious Mental Illness');
    if (choices['Substance Use'] === 'Yes')
      conditions.push('Substance Abuse Disorder');
    if (individual['HIV/AIDS'] === 'Yes') conditions.push('HIV/AIDS');
    if (choices['Physical Disability'] === 'Yes')
      conditions.push('Physical Disability');
    const toReturn =
      conditions.length > 0 ? conditions : (['None'] as Condition[]);
    return toReturn;
  },
  GetConditionsDisabilityChoice(
    individual: HouseholdMember,
    age: Age,
  ): Condition[] {
    const conditions = [] as Condition[];

    const choices = individual as ChronicHomelessDisabilityChoice;

    if (choices['HasDisability'] == 'No') return ['None'];

    if (
      choices['HasDisability'] === 'Not Sure' ||
      choices['HasDisability'] === 'Unknown'
    )
      return [];

    if (!choices['DisabilityType']) return [];

    for (const disability of choices['DisabilityType']) {
      switch (disability) {
        case 'Physical/Medical':
          conditions.push('Physical Disability');
          break;
        case 'Mental Health/Emotional':
          conditions.push('Serious Mental Illness');
          break;
        case 'Drug or Alcohol Addiction':
          conditions.push('Substance Abuse Disorder');
          break;
        case 'HIV/AIDS':
          conditions.push('HIV/AIDS');
          break;
        case 'Developmental':
          conditions.push('Developmental Disability');
          break;
        default:
          continue;
      }
    }
    return conditions;
  },
  GetGender(individual: HouseholdMember, year: CountYear): Gender {
    if (['2021', '2022', '2023'].includes(year)) return this.GetGender2023(individual);
    return this.GetGender2024(individual);
  },
  GetGender2023(individual: HouseholdMember): Gender {
    if (Array.isArray(individual.Gender) && individual.Gender.length > 1)
      return 'Multiple';
    const gender = Array.isArray(individual.Gender)
      ? individual.Gender[0]
      : individual.Gender;
    switch (gender) {
      case 'Male':
        return 'Male';
      case 'Female':
        return 'Female';
      case 'Transgender':
      case 'TransgenderMTF' as any:
      case 'Transgender MTF':
      case 'TransgenderFTM' as any:
      case 'Transgender FTM':
        return 'Transgender';
      case 'NonConforming' as any:
      case 'Gender Non-Conforming':
        return 'Gender Non-Conforming';
      case 'Culturally Specific Identity':
        return 'Culturally Specific Identity';
      case 'Other':
        return 'Other';
      case 'Questioning':
        return 'Questioning';
      default:
        return 'Unknown';
    }
  },
  GetGender2024(individual: HouseholdMember): Genders2024 {
    if (!Array.isArray(individual.Gender)) return [this.StandardizedGenderString2024(individual.Gender)];
    return (individual.Gender as string[]).map(r => this.StandardizedGenderString2024(r));
  },
  StandardizedGenderString2024(gender: string): Gender2024 {
    switch (gender) {
      case 'Male':
        return 'Male';
      case 'Female':
        return 'Female';
      case 'Transgender':
        return 'Transgender';
      case 'NonConforming' as any:
      case 'Gender Non-Conforming':
        return 'Gender Non-Conforming';
      case 'Culturally Specific Identity':
        return 'Culturally Specific Identity';
      case 'Other':
        return 'Other';
      case 'Questioning':
        return 'Questioning';
      default:
        return 'Unknown';
    }
  },
  GetRelation(individual: HouseholdMember, head: HeadOfHousehold): Relation {
    if (individual['Personal ID'] === head['Personal ID']) return 'Head';
    if (!('Relation' in individual)) return 'Unknown';
    switch (individual.Relation) {
      case 'Child':
        return 'Child';
      case 'Spouse':
        return 'Spouse';
      case 'Non-Married Partner':
        return 'Non-Married Partner';
      case 'Other Family':
        return 'Other Family';
      case 'Other, Non-Family':
        return 'Other, Non-Family';
      default:
        return 'Unknown';
    }
  },
  GetRace(individual: HouseholdMember, year: CountYear): Race {
      if (['2021', '2022', '2023'].includes(year)) return this.GetRace2023(individual);
      return this.GetRace2024(individual);
  },
  GetRace2024(individual: HouseholdMember): Race {
    if (!Array.isArray(individual.Race)) return [this.StandardizedRaceString2024(individual.Race)];
    return (individual.Race as string[]).map(r => this.StandardizedRaceString2024(r));
  },
  GetRace2023(individual: HouseholdMember): Race2023 {
    if (Array.isArray(individual.Race) && individual.Race.length > 1) {
      if (
        individual.Race.indexOf('Not Sure') !== -1 ||
        individual.Race.indexOf('Refused to Answer') !== -1 ||
        individual.Race.indexOf('Other') !== -1
      )
        return 'Unknown';
      return 'Multiple';
    }
    const race = Array.isArray(individual.Race)
      ? individual.Race[0]
      : individual.Race;
    switch (race) {
      case 'AmericanIndian':
      case 'American Indian/Alaskan Native':
      case 'American Indian':
        return 'American Indian/Alaskan Native';
      case 'Asian':
        return 'Asian';
      case 'Black':
      case 'Black/African American':
        return 'Black/African American';
      case 'PacificIslander':
      case 'Native Hawaiian/Other Pacific Islander':
        return 'Native Hawaiian/Other Pacific Islander';
      case 'White':
        return 'White';
      default:
        return 'Unknown';
    }
  },
  StandardizedRaceString2024(race: string): Race2024 {
    switch (race) {
      case 'AmericanIndian':
      case 'American Indian/Alaskan Native':
      case 'American Indian':
        return 'American Indian/Alaskan Native';
      case 'Asian':
        return 'Asian';
      case 'Black':
      case 'Black/African American':
        return 'Black/African American';
      case 'PacificIslander':
      case 'Native Hawaiian/Other Pacific Islander':
        return 'Native Hawaiian/Other Pacific Islander';
      case 'White':
        return 'White';
      case 'Middle Eastern or North African':
      case 'Middle Eastern':
        return 'Middle Eastern or North African';
      case 'Hispanic/Latina/e/o':
      case 'Hispanic':
        return 'Hispanic/Latina/e/o';
      default:
        return 'Unknown';
    }

  },
  GetAge(individual: HouseholdMember, countYear: CountYear): Age {
    if (!individual.Age) {
      return 'Unknown';
    }

    const asNumber = parseInt(individual.Age, 10);
    const isNumber = !Number.isNaN(asNumber);
    if (isNumber) {
      if (asNumber < 18) return 'Under 18';
      if (asNumber < 25) return '18-24';
      if (countYear === '2021' || countYear === '2022') return '25+';
      if (asNumber < 35) return '25-34';
      if (asNumber < 45) return '35-44';
      if (asNumber < 55) return '45-54';
      if (asNumber < 65) return '55-64';
      return '65+';
    }
    switch (individual.Age) {
      case 'under18':
      case 'Under 18':
        return 'Under 18';
      case '18to24':
      case '18-24':
        return '18-24';
      case '25plus':
      case '25+':
      case '25 Plus':
        return '25+';
      case '25-34':
        return '25-34';
      case '35-44':
        return '35-44';
      case '45-54':
        return '45-54';
      case '55-64':
        return '55-64';
      case '65+':
        return '65+';
      default:
        return 'Unknown';
    }
  },
  GetEthnicity(individual: HouseholdMember): Ethnicity {
    switch (individual.Hispanic) {
      case 'Yes':
        return 'Hispanic';
      case 'No':
        return 'Non-Hispanic';
      default:
        return 'Unknown';
    }
  },
  ParseYesNoUnknown(answer: string, age: Age): YesNoUnknown {
    if (answer === 'Yes') return 'Yes';
    if (answer === 'No') return 'No';
    if (age === 'Under 18') return 'No';
    return 'Unknown';
  },
  GetLiterallyHomeless(person: HeadOfHousehold): YesNoUnknown {
    if (isNonHomelessSituation(person['Where They Stayed Last Night']))
      return 'No';
    if (isHomelessSituation(person['Where They Stayed Last Night']))
      return 'Yes';
    
    return 'Unknown';
  },
  GetChronicallyHomeless(head: HeadOfHousehold): YesNoUnknown {
    // For Jackson/West Tennessee CoC
    if ('Is Chronically Homeless' in head) {
      const answer = head['Is Chronically Homeless'];
      if (answer == 'Yes') return 'Yes'
      if (answer == 'No') return 'No'
      return 'Unknown'
    }
    const isHomeless = this.GetLiterallyHomeless(head);
    if (isHomeless === 'No') return 'No';
    const disability = hasDisability(head);
    if (disability === null) return 'Unknown';
    if (!disability) return 'No';

    const oneYear = oneConsecutiveYearHomeless(head);
    const fourOrMore = fourHomelessOccasionsOfTwelveMonthsInThreeYears(head);
    const recentlyLeft = recentlyLeftInstitutionAndChronicallyHomelessPrior(
      head,
    );
    const cases = [oneYear, fourOrMore, recentlyLeft];
    if (_.some(cases, c => c === true)) return 'Yes';
    if (
      oneYear === null &&
      fourOrMore === null &&
      recentlyLeft === null &&
      isHomeless === 'Unknown'
    )
      return 'Unknown';
    return 'No';
  },
  GetName(member: HouseholdMember): string | null {
    if ('Initials Or Name' in member) return member['Initials Or Name'];
    return null;
  },
  GetYouthHouseholdType(adminAdjustment: AdminAdjustment): YouthHouseholdType {
    const ages = adminAdjustment.members.map(
      (aa: HouseholdMemberConclusions) => aa.Age,
    );
    if (_.includes(ages, 'Unknown')) return 'Unknown';
    if (_.every(ages, age => isChildOrYouth(age))) {
      if (_.some(adminAdjustment.members, member => member.Relation === 'Child'))
      return 'Parenting Youth';
    return 'Unaccompanied Youth';
    }
    return 'Other';
  },
  GetHouseholdType(adminAdjustment: AdminAdjustment): HouseholdType {
    const ages = adminAdjustment.members.map(
      (aa: HouseholdMemberConclusions) => aa.Age,
    );
    if (_.includes(ages, 'Unknown')) return 'Unknown';
    if (_.every(ages, age => isChildOrYouth(age))) {
      if (_.some(adminAdjustment.members, member => member.Relation === 'Child'))
      return 'Parenting Youth';
    return 'Unaccompanied Youth';
    }
    if (_.every(ages, age => isAdultOrYouth(age))) return 'Only Adults'
    if (_.every(ages, age => age === 'Under 18')) return 'Only Children'
    return 'Adults & Children'
  },
  
  HasIssue(adminAdjustment: AdminAdjustment): boolean {
    const isUnknown = (prop: any) => prop === 'Unknown';
    if (isUnknown(adminAdjustment.housingStatus)) return true;
    for (const member of adminAdjustment.members) {
      if (isUnknown(member.Age)) return true;
      if (isUnknown(member.DomesticViolenceSurvivor)) return true;
      if ('Ethnicity' in member && isUnknown(member.Ethnicity)) return true;
      if (isUnknown(member.Gender)) return true;
      if (isUnknown(member.Race)) return true;
      if (isUnknown(member.Relation)) return true;
      if (isUnknown(member.Veteran)) return true;
    }
    return false;
  },
  AdminAdjustmentFromConclusion(conclusion: Conclusion): AdminAdjustment {
    const { isGuess, ...aa } = conclusion;
    return aa;
  },
};

const isNonHomelessSituation = (situation: string | undefined): boolean => {
  if (typeof situation === 'undefined') return false;
  const nonHomelessSituations = [
    'Motel',
    'House',
    'Motel/hotel',
    'House or apartment',
    'Couch surfing',
    'JailHospitalOrTreatmentProgram',
    'Jail, hospital, treatment program'
  ];
  return nonHomelessSituations.indexOf(situation) !== -1;
};

const isHomelessSituation = (situation: string | undefined): boolean => {
  if (typeof situation === 'undefined') return false;
  const homelessSituations = [
    'Street',
    'Street or sidewalk',
    'Vehicle',
    'Park',
    'AbandonedBuilding',
    'Abandoned building',
    'BusTrainStationOrAirport',
    'Bus, train station, airport',
    'Bridge',
    'Under bridge/overpass',
    'Woods',
    'Woods or outdoor encampment',
    'Motel/hotel paid by agency',
    'Cold night shelter',
    'Emergency shelter',
    'EmergencyShelter',
    'TransitionalHousing',
    'Transitional housing',
    // 4/17/22 - For christopher.sage@pcni.org, mark his specific answer as sheltered.
    // https://www.hudexchange.info/homelessness-assistance/coc-esg-virtual-binders/esg-eligible-activities/emergency-shelter/shelter-operations/
    'Friends/family',
  ];
  return homelessSituations.indexOf(situation) !== -1;
};

const oneConsecutiveYearHomeless = (head: HeadOfHousehold): boolean | null => {
  if (!('Homelessness Duration' in head)) return null;
  return head['Homelessness Duration'] === '1 year or longer';
};

const fourHomelessOccasionsOfTwelveMonthsInThreeYears = (
  head: HeadOfHousehold,
): boolean | null => {
  if (
    !('Months Homeless In Last Three Years' in head) ||
    !('Times Homeless In Last Three Years' in head)
  )
    return null;
  const monthsAsInt = parseInt(head['Months Homeless In Last Three Years'], 10);
  if (isNaN(monthsAsInt)) return null;
  return (
    monthsAsInt >= 12 &&
    head['Times Homeless In Last Three Years'] === 'Four or More Times'
  );
};
const recentlyLeftInstitutionAndChronicallyHomelessPrior = (
  head: HeadOfHousehold,
): boolean | null => {
  if (!('Homeless Before Institution For a Year Or More' in head)) return null;
  return head['Homeless Before Institution For a Year Or More'] === 'Yes';
};

export const replaceQuestionsInPlace = (
  entry: Record<string, string>,
  dict: Record<string, string>,
) => {
  for (const [key, val] of Object.entries(entry)) {
    // Replace the CustomQuestion... title with the title of the question if there isn't already
    // an entry with that title.
    if (
      key.indexOf('CustomQuestion') !== -1 &&
      dict[key] &&
      dict[key] &&
      !(dict[key] in entry)
    ) {
      const title = dict[key];
      delete (entry as any)[key];
      (entry as any)[title] = val;
    }
  }
};

export const isChildOrYouth = (age: Age): boolean => {
  return ["Under 18", '18-24'].includes(age);
}

export const isAdultOrYouth = (age: Age): boolean => {
  return ['18-24', '25+', ...adultAges2023].includes(age);
}

export const isAdult = (age: Age): boolean => {
  return ['25+', ...adultAges2023].includes(age);
}

export const missing = (boths: Both[]): Both[] => {
  return boths.filter(b => bothIsMissing(b))
}

export const bothIsMissing = (b: Both): boolean => {
  if (b.conclusion.surveyStatus !== 'Live') return false;
  if (b.conclusion.housingStatus === 'Unknown') return true;

  // If they've completed the survey before, then we will expect to be missing some info.
  if (b.household?.HouseholdMembers?.length > 0 
    && !!b.household?.HouseholdMembers[0]['Completed Survey Before']
    && b.household?.HouseholdMembers[0]['Completed Survey Before'] === 'Yes')
      return false;

  // If they're not literally homeless, then we'll have exited early and won't have some info.
  if (b.conclusion.housingStatus === 'Not Literally Homeless') return false;

    for (const m of b.conclusion.members) {
      if (m.Age === 'Unknown') return true;
      if ('Ethnicity' in m && m.Ethnicity === 'Unknown') return true;
      if (m.Gender === 'Unknown') return true;
      if (m.Race === 'Unknown') return true;
      if (m.Relation === 'Unknown') return true;
      // If under 18 won't have conditions, veteran info
      if (m.Age === 'Under 18') continue
      if (Object.values(m).find(v => v === 'Unknown')) return true;
    }
    return false;
}

export const notMissing = (boths: Both[]): Both[] => {
  return boths.filter(b => {
    return boths.filter(b => !bothIsMissing(b))
  })
}