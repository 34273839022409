
import Vue from 'vue';
import { AnalyticsService } from '@/services/pure/analytics';
import { DataService } from '@/services/pure/data';
import HorizontalBarChart from '@/components/HorizontalBarChart.vue';
import { Analytics, SubAnalytics } from '@/models/analytics';
import { HeadOfHousehold, Household } from '@/models/survey';
import SubAnalyticsVue from './SubAnalytics.vue';
import { StringService } from '@/services/misc/string';
import _, { Dictionary } from 'lodash';
import Store from '@/store';
import { Both } from "../../../Both"
import { GroundTruthService } from '@/services/misc/ground-truth';
import { ConclusionService, missing, notMissing } from '@/services/pure/conclusion';
import { Conclusion, ApprovalStatus, HousingStatus } from '../../../models/adminadjustment';
import { CountyService } from '@/services/pure/county';
import { Route } from 'vue-router';
import Polygon from 'ol/geom/Polygon';
import { fromLonLat } from 'ol/proj'
import Settings from '../../Utility/Settings.vue';
import RegionSelection from '../../PITCount/Regions/RegionSelection.vue';
import { SurveyWithCode } from '@/models/count';
import { HUDSection, ReportGenders, Shelter, ShelterInformation } from '@/models/all';
import PopulationGroup from './PopulationGroup.vue'
import Races from './Races.vue'
import Ages from './Ages.vue'
import Genders from './Genders.vue'
import { HasPopulationMembers } from '../../../services/pure/breakdown';
import { Age, Gender } from '../../../models/household-enums';

interface Filter {
  question: string;
  answer: string;
  equalTo: string;
}

export default Vue.extend({
  components: { Settings, RegionSelection, PopulationGroup, Races, Ages, Genders },
  props: ['householdsWithAdminAdjustments'],
  mounted() {
    this.loadFiltersFromQueryParams(this.$route.query);
  },
  data() {
    return {
      filterDialog: false,
      customLocationFilter: 'All',
      missingInformation: 'All',
      filters: [] as Filter[],
      surveyStatus: 'All',
      housingStatus: GroundTruthService.GetHousingStatuses(),
      approvalStatus: 'All',
      shelterStatus: 'All',
      countyFilter: 'All',
      cityFilter: 'All',
      zipcodeFilter: 'All',
      volunteer: 'All',
      shelterSelection: [] as Shelter[],
      filteredHouseholdIDs: [] as string[],
      boundingBox: [] as number[][],
      showAdvanced: false,
      regions: [] as string[],
      populations: [] as HUDSection[],
      races: [] as any[],
      ages: [] as Age[],
      genders: [] as ReportGenders[],
    };
  },
  watch: {
    filteredSurveys: {
      deep: true,
      handler(newVal) {
        this.$emit('filteredSurveys', newVal);
      },
    },
    $route(to: Route, from: Route) {
      // Handles browser history when going back/forward and component is preserved.
      if (_.isEqual(to.query, from.query)) return;
      this.loadFiltersFromQueryParams(to.query);
    }
  },
  computed: {
    advancedButtonText(): string {
      return this.showAdvanced ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    surveyStatuses() {
      const statuses: string[] = GroundTruthService.GetSurveyStatuses();
      statuses.unshift('All');
      return statuses;
    },
    shelterStatuses() {
      const statuses: string[] = GroundTruthService.GetShelterStatuses();
      statuses.unshift('All');
      return statuses;
    },
    volunteers() {
      const volunteers = _.uniq((this.householdsWithAdminAdjustments as Both[]).map(b => b.household.volunteerName));
      volunteers.unshift('All');
      return volunteers;
    },
    housingStatuses() {
      const statuses: string[] = GroundTruthService.GetHousingStatuses();
      return statuses;
    },
    approvalStatuses() {
      const statuses: string[] = GroundTruthService.GetApprovalStatuses();
      statuses.unshift('All');
      return statuses;
    },
    shelters() {
      const survey: SurveyWithCode = Store.get('survey/survey');
      return survey.shelters ? survey.shelters.shelters : [];
    },
    customLocations(): string[] {
      const withCustomLocation: Both[] = this.householdsWithAdminAdjustments.filter(
        (w: Both) => !!w && !!w.household.customLocation,
      );
      const custom = _.uniq(
        withCustomLocation.map((w: Both) =>
          w!.household.customLocation!.trim(),
        ),
      );
      return ['All', ...custom];
    },
    countyOptions(): string[] {
      const counties: string[] = this.householdsWithAdminAdjustments.map(
        (h: Both) => h.household.county ?? 'Unknown'
      );
      return _.uniq([...counties, 'Unknown']);
    },
    cityOptions(): string[] {
      const cities: string[] = this.householdsWithAdminAdjustments.map(
        (h: Both) => h.household.city ?? 'Unknown'
      );
      return _.uniq([...cities, 'Unknown']);
    },
    zipcodeOptions(): string[] {
      const zipcodes: string[] = this.householdsWithAdminAdjustments.map(
        (h: Both) => h.household.zipcode ?? 'Unknown'
      );
      return _.uniq([...zipcodes, 'Unknown']);
    },
    missingInformationOptions(): string[] {
      return ['All', 'Yes', 'No']
    },
    filteredSurveys(): Both[] {

      let filtered = this.householdsWithAdminAdjustments;

      const filtersToApply = [
        this.filterByHouseholdIDs,
        this.filterByHeadOfHousehold,
        this.filterByCounty,
        this.filterByCity,
        this.filterByZip,
        this.filterByLocation,
        this.filterBySurveyStatus,
        this.filterByHousingStatus,
        this.filterByShelterStatus,
        this.filterByApprovalStatus,
        this.filterByVolunteer,
        this.filterByRegion,
        this.filterByBoundingBox,
        this.filterByShelter,
        this.filterByPopulations,
        this.filterByRaces,
        this.filterByAges,
        this.filterByGenders,
        this.filterByMissingInformation
      ];

      for (let fn of filtersToApply) {
        filtered = fn(filtered);
      }

      return filtered;
    },
  },
  methods: {
    filterByPopulations(boths: Both[]) {
      if (this.populations.length === 0) return boths;
      return boths.filter(b => {
        return _.some(this.populations, p => HasPopulationMembers(b.conclusion, p));
      });
    },
    filterByRaces(boths: Both[]) {
      if (this.races.length === 0) return boths;
      return boths.filter(b => {
        return _.some(b.conclusion.members, m => {
          // Special cases
          if (this.races.length === 1 && this.races[0] === 'Multi-racial & Hispanic/Latina/e/o') {
            return Array.isArray(m.Race) && m.Race.length > 2 && m.Race.includes('Hispanic/Latina/e/o');
          }
          if (this.races.length === 1 && this.races[0] === 'Multi-racial (not Hispanic/Latina/e/o)') {
            return Array.isArray(m.Race) && m.Race.length > 1 && !m.Race.includes('Hispanic/Latina/e/o');
          }
          if (this.races.length === 1 && this.races[0].includes('&')) {
            const first = this.races[0].split('&')[0].trim();
            const second = this.races[0].split('&')[1].trim();
            return Array.isArray(m.Race) && m.Race.includes(first) && m.Race.includes(second);
          }

          return _.some(this.races, r => {
            if (r === 'Unknown') return m.Race === r || Array.isArray(m.Race) && m.Race.includes(r);
            return m.Race === r || Array.isArray(m.Race) && m.Race.length === 1 && m.Race[0] === r || r === 'Multiple Races' && m.Race === 'Multiple'
          })
        });
      });
    },
    filterByAges(boths: Both[]) {
      if (this.ages.length === 0) return boths;
      return boths.filter(b => {
        return _.some(this.ages, r => {
          return _.some(b.conclusion.members, m => m.Age === r)
        });
      });
    },
    filterByGenders(boths: Both[]) {
      if (this.genders.length === 0) return boths;
      return boths.filter(b => {
        return _.some(b.conclusion.members, m => {
          if (Array.isArray(this.genders) && this.genders.length == 1 && this.genders[0] === 'Multiple' && Array.isArray(m.Gender) && Array.isArray(m.Gender) && m.Gender.length > 1) return true;
          return _.some(this.genders, r => {
            if (r === 'Unknown') return m.Gender === r || Array.isArray(m.Gender) && m.Gender.includes(r);
            return m.Gender === r || Array.isArray(m.Gender) && m.Gender.length === 1 && m.Gender[0] === r
          })
        });
      });
    },
    filterByCounty(boths: Both[]) {
      return filterByProp(boths, 'county', this.countyFilter)
    },
    filterByCity(boths: Both[]) {
      return filterByProp(boths, 'city', this.cityFilter)
    },
    filterByZip(boths: Both[]) {
      return filterByProp(boths, 'zipcode', this.zipcodeFilter)
    },
    filterByRegion(boths: Both[]) {
      if (this.regions.length === 0) return boths;
      return boths.filter(b => {
        if (!b.household.teamName) return false;
        return this.regions.includes(b.household.teamName);
      })
    },
    filterByBoundingBox(Boths: Both[]) {
      if (!this.boundingBox || this.boundingBox.length === 0) return Boths;
      const poly = new Polygon(this.boundingBox as any);
      return Boths.filter((b: Both) => {
        if ((!Array.isArray(b.household.latlng))) {
          return false;
        } else {
          return poly.intersectsCoordinate(b.household.latlng);
        }
      });
    },
    filterByShelter(boths: Both[]) {
      if (!this.shelterSelection || this.shelterSelection.length === 0) return boths;
      return boths.filter(b => this.shelterSelection.map(s => s.id).indexOf(b.household.shelter) !== -1);
    },
    filterByApprovalStatus(Boths: Both[]) {
      if (this.approvalStatus === 'All') return Boths;
      const filteredByStatus = Boths.filter(
        (f: Both) => getApprovalStatus(f.conclusion) === this.approvalStatus,
      );
      return filteredByStatus;
    },
    filterByVolunteer(Boths: Both[]) {
      if (this.volunteer === 'All') return Boths;
      const filteredByVolunteer = Boths.filter(
        (f: Both) => f.household.volunteerName.toLowerCase().trim() === this.volunteer.toLowerCase().trim(),
      );
      return filteredByVolunteer;
    },
    filterBySurveyStatus(Boths: Both[]) {
      if (this.surveyStatus === 'All') return Boths;
      const filteredByStatus = Boths.filter(
        (f: Both) => f.conclusion.surveyStatus === this.surveyStatus,
      );
      return filteredByStatus;
    },
    filterByHousingStatus(Boths: Both[]) {
      if (this.housingStatus.length === 0 || this.housingStatus.length === 3) return Boths;

      const filteredByStatus = Boths.filter(
        (f: Both) => this.housingStatus.indexOf(f.conclusion.housingStatus as HousingStatus) !== -1,
      );
      return filteredByStatus;
    },
    filterByShelterStatus(Boths: Both[]) {
      if (this.shelterStatus === 'All') return Boths;
      const filteredByStatus = Boths.filter(
        (f: Both) => f.conclusion.shelterStatus === this.shelterStatus,
      );
      return filteredByStatus;
    },
    filterByLocation(Boths: Both[]) {
      const questionFiltered = Boths;
      const unset = this.customLocationFilter === 'All';
      if (unset) return questionFiltered;
      const matching = questionFiltered.filter(
        qf =>
          !!qf &&
          !!qf.household.customLocation &&
          qf.household.customLocation.trim() === this.customLocationFilter,
      );
      return matching;
    },
    filterByMissingInformation(Boths: Both[]) {
      const questionFiltered = Boths;
      const unset = this.missingInformation === 'All';
      if (unset) return questionFiltered;
      if (this.missingInformation == 'Yes') {
        return missing(questionFiltered);
      }
      return notMissing(questionFiltered);
    },
    filterByHouseholdIDs(Boths: Both[]) {
      const ids = this.filteredHouseholdIDs;
      if (!ids || ids.length === 0) return Boths;
      const filtered = Boths.filter((b: Both) => {
        return ids.includes(b.household.householdID);
      });
      return filtered;
    },
    filterByHeadOfHousehold(Boths: Both[]) {
      const filters = this.filters;
      if (!filters || filters.length == 0) return Boths;
      const validFilters = filters.filter(
        (f: Filter) => f.question && f.answer && f.equalTo,
      );
      const filtered = Boths.filter(wi => passesFilters(wi, validFilters));
      return filtered;
    },
    onRegionChange(regions: string[]) {
      this.regions = regions;
    },
    toggleShowAdvanced() {
      this.showAdvanced = !this.showAdvanced;
    },
    closeDialiog(e: MouseEvent) {
      this.filterDialog = false;
      this.pushFiltersToQueryParams();
    },
    loadFiltersFromQueryParams(query: Dictionary<string | (string | null)[]>) {
      if (!query) return;
      this.surveyStatus = (query.surveyStatus ?? 'All') as string;
      this.housingStatus = (query.housingStatus ?? GroundTruthService.GetHousingStatuses()) as HousingStatus[];
      this.approvalStatus = (query.approvalStatus ?? 'All') as string;
      this.shelterStatus = (query.shelterStatus ?? 'All') as string;
      this.filteredHouseholdIDs = (query.prefilteredSurveys ?? []) as string[];
      this.customLocationFilter = (query.customLocationFilter ?? 'All') as string;
      this.missingInformation = (query.missingInformation ?? 'All') as string;
      this.countyFilter = (query.countyFilter ?? '') as string;
      this.cityFilter = (query.cityFilter ?? '') as string;
      this.zipcodeFilter = (query.zipcodeFilter ?? '') as string;
      this.filters = (query.filters ? JSON.parse(query.filters as any) : []) as any;
      this.boundingBox = (query.boundingBox ? JSON.parse(query.boundingBox as any) : []) as any;
      this.volunteer = (query.volunteer ?? 'All') as string;
      this.regions = (query.regions ? query.regions : []) as any;
      this.populations = loadFilterFromQuery(query, 'populations');
      this.races = loadFilterFromQuery(query, "races");
      this.ages = loadFilterFromQuery(query, "ages");
      this.genders = loadFilterFromQuery(query, "genders");
      const shelterIds = query.shelter as string[];
      this.shelterSelection = shelterIds ? this.shelters.filter(s => shelterIds.indexOf(s.id) !== -1) : [];
    },
    pushFiltersToQueryParams() {
      const tab = this.$route.query.tab;
      const query = {
        volunteer: this.volunteer == 'All' ? undefined : this.volunteer,
        surveyStatus: this.surveyStatus == 'All' ? undefined : this.surveyStatus,
        housingStatus: this.housingStatus.length == 0 || this.housingStatus.length === 3 ? undefined : this.housingStatus,
        approvalStatus: this.approvalStatus == 'All' ? undefined : this.approvalStatus,
        shelterStatus: this.shelterStatus == 'All' ? undefined : this.shelterStatus,
        customLocationFilter: this.customLocationFilter == 'All' ? undefined : this.customLocationFilter,
        missingInformation: this.missingInformation == 'All' ? undefined : this.missingInformation,
        prefilteredSurveys: (!this.filteredHouseholdIDs || this.filteredHouseholdIDs.length == 0) ? undefined : this.filteredHouseholdIDs,
        countyFilter: !this.countyFilter ? undefined : this.countyFilter,
        cityFilter: !this.cityFilter ? undefined : this.cityFilter,
        zipcodeFilter: !this.zipcodeFilter ? undefined : this.zipcodeFilter,
        filters: (!this.filters || this.filters.length == 0) ? undefined : JSON.stringify(this.filters),
        boundingBox: (!this.boundingBox || this.boundingBox.length == 0) ? undefined : JSON.stringify(this.boundingBox),
        regions: (!this.regions || this.regions.length == 0) ? undefined : this.regions,
        shelter: (!this.shelterSelection || this.shelterSelection.length == 0) ? undefined : this.shelterSelection.map(s => s.id),
        populations: (!this.populations || this.populations.length == 0) ? undefined : this.populations,
        races: (!this.races || this.races.length == 0) ? undefined : this.races,
        ages: (!this.ages || this.ages.length == 0) ? undefined : this.ages,
        genders: (!this.genders || this.genders.length == 0) ? undefined : this.genders,
        tab
      } as any;

      if (_.isEqual(this.$route.query, query)) return;

      this.$router.push({ path: this.$route.path, query });
    },
    getPrettyDate(date: number) {
      return StringService.getPrettyDate(date);
    },
    addFilter() {
      this.filters.push({
        question: '',
        answer: '',
        equalTo: 'Is',
      });
    },
    removeFilter(index: number) {
      this.filters.splice(index, 1);
    },
    getQuestions(): string[] {
      const households = this.householdsWithAdminAdjustments.map(
        (w: Both) => w.household,
      );
      const questions = DataService.getQuestions(households);
      return ['', ...questions];
    },
    getAnswers(question: string) {
      const households = this.householdsWithAdminAdjustments.map(
        (w: Both) => w.household,
      );
      const answers = DataService.getAnswers(households, question);
      return ['', ...answers];
    },
  },
});

const loadFilterFromQuery = (query: any, paramName: string) => {
  const val = query[paramName];
  if (!val) return [];
  if (!Array.isArray(val)) return [val];
  return val;
}

const filterByProp = (Boths: Both[], prop: string, value: string | string[]) => {
  if (!value) return Boths;
  if (Array.isArray(value) && value.length === 0) return Boths;
  if (Array.isArray(value)) return Boths.filter(b => value.indexOf((b.household as any)[prop]) !== -1);
  return Boths.filter(b => (b.household as any)[prop] === value);
};
const passesFilters = (Both: Both, filters: Filter[]): boolean => {
  for (let filter of filters) {
    let foundQuestion = false;
    // Search in household.
    for (let rawQuestion of Object.keys(Both.household)) {
      const readableQuestion = DataService.nicer(rawQuestion);
      if (readableQuestion === filter.question) {
        const rawAnswer = (Both.household as any)[rawQuestion];
        const readableAnswer = DataService.nicer(rawAnswer);
        const isSame = readableAnswer === filter.answer;
        if (
          (isSame && filter.equalTo === 'Is Not') ||
          (!isSame && filter.equalTo === 'Is')
        ) {
          return false;
        }
        foundQuestion = true;
      }
    }

    // Search in head of household.
    const head = Both.household.HouseholdMembers[0] as HeadOfHousehold;
    for (let rawQuestion of Object.keys(head)) {
      const readableQuestion = DataService.nicer(rawQuestion);
      if (readableQuestion === filter.question) {
        const rawAnswer = (head as any)[rawQuestion];
        const readableAnswer = DataService.nicer(rawAnswer);
        const isSame = readableAnswer === filter.answer;
        if (
          (isSame && filter.equalTo === 'Is Not') ||
          (!isSame && filter.equalTo === 'Is')
        ) {
          return false;
        }
        foundQuestion = true;
      }
    }
    if (!foundQuestion) return false;
  }
  return true;
};
const getApprovalStatus = (conclusion: Conclusion): ApprovalStatus => {
  if (!conclusion.isGuess) return 'Confirmed';
  return ConclusionService.HasIssue(conclusion)
    ? 'Need Attention'
    : 'Unconfirmed';
};
