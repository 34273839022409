import { DischargeStatus, MilitaryBranch } from '@/models/survey';
import { MilitaryBranchExport, DischargeStatusExport } from '@/models/hmis/Individual/HMISClient';

export const HMISMilitary = {
  mapMilitaryBranch(branch?: MilitaryBranch): MilitaryBranchExport {
    if (!branch) return null;
    switch (branch) {
      case 'Army':
        return 1;
      case 'AirForce':
        return 2;
      case 'Navy':
        return 3;
      case 'Marines':
        return 4;
      case 'CoastGuard':
        return 6;
      case 'Not Sure':
        return 8;
      case 'Refused to Answer':
        return 9;
      default:
        return 99;
    }
  },
  mapDischargeStatus(status?: DischargeStatus): DischargeStatusExport {
    if (!status) return null;
    switch (status) {
      case 'Honorable':
        return 1;
      case 'GeneralUnderHonorable':
        return 2;
      case 'BadConduct':
        return 4;
      case 'Dishonorable':
        return 5;
      case 'OtherThanHonorable':
        return 6;
      case 'Uncharacterized':
        return 7;
      case 'Not Sure':
        return 8;
      case 'Refused to Answer':
        return 9;
      default:
        return 99;
    }
  },
};
