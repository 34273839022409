import { FormType } from './../../models/formstructure';
import { firestore } from "firebase";
import _ from "lodash";
import { Store } from "vuex";
import { AdminAdjustment, AdminAdjustmentWrapper } from "../../models/adminadjustment";
import { ShelterSetWithId } from "../../models/all";
import { Survey, SurveyMode, SurveyType, SurveyWithCode } from "../../models/count";
import { Choice, Ele, Form } from "../../models/formstructure";
import { RegionMap } from "../../models/Organization";
import { Household } from "../../models/survey";

export const GetAdminAdjustmentWrapperWithoutStore = (survey: Household, adminAdjustments: AdminAdjustment[]): AdminAdjustmentWrapper => {
    const adjustment = adminAdjustments.find(a => a.householdID === survey.householdID);
    return {
      exists: typeof adjustment !== 'undefined',
      adminAdjustment: adjustment
    }
  }

  export const CountFactory = {
    create(data: firestore.DocumentData, code: string): SurveyWithCode {
      const mode: SurveyMode = data.mode
        ? data.mode
        : data.isLive
          ? 'Live'
          : 'Training';
      return {
        name: data!.name,
        created: data!.created,
        mode,
        observationalSurvey: data!.observationalSurvey,
        interviewSurvey: data!.interviewSurvey,
        code,
        organizationName: data!.organizationName,
        type: data.type ? data.type : 'PIT Count',
        hmisMappings: data.hmisMappings ?? [],
        includeObservationalInExport: data.includeObservationalInExport ?? false,
        locationLookup: data.locationLookup ?? {},
        ...(!!data.regionMap && {regionMap: data.regionMap}),
        ...(!!data.leads && {leads: data.leads}),
        ...(!!data.hotspots && {hotspots: data.hotspots}),
        ...(!!data.shelters && {shelters: data.shelters}),
        ...(!!data.volunteerSignupSurvey && {volunteerSignupSurvey: data.volunteerSignupSurvey}),
      };
    },
  };

  export const PrepareSurveyFormForDownload = (structure: string): string => {
    let form: Form = JSON.parse(structure);
    const pipeline = [
      renderTemplateElements,
      switchDropdownToRadio,
      removeHidden,
      handleHTML,
    ]
    for (const call of pipeline) {
      form = call(form)
    }

    return JSON.stringify(form);
  }

  const renderTemplateElements = (form: Form) => {
    for (const page of form.pages) {
      if (!page.elements) continue;
      for (let i = 0; i < page.elements.length; i++) {
        const element = page.elements[i] as Ele;
        if (element.type !== 'paneldynamic') continue;
        let templateElements: any[] = []
        if (element.name === 'HouseholdMembers') templateElements = getTemplateElements(element.templateElements);
        page.elements = [...page.elements.slice(0, i), ...templateElements, ...page.elements.slice(i+1)]
      }
    }
    return form;
  }

  const handleHTML = (form: Form) => {
    for (const page of form.pages) {
      if (!page.elements) continue;
      for (let i = 0; i < page.elements.length; i++) {
        const element = page.elements[i] as Ele;
        if (element.type !== 'html') continue;
        if (!element.html?.startsWith("The next set of questions asks about")) continue;
        // Remove all non-ascii
       element.html =  element.html
       ?.replace("’", "'")
       ?.replace(/[^\x00-\x7F]/g, "");
      }
    }
    return form;
  }

  const removeHidden = (form: Form) => {
    const dict = getPrettyNameDictFromParsed(form);
    for (const page of form.pages) {
      page.visibleIf = undefined;
      page.visible = true;
      if (!page.elements) continue;
      for (let i = 0; i < page.elements.length; i++) {
        const element = page.elements[i] as Ele;
        if (!element.visibleIf) continue;
        const visibleIfDisplayed = element.visibleIf
        .replaceAll("{", "\"")
        .replaceAll("}", "\"")
        .replaceAll("!=", "is not")
        .replaceAll("panel.", "")
        .replaceAll("AgeHousehold", "Age")
        const customQuestionsReplaced = replaceCustomQuestions(visibleIfDisplayed, dict);
        element.title = `${element.title} (If ${customQuestionsReplaced})`
        element.visibleIf = undefined;
        element.visible = true;
      }
    }
    return form;
  }

  // Replace with question number instead!
  const replaceCustomQuestions = (str: string, dict: Record<string, string>): string => {
    const is = indices(str, "CustomQuestion");
    const full = is.map(i => expand(str, i, '\"', "CustomQuestionf30eb0d3-df3e-41e9-a6ee-5216c00e5899".length + 1));
    const unique = _.uniq(full);
    for (const customQuestion of unique) {
      const toReplace = dict[customQuestion];
      if (!toReplace) throw new Error("can't find custom question");
      if (toReplace.length > 100)
        str = str.replaceAll(customQuestion, toReplace.substring(0, 100) + "...");
      else 
      str = str.replaceAll(customQuestion, toReplace);
    }
    return str;
  }

  const expand = (str: string, i: number, ending: string, term: number) => {
    let end = i + 1;
    while (str[end] !== ending && end - i < term) end++;

    if (end - i == term) throw new Error(`too long! ${str.substring(i, end)}`);
    return str.substring(i, end);
  }

  const indices = (str: string, sub: string): number[] => {
    const found = [];
    let i = 0;
    while (i < str.length) {
      const index = str.indexOf(sub, i);
      if (index === -1) return found;
      found.push(index);
      i = index + sub.length;
    }
    return found;
  }

  const switchDropdownToRadio = (form: Form) => {
    for (const page of form.pages) {
      if (!page.elements) continue;
      for (const element of page.elements) {
       if (element.type === 'dropdown') element.type = 'radiogroup'
      }
    }
    return form;
  }

  const getTemplateElements = (elements: Ele[],): Ele[] => {
    const copies = 3;
    const elementsToReturn = [];
    const title = `Complete the following information for each additional member in the household.`
    elementsToReturn.push({
      "type": "html",
      "name": `DynamicPanelPrompt`,
      "title": "DynamicPanelPrompt",
      "html": `<h1 style=\"color:black;font-size:20px;\">${title}</h1><br/><br/>`
     } as unknown as Ele)
    for (let i = 0; i < copies; i++) {
      elementsToReturn.push({
        "type": "html",
        "name": `HouseholdMemberPrompt${i}`,
        "title": `HouseholdMemberPrompt${i}`,
        "html": `<h1 style=\"font-size:20px;\">Household Member ${i+1}</h1><br/><br/>`
       } as unknown as Ele)
      for (const ele of elements) {
        const toPush = {...ele, name: `${ele.name}${i}`}
        elementsToReturn.push(toPush);
      }
    }
    return elementsToReturn;
  }

  export const getPrettyNameDict = (stringStructure: string): Record<string,string> => {
    const structure = JSON.parse(stringStructure);
    return getPrettyNameDictFromParsed(structure);
  }

  export const getPrettyNameDictFromParsed = (structure: Form): Record<string,string> => {
    const dict = {} as any;
      dict['volunteerName'] = 'Volunteer Name';
      dict['volunteerPhone'] = 'Volunteer Phone #';
      dict['volunteerID'] = 'Volunteer Unique ID';
      dict['IsObservationSurvey'] = 'Was Taken Under Observation';
      dict['customLocation'] = 'Custom Location';
      dict['householdID'] = 'Household ID';
      dict['personalID'] = 'Personal ID';

      function getDisplayNameFromElement(element: Ele) {
        if (_.isObject(element.title)) {
          return !!element.title.default ? element.title.default : element.name;
        } else {
          return !!element.title ? element.title : element.name;
        }
      }
      function getDisplayNameFromChoice(choice: Choice) {
        if (!choice.text) {
          return choice;
        }
        if (_.isObject(choice.text)) {
          return !!choice.text.default ? choice.text.default : choice.value;
        } else {
          return !!choice.text ? choice.text : choice.value;
        }
      }
      function addElement(element: Ele) {
        dict[element.name] = getDisplayNameFromElement(element);

        if (element.choices) {
          for (const choice of element.choices) {
            if (_.isObject(choice)) {
              dict[choice.value] = getDisplayNameFromChoice(choice);
            } else {
              dict[choice] = choice;
            }
          }
        }
        if (element.templateElements) {
          for (const templateElement of element.templateElements) {
            addElement(templateElement);
          }
        }
      }
      function add(obj: Form) {
        for (const page of obj.pages) {
          if (!page.elements) continue;
          for (const element of page.elements) {
            addElement(element);
          }
        }
      }
      add(structure);
      return dict;
  }

  export const structureFromSurvey = (survey: Survey, formType: FormType) => {
    switch (formType) {
      case FormType.PITCountObservation:
        return survey.observationalSurvey
      case FormType.PITCountInterview:
        return survey.interviewSurvey
      case FormType.Other:
        return survey.interviewSurvey
      case FormType.VolunteerSignup:
        return survey.volunteerSignupSurvey
    }
  }