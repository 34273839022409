
import { Survey } from '@/models/count';
import { FormType, FormWrapper } from '@/models/formstructure';
import FormEditor from '@/components/Forms/FormEditor.vue';
import Vue from 'vue';
import { FormStructureService } from '@/services/pure/formstructure';
import { StringService } from '@/services/misc/string';
import { CountService } from '@/services/count';
import { Notification } from '@/services/misc/notification';
import { SurveyPDF } from 'survey-pdf';
import { PrepareSurveyFormForDownload } from '../../services/shared/surveys';
export default Vue.extend({
  components: { FormEditor },
  props: ['survey'],
  data() {
    return {
      showDialog: false,
      selection: 'Interview',
      interviewSurvey: null,
      observationSurvey: null,
      volunteerSignUpSurvey: null,
    };
  },
  computed: {
    surveyComputed(): Survey {
      return this.survey;
    },
    interviewRoute(): string {
      return `/view-form/${this.survey.code}/${FormType.PITCountInterview}`;
    },
    observationRoute(): string {
      return `/view-form/${this.survey.code}/${FormType.PITCountObservation}`;
    },
    volunteerSignUpRoute(): string {
      return `/view-form/${this.survey.code}/${FormType.VolunteerSignup}`;
    },
    formType(): FormType {
      return this.surveyComputed.type == 'Other'
        ? FormType.Other
        : FormType.PITCountInterview;
    },
    observationFormType(): FormType {
      return FormType.PITCountObservation;
    },
    otherSurveys(): FormWrapper[] {
      return this.forms.filter(ss => ss.Type === FormType.Other);
    },
    save() {
      return () => {
        this.showDialog = true;
      };
    },
    forms(): FormWrapper[] {
      return this.$store.getters.formStructures;
    },
    interviewSurveys(): FormWrapper[] {
      return this.forms.filter(ss => ss.Type === FormType.PITCountInterview);
    },
    observationSurveys(): FormWrapper[] {
      return this.forms.filter(ss => ss.Type === FormType.PITCountObservation);
    },
    volunteerSignUpSurveys(): FormWrapper[] {
      return this.forms.filter(ss => ss.Type === FormType.VolunteerSignup);
    },
  },
  methods: {
    downloadInterview() {
      return this.downloadAsPDF(this.survey.interviewSurvey);
    },
    downloadObservation() {
      return this.downloadAsPDF(this.survey.observationalSurvey);
    },
    downloadVolunteerSignUp() {
      return this.downloadAsPDF(this.survey.volunteerSignupSurvey);
    },
    downloadAsPDF(content: any) {
      const prepared = PrepareSurveyFormForDownload(content);
      const surveyPdf = new SurveyPDF(prepared, {
        haveCommercialLicense: true,
      });
      surveyPdf.save('questions.pdf');
    },
    getTitle(survey: FormWrapper) {
      return FormStructureService.GetTitle(survey);
    },
    getTitleWithDate(survey: FormWrapper) {
      return `${FormStructureService.GetTitle(
        survey,
      )} - ${StringService.getPrettyDate(survey.Edited)}`;
    },
    async updateInterview() {
      const newSurvey = (this.interviewSurvey as any).Structure;
      await CountService.update({ ...this.survey, interviewSurvey: newSurvey });
      Notification.methods.send('success', 'Survey updated');
      this.interviewSurvey = null;
    },
    async updateObservation() {
      const newSurvey = (this.observationSurvey as any).Structure;
      await CountService.update({
        ...this.survey,
        observationalSurvey: newSurvey,
      });
      Notification.methods.send('success', 'Survey updated');
      this.observationSurvey = null;
    },
    async updateVolunteerSignUp() {
      const newSurvey = (this.volunteerSignUpSurvey as any).Structure;
      await CountService.update({
        ...this.survey,
        volunteerSignupSurvey: newSurvey,
      });
      Notification.methods.send('success', 'Survey updated');
      this.volunteerSignUpSurvey = null;
    },
  },
});
