
import Vue from 'vue';
import EditHouseholdDialog from '@/components/Dialogs/EditHouseholdDialog.vue';
export default Vue.extend({
  components: {
    EditHouseholdDialog,
  },
  props: ['selected', 'allHouseholds', 'survey'],
  data() {
    return {
      showDialog: false,
    };
  },
});
