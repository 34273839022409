
import Vue from 'vue';
import { StringService } from '@/services/misc/string';
import { FormType, FormWrapper } from '@/models/formstructure';
import { FormStructureService } from '@/services/pure/formstructure';
import _ from 'lodash';
import FormListItem from '@/components/FormListItem.vue';
import CreateFormDialog from '@/components/Dialogs/CreateFormDialog.vue';
import FormFilter from '@/components/Forms/FormFilter.vue';
export default Vue.extend({
  data() {
    return {
      showDialog: false,
      formFilter: null as null | FormType,
    };
  },
  components: {
    FormListItem,
    CreateFormDialog,
    FormFilter,
  },
  computed: {
    forms(): FormWrapper[] {
      const structures: FormWrapper[] = this.$store.getters.formStructures;
      const sorted = structures.sort((a, b) => b.Edited - a.Edited);
      const filtered = sorted.filter(
        form => !this.formFilter || form.Type === this.formFilter,
      );
      return filtered;
    },
  },
  methods: {
    onFilterChange(formFilter: FormType | null) {
      this.formFilter = formFilter;
    },
    prettyDate(date: number) {
      return StringService.getPrettyDate(date);
    },
    surveyTitle(survey: FormWrapper) {
      return FormStructureService.GetTitle(survey);
    },
    isObservation(formType: FormType) {
      return formType === FormType.PITCountObservation;
    },
    isDefault(survey: FormWrapper) {
      return FormStructureService.IsDefaultSurvey(survey);
    },
  },
});
