
import Vue from 'vue';
import { API, Result } from '../repositories/api';
import { Notification } from '../services/misc/notification';
import { FirebaseRepository } from '../repositories/firebase';
import firebase from 'firebase/app';
export default Vue.extend({
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    async login(event: any) {
      event.preventDefault();
      if (this.email === '') {
        Notification.methods.send('error', 'Please enter your email.');
        return;
      }
      if (this.password === '') {
        Notification.methods.send('error', 'Please enter your password.');
        return;
      }
      if (!emailIsValid(this.email)) {
        Notification.methods.send('error', "That email address isn't valid.");
        return;
      }
      this.loading = true;
      const result = await API.methods.loginAdmin(
        firebase.auth(),
        this.email,
        this.password,
      );
      if (result.result === Result.IncorrectPassword) {
        Notification.methods.send('error', 'Incorrect password.');
        this.loading = false;
        return;
      }
      if (result.result === Result.InternalServerError) {
        Notification.methods.send(
          'error',
          'Something went wrong. Please try again.',
        );
        this.loading = false;
        return;
      }
      this.loading = false;
    },
  },
});
function emailIsValid(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}
