
import Vue, { PropType } from 'vue';
import Settings from '@/components/Utility/Settings.vue';
import { FirebaseRepository } from '../../../repositories/firebase';
import { Dashboard, DashboardAccess } from '../../../models/dashboard';
import { Notification } from '@/services/misc/notification';
export default Vue.extend({
  components: {
    Settings,
  },
  props: ['code', 'surveyName', 'orgName'],
  computed: {
    link(): string {
      return `https://dashboard.pointintimecount.com/${this.code}`;
    },
    embedSnippet(): string {
      return `
    <iframe
      width="100%"
      height="500"
      src="${this.link}">
    </iframe>
`
    },
    isChange(): boolean {
      return (
        this.initialTitle !== this.title ||
        this.initialDescription !== this.description ||
        this.initialAccess !== this.access
      );
    },
    initialTitle(): string {
      const dashboard: Dashboard = this.$store.getters.dashboard(this.code);
      if (!dashboard) return this.surveyName;
      return dashboard.settings.title;
    },
    initialDescription(): string {
      const dashboard: Dashboard = this.$store.getters.dashboard(this.code);
      if (!dashboard) return this.orgName;
      return dashboard.settings.description;
    },
    initialAccess(): DashboardAccess {
      const dashboard: Dashboard = this.$store.getters.dashboard(this.code);
      if (!dashboard) return 'noone';
      return dashboard.settings.access;
    },
  },
  mounted(): void {
    this.title = this.initialTitle;
    this.description = this.initialDescription;
    this.access = this.initialAccess;
  },
  methods: {
    
    async save() {
      await FirebaseRepository.setDashboardSettings(this.code, {
        title: this.title,
        description: this.description,
        access: this.access,
      });
      Notification.methods.send('success', 'Survey updated');
    },
  },
  data() {
    return {
      access: 'noone' as DashboardAccess,
      titleRules: [(v: any) => !!v || 'Title required'],
      descriptionRules: [(v: any) => !!v || 'Description required'],
      title: '',
      description: '',
    };
  },
});
