import firebase from 'firebase/app';
import {API, Result } from '../repositories/api';
import { PasswordResetResult } from '../models/ForgotPasswordResult';

export const PasswordAPI = {
    methods: {
      async PasswordResetHandler(
        auth: firebase.auth.Auth,
        newPassword: string,
        repeatNewPassword: string,
      ): Promise<PasswordResetResult> {
        if (newPassword !== repeatNewPassword) {
          return { success: false, message:  'New passwords don\'t match' };
        }

        // Get the action to complete. (Must be 'resetPassword' in our case--we don't handle anything else.)
        // Get the one-time code from the query parameter.
        // (Optional) Ignored here: Get the continue URL from the query parameter if available.
        // (Optional) Ignored here: Get the language code if available.
        const mode = getParameterByName('mode');
        const actionCode = getParameterByName('oobCode');
        if (mode !== 'resetPassword' || actionCode === '') {
          return { success: false, message: 'Something went wrong. Please contact support' };
        }

        return handleResetPassword(auth, actionCode, newPassword);
      },
    },
};

async function handleResetPassword(
  auth: firebase.auth.Auth,
  actionCode: string,
  newPassword: string,
): Promise<PasswordResetResult> {
  try {
    // Client-side validation of the reset code.
    const accountEmail = await auth.verifyPasswordResetCode(actionCode);
    await auth.confirmPasswordReset(actionCode, newPassword);
    return { success: true, message: 'Password update successful. You can now log in with your new password.' };

  } catch (error) {
    return { success: false, message: 'Please try again: ' + error.message };
  }
}

function getParameterByName(name: string): string {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(name);
  if (param == null) {
    return '';
  }
  return param;
}
