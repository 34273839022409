
import Vue from 'vue';
import { Display } from '../../models/cleanup';
import { SurveyWithCode } from '../../models/count';
export default Vue.extend({
  props: ['selected', 'survey'],
  computed: {
    disabled() {
      return (this.selected as Display[]).length !== 2;
    },
    path() {
      if (this.disabled) return '';
      const selected = this.selected as Display[];
      return `/survey/${(this.survey as SurveyWithCode).code}/compare/${
        selected[0].ID
      }/${selected[1].ID}`;
    },
  },
});
