
import VolunteerGrid from '@/components/PITCount/Volunteers/VolunteerGrid.vue';
import VolunteerGridRegistration from '@/components/PITCount/Volunteers/VolunteerGridRegistration.vue';
import { Household, SubmissionWrapper, VolunteerRegistration } from '@/models/survey';
import * as _ from 'lodash';
import Vue, { PropType } from 'vue';
import VolunteerMap from '@/components/Maps/VolunteerMap.vue';
import { SurveyWithCode } from '../../models/count';
export default Vue.extend({
  props: {
    id: {
      type: String,
    },
    submissions: {
      type: Array as PropType<Household[]>,
    },
  },
  components: {
    VolunteerGrid,
    VolunteerMap,
    VolunteerGridRegistration,
  },
  computed: {
    volunteerRegistrations(): VolunteerRegistration[] {
      return this.$store.getters.volunteerRegistrations[this.id];
    },
    volunteerRegistration(): boolean {
      return this.survey.mode === 'Volunteer Registration'
    },
    survey(): SurveyWithCode  {
     return this.$store.getters.Survey(this.id);
    },
    submissionsGroupedByVolunteer(): _.Dictionary<Household[]> {
      const result = _.groupBy(
        this.submissions as Household[],
        (h: Household) => h.volunteerName.toUpperCase().trim(),
      );
      return result;
    },
  },
});
