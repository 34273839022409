import { render, staticRenderFns } from "./AllUnknownAge.vue?vue&type=template&id=53cd47e1&scoped=true&"
import script from "./AllUnknownAge.vue?vue&type=script&lang=ts&"
export * from "./AllUnknownAge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53cd47e1",
  null
  
)

export default component.exports