import { SubAnalytics } from '../../models/analytics';
import { SurveyWithCode } from '../../models/count';
import { Ele, FormType } from '../../models/formstructure';
import { Household, SubmissionWrapper } from '../../models/survey';
import Store from '../../store';
import _ from 'lodash';
import { ReportSection } from './../../models/all';
import { BreakdownWrapper } from './breakdown';
import { ConclusionService } from './conclusion';
import { getHouseholdsFromSubmissionWrappers } from '../shared/analytics';
interface Total {
  title: string;
  value: number;
}
export const AnalyticsService = {
  householdsWithCounties: [] as Household[],
  getHouseholds(code: string): Household[] | null {
    let submissionWrappers: SubmissionWrapper[] =
      Store.getters.submissions[code];

    if (!submissionWrappers) return null;
    const survey = Store.getters.surveys.find(
      (s: SurveyWithCode) => s.code === code,
    );

    if (Store.getters.isTrialAccount) {
      submissionWrappers = this.filterForTrial(submissionWrappers);
    }

    const locationLookup = Store.getters.locationLookup(code);
    const households = getHouseholdsFromSubmissionWrappers(
      submissionWrappers,
      survey,
      locationLookup,
    );

    this.populateCountyData(households);
    return households;
  },
  populateCountyData(households: Household[]) {
    const nonUnknownLocationHouseholds = households.filter(
      (h: any) => h.location && h.location !== 'Unknown',
    );
    const householdsWithoutCounties = nonUnknownLocationHouseholds.filter(
      n =>
        this.householdsWithCounties.find(
          h => h.householdID === n.householdID,
        ) === undefined,
    );
    if (!householdsWithoutCounties || householdsWithoutCounties.length === 0)
      return;
    Store.dispatch('populateCountyData', householdsWithoutCounties);
    this.householdsWithCounties.push(...householdsWithoutCounties);
  },
  filterForTrial(surveyWrappers: SubmissionWrapper[]): SubmissionWrapper[] {
    let newSurveysToLetThrough = 5;
    const wrappersToReturn = [];
    for (const wrapper of surveyWrappers) {
      const collectedDuringTrial = wrapper.date > Store.getters.paidThrough;
      if (collectedDuringTrial) {
        newSurveysToLetThrough--;
        if (newSurveysToLetThrough < 0) break;
      }
      wrappersToReturn.push(wrapper);
    }
    return wrappersToReturn;
  },
  getProtectedQuestions(type: FormType) {
    if (type === FormType.Other) return [];
    if (type === FormType.VolunteerSignup) return [
      'FirstName', 'LastName', 'Email'
    ]
    return [
      'Where They Stayed Last Night',
      'Completed Survey Before',
      'Was In Facility Less Than 90 Days',
      'Where They Stayed Before Institution',
      'Homeless Before Institution For a Year Or More',
      'Initials Or Name',
      'First Time Being Homeless',
      'Homelessness Duration',
      'Times Homeless In Last Three Years',
      'Months Homeless In Last Three Years',
      'Observation Survey Reason',
      'Substance Use',
      'Mental Health Concern',
      'Physical Disability',
      'HIV/AIDS',
      'Experienced Domestic Violence',
      'Relation',
      'Veteran',
      'Gender',
      'Race',
      'Hispanic',
      'Age',
      'HouseholdMembers',
      'Substance UseHousehold',
      'HIV/AIDSHousehold',
      'Mental Health ConcernHousehold',
      'Physical DisabilityHousehold',
      'Experienced Domestic ViolenceHousehold',
      'GenderHousehold',
      'RaceHousehold',
      'HispanicHousehold',
      'VeteranHousehold',
      'AgeHousehold',
      'Notes',
    ];
  },
  CreateSubAnalytics(
    breakdownWrapper: BreakdownWrapper,
    keys: string[],
  ): ReportSection[] {
    if (['2021', '2022', '2023'].includes(breakdownWrapper.year)) {
      return this.CreateSubAnalytics2023(breakdownWrapper, keys);
    }
    return this.CreateSubAnalytics2024(breakdownWrapper, keys);

  },
  CreateSubAnalytics2023(
    breakdownWrapper: BreakdownWrapper,
    keys: string[],
  ): ReportSection[] {
    let total = breakdownWrapper.Total as any;
    let unsheltered = breakdownWrapper.Unsheltered as any;
    let emergency = breakdownWrapper.Emergency as any;
    let transitional = breakdownWrapper.Transitional as any;
    let safeHaven = breakdownWrapper.SafeHaven as any;
    for (const key of keys) {
      total = total[key];
      unsheltered = unsheltered[key];
      emergency = emergency[key];
      transitional = transitional[key];
      safeHaven = safeHaven[key];
    }
    return [
      {
        title: 'Gender',
        elements: [
          {
            title: 'Female',
            Total: total.Gender.Female,
            Unsheltered: unsheltered.Gender.Female,
            Emergency: emergency.Gender.Female,
            Transitional: transitional.Gender.Female,
            SafeHaven: safeHaven.Gender.Female,
          },
          {
            title: 'Male',
            Total: total.Gender.Male,
            Unsheltered: unsheltered.Gender.Male,
            Emergency: emergency.Gender.Male,
            Transitional: transitional.Gender.Male,
            SafeHaven: safeHaven.Gender.Male,
          },
          {
            title:
              "A gender that is not singularly 'Female' or 'Male'",
            Total: total.Gender.Nonconforming,
            Unsheltered: unsheltered.Gender.Nonconforming,
            Emergency: emergency.Gender.Nonconforming,
            Transitional: transitional.Gender.Nonconforming,
            SafeHaven: safeHaven.Gender.Nonconforming,
          },
          {
            title: 'Questioning',
            Total: total.Gender.Questioning,
            Unsheltered: unsheltered.Gender.Questioning,
            Emergency: emergency.Gender.Questioning,
            Transitional: transitional.Gender.Questioning,
            SafeHaven: safeHaven.Gender.Questioning,
          },
          {
            title: 'Transgender',
            Total: total.Gender.Transgender,
            Unsheltered: unsheltered.Gender.Transgender,
            Emergency: emergency.Gender.Transgender,
            Transitional: transitional.Gender.Transgender,
            SafeHaven: safeHaven.Gender.Transgender,
          },
          {
            title: 'Multiple',
            Total: total.Gender.Multiple,
            Unsheltered: unsheltered.Gender.Multiple,
            Emergency: emergency.Gender.Multiple,
            Transitional: transitional.Gender.Multiple,
            SafeHaven: safeHaven.Gender.Multiple,
          },
          {
            title: 'Unknown',
            Total: total.Gender.Unknown,
            Unsheltered: unsheltered.Gender.Unknown,
            Emergency: emergency.Gender.Unknown,
            Transitional: transitional.Gender.Unknown,
            SafeHaven: safeHaven.Gender.Unknown,
          },
        ],
      },
      {
        title: 'Ethnicity',
        elements: [
          {
            title: 'Non-Hispanic/Non-Latin(a)(o)(x)',
            Total: total.Ethnicity.Non,
            Unsheltered: unsheltered.Ethnicity.Non,
            Emergency: emergency.Ethnicity.Non,
            Transitional: transitional.Ethnicity.Non,
            SafeHaven: safeHaven.Ethnicity.Non,
          },
          {
            title: 'Hispanic/Latin(a)(o)(x)',
            Total: total.Ethnicity.Hispanic,
            Unsheltered: unsheltered.Ethnicity.Hispanic,
            Emergency: emergency.Ethnicity.Hispanic,
            Transitional: transitional.Ethnicity.Hispanic,
            SafeHaven: safeHaven.Ethnicity.Hispanic,
          },
          {
            title: 'Unknown',
            Total: total.Ethnicity.Unknown,
            Unsheltered: unsheltered.Ethnicity.Unknown,
            Emergency: emergency.Ethnicity.Unknown,
            Transitional: transitional.Ethnicity.Unknown,
            SafeHaven: safeHaven.Ethnicity.Unknown,
          },
        ],
      },
      {
        title: 'Race',
        elements: [
          {
            title: 'American Indian, Alaska Native, or Indigenous',
            Total: total.Race.Indian,
            Unsheltered: unsheltered.Race.Indian,
            Emergency: emergency.Race.Indian,
            Transitional: transitional.Race.Indian,
            SafeHaven: safeHaven.Race.Indian,
          },
          {
            title: 'Asian or Asian American',
            Total: total.Race.Asian,
            Unsheltered: unsheltered.Race.Asian,
            Emergency: emergency.Race.Asian,
            Transitional: transitional.Race.Asian,
            SafeHaven: safeHaven.Race.Asian,
          },
          {
            title: 'Black, African American, or African',
            Total: total.Race.Black,
            Unsheltered: unsheltered.Race.Black,
            Emergency: emergency.Race.Black,
            Transitional: transitional.Race.Black,
            SafeHaven: safeHaven.Race.Black,
          },
          {
            title: 'Native Hawaiian or Pacific Islander',
            Total: total.Race.PacificIslander,
            Unsheltered: unsheltered.Race.PacificIslander,
            Emergency: emergency.Race.PacificIslander,
            Transitional: transitional.Race.PacificIslander,
            SafeHaven: safeHaven.Race.PacificIslander,
          },
          {
            title: 'White',
            Total: total.Race.White,
            Unsheltered: unsheltered.Race.White,
            Emergency: emergency.Race.White,
            Transitional: transitional.Race.White,
            SafeHaven: safeHaven.Race.White,
          },
          {
            title: 'Multiple Races',
            Total: total.Race.Multiple,
            Unsheltered: unsheltered.Race.Multiple,
            Emergency: emergency.Race.Multiple,
            Transitional: transitional.Race.Multiple,
            SafeHaven: safeHaven.Race.Multiple,
          },
          {
            title: 'Unknown',
            Total: total.Race.Unknown,
            Unsheltered: unsheltered.Race.Unknown,
            Emergency: emergency.Race.Unknown,
            Transitional: transitional.Race.Unknown,
            SafeHaven: safeHaven.Race.Unknown,
          },
        ],
      },
      {
        title: 'Chronically Homeless',
        elements: [
          {
            title: 'Total number of households',
            Total: total.ChronicHouseholds,
            Unsheltered: unsheltered.ChronicHouseholds,
            Emergency: emergency.ChronicHouseholds,
            Transitional: transitional.ChronicHouseholds,
            SafeHaven: safeHaven.ChronicHouseholds,
          },
          {
            title: 'Total number of persons',
            Total: total.ChronicMembers,
            Unsheltered: unsheltered.ChronicMembers,
            Emergency: emergency.ChronicMembers,
            Transitional: transitional.ChronicMembers,
            SafeHaven: safeHaven.ChronicMembers,
          },
        ],
      },
    ];
  },
  CreateSubAnalytics2024(
    breakdownWrapper: BreakdownWrapper,
    keys: string[],
  ): ReportSection[] {
    let total = breakdownWrapper.Total as any;
    let unsheltered = breakdownWrapper.Unsheltered as any;
    let emergency = breakdownWrapper.Emergency as any;
    let transitional = breakdownWrapper.Transitional as any;
    let safeHaven = breakdownWrapper.SafeHaven as any;

    for (let key of keys) {
      total = total[key];
      unsheltered = unsheltered[key];
      emergency = emergency[key];
      transitional = transitional[key];
      safeHaven = safeHaven[key];
    }
    return [
      {
        title: 'Gender',
        elements: [
          {
            title: 'Woman (Girl, if child)',
            Total: total.Gender.Female,
            Unsheltered: unsheltered.Gender.Female,
            Emergency: emergency.Gender.Female,
            Transitional: transitional.Gender.Female,
            SafeHaven: safeHaven.Gender.Female,
          },
          {
            title: 'Man (Boy, if child)',
            Total: total.Gender.Male,
            Unsheltered: unsheltered.Gender.Male,
            Emergency: emergency.Gender.Male,
            Transitional: transitional.Gender.Male,
            SafeHaven: safeHaven.Gender.Male,
          },
          {
            title:
              "Non-Binary",
            Total: total.Gender.Nonconforming,
            Unsheltered: unsheltered.Gender.Nonconforming,
            Emergency: emergency.Gender.Nonconforming,
            Transitional: transitional.Gender.Nonconforming,
            SafeHaven: safeHaven.Gender.Nonconforming,
          },
          {
            title: 'Questioning',
            Total: total.Gender.Questioning,
            Unsheltered: unsheltered.Gender.Questioning,
            Emergency: emergency.Gender.Questioning,
            Transitional: transitional.Gender.Questioning,
            SafeHaven: safeHaven.Gender.Questioning,
          },
          {
            title: 'Culturally Specific Identity (e.g., TwoSpirit)',
            Total: total.Gender.CulturallySpecificIdentity,
            Unsheltered: unsheltered.Gender.CulturallySpecificIdentity,
            Emergency: emergency.Gender.CulturallySpecificIdentity,
            Transitional: transitional.Gender.CulturallySpecificIdentity,
            SafeHaven: safeHaven.Gender.CulturallySpecificIdentity,
          },
          {
            title: 'Transgender',
            Total: total.Gender.Transgender,
            Unsheltered: unsheltered.Gender.Transgender,
            Emergency: emergency.Gender.Transgender,
            Transitional: transitional.Gender.Transgender,
            SafeHaven: safeHaven.Gender.Transgender,
          },
          {
            title: 'Different Identity',
            Total: total.Gender.DifferentIdentity,
            Unsheltered: unsheltered.Gender.DifferentIdentity,
            Emergency: emergency.Gender.DifferentIdentity,
            Transitional: transitional.Gender.DifferentIdentity,
            SafeHaven: safeHaven.Gender.DifferentIdentity,
          },
          {
            title: 'Multiple',
            Total: total.Gender.Multiple,
            Unsheltered: unsheltered.Gender.Multiple,
            Emergency: emergency.Gender.Multiple,
            Transitional: transitional.Gender.Multiple,
            SafeHaven: safeHaven.Gender.Multiple,
          },
          {
            title: 'Multiple Includes Woman (Girl if child)',
            Total: total.Gender.MultipleIncludesWoman,
            Unsheltered: unsheltered.Gender.MultipleIncludesWoman,
            Emergency: emergency.Gender.MultipleIncludesWoman,
            Transitional: transitional.Gender.MultipleIncludesWoman,
            SafeHaven: safeHaven.Gender.MultipleIncludesWoman,
          },
          {
            title: 'Multiple Includes Man (Boy if child)',
            Total: total.Gender.MultipleIncludesMan,
            Unsheltered: unsheltered.Gender.MultipleIncludesMan,
            Emergency: emergency.Gender.MultipleIncludesMan,
            Transitional: transitional.Gender.MultipleIncludesMan,
            SafeHaven: safeHaven.Gender.MultipleIncludesMan,
          },
          {
            title: 'Multiple Includes Non-Binary',
            Total: total.Gender.MultipleIncludesNonConforming,
            Unsheltered: unsheltered.Gender.MultipleIncludesNonConforming,
            Emergency: emergency.Gender.MultipleIncludesNonConforming,
            Transitional: transitional.Gender.MultipleIncludesNonConforming,
            SafeHaven: safeHaven.Gender.MultipleIncludesNonConforming,
          },
          {
            title: 'Multiple Includes Questioning',
            Total: total.Gender.MultipleIncludesQuestioning,
            Unsheltered: unsheltered.Gender.MultipleIncludesQuestioning,
            Emergency: emergency.Gender.MultipleIncludesQuestioning,
            Transitional: transitional.Gender.MultipleIncludesQuestioning,
            SafeHaven: safeHaven.Gender.MultipleIncludesQuestioning,
          },
          {
            title: 'Multiple Includes Culturally Specific Identity (e.g., Two-Spirit)',
            Total: total.Gender.MultipleIncludesCulturallySpecificIdentity,
            Unsheltered: unsheltered.Gender.MultipleIncludesCulturallySpecificIdentity,
            Emergency: emergency.Gender.MultipleIncludesCulturallySpecificIdentity,
            Transitional: transitional.Gender.MultipleIncludesCulturallySpecificIdentity,
            SafeHaven: safeHaven.Gender.MultipleIncludesCulturallySpecificIdentity,
          },
          {
            title: 'Multiple Includes Transgender',
            Total: total.Gender.MultipleIncludesTransgender,
            Unsheltered: unsheltered.Gender.MultipleIncludesTransgender,
            Emergency: emergency.Gender.MultipleIncludesTransgender,
            Transitional: transitional.Gender.MultipleIncludesTransgender,
            SafeHaven: safeHaven.Gender.MultipleIncludesTransgender,
          },
          {
            title: 'Multiple Includes Different Identity',
            Total: total.Gender.MultipleIncludesDifferentIdentity,
            Unsheltered: unsheltered.Gender.MultipleIncludesDifferentIdentity,
            Emergency: emergency.Gender.MultipleIncludesDifferentIdentity,
            Transitional: transitional.Gender.MultipleIncludesDifferentIdentity,
            SafeHaven: safeHaven.Gender.MultipleIncludesDifferentIdentity,
          },
          {
            title: 'Unknown',
            Total: total.Gender.Unknown,
            Unsheltered: unsheltered.Gender.Unknown,
            Emergency: emergency.Gender.Unknown,
            Transitional: transitional.Gender.Unknown,
            SafeHaven: safeHaven.Gender.Unknown,
          },
        ],
      },
      {
        title: 'Race and Ethnicity',
        elements: [
          {
            title: 'American Indian, Alaska Native, or Indigenous',
            Total: total.Race.Indian,
            Unsheltered: unsheltered.Race.Indian,
            Emergency: emergency.Race.Indian,
            Transitional: transitional.Race.Indian,
            SafeHaven: safeHaven.Race.Indian,
          },
          {
            title: 'American Indian, Alaska Native, or Indigenous & Hispanic/Latina/e/o',
            Total: total.Race.IndianAndHispanic,
            Unsheltered: unsheltered.Race.IndianAndHispanic,
            Emergency: emergency.Race.IndianAndHispanic,
            Transitional: transitional.Race.IndianAndHispanic,
            SafeHaven: safeHaven.Race.IndianAndHispanic,
          },
          {
            title: 'Asian or Asian American',
            Total: total.Race.Asian,
            Unsheltered: unsheltered.Race.Asian,
            Emergency: emergency.Race.Asian,
            Transitional: transitional.Race.Asian,
            SafeHaven: safeHaven.Race.Asian,
          },
          {
            title: 'Asian or Asian American & Hispanic/Latina/e/o',
            Total: total.Race.AsianAndHispanic,
            Unsheltered: unsheltered.Race.AsianAndHispanic,
            Emergency: emergency.Race.AsianAndHispanic,
            Transitional: transitional.Race.AsianAndHispanic,
            SafeHaven: safeHaven.Race.AsianAndHispanic,
          },
          {
            title: 'Black, African American, or African',
            Total: total.Race.Black,
            Unsheltered: unsheltered.Race.Black,
            Emergency: emergency.Race.Black,
            Transitional: transitional.Race.Black,
            SafeHaven: safeHaven.Race.Black,
          },
          {
            title: 'Black, African American, or African & Hispanic/Latina/e/o',
            Total: total.Race.BlackAndHispanic,
            Unsheltered: unsheltered.Race.BlackAndHispanic,
            Emergency: emergency.Race.BlackAndHispanic,
            Transitional: transitional.Race.BlackAndHispanic,
            SafeHaven: safeHaven.Race.BlackAndHispanic,
          },
          {
            title: 'Hispanic/Latina/e/o',
            Total: total.Race.Hispanic,
            Unsheltered: unsheltered.Race.Hispanic,
            Emergency: emergency.Race.Hispanic,
            Transitional: transitional.Race.Hispanic,
            SafeHaven: safeHaven.Race.Hispanic,
          },
          {
            title: 'Native Hawaiian or Pacific Islander',
            Total: total.Race.PacificIslander,
            Unsheltered: unsheltered.Race.PacificIslander,
            Emergency: emergency.Race.PacificIslander,
            Transitional: transitional.Race.PacificIslander,
            SafeHaven: safeHaven.Race.PacificIslander,
          },
          {
            title: 'Native Hawaiian or Pacific Islander & Hispanic/Latina/e/o',
            Total: total.Race.PacificIslanderAndHispanic,
            Unsheltered: unsheltered.Race.PacificIslanderAndHispanic,
            Emergency: emergency.Race.PacificIslanderAndHispanic,
            Transitional: transitional.Race.PacificIslanderAndHispanic,
            SafeHaven: safeHaven.Race.PacificIslanderAndHispanic,
          },
          {
            title: 'White',
            Total: total.Race.White,
            Unsheltered: unsheltered.Race.White,
            Emergency: emergency.Race.White,
            Transitional: transitional.Race.White,
            SafeHaven: safeHaven.Race.White,
          },
          {
            title: 'White & Hispanic/Latina/e/o',
            Total: total.Race.WhiteAndHispanic,
            Unsheltered: unsheltered.Race.WhiteAndHispanic,
            Emergency: emergency.Race.WhiteAndHispanic,
            Transitional: transitional.Race.WhiteAndHispanic,
            SafeHaven: safeHaven.Race.WhiteAndHispanic,
          },
          {
            title: 'Middle Eastern or North African',
            Total: total.Race.MiddleEastern,
            Unsheltered: unsheltered.Race.MiddleEastern,
            Emergency: emergency.Race.MiddleEastern,
            Transitional: transitional.Race.MiddleEastern,
            SafeHaven: safeHaven.Race.MiddleEastern,
          },
          {
            title: 'Middle Eastern or North African & Hispanic/Latina/e/o',
            Total: total.Race.MiddleEasternAndHispanic,
            Unsheltered: unsheltered.Race.MiddleEasternAndHispanic,
            Emergency: emergency.Race.MiddleEasternAndHispanic,
            Transitional: transitional.Race.MiddleEasternAndHispanic,
            SafeHaven: safeHaven.Race.MiddleEasternAndHispanic,
          },
          {
            title: 'Multi-racial & Hispanic/Latina/e/o',
            Total: total.Race.MultipleAndHispanic,
            Unsheltered: unsheltered.Race.MultipleAndHispanic,
            Emergency: emergency.Race.MultipleAndHispanic,
            Transitional: transitional.Race.MultipleAndHispanic,
            SafeHaven: safeHaven.Race.MultipleAndHispanic,
          },
          {
            title: 'Multi-racial (not Hispanic/Latina/e/o)',
            Total: total.Race.MultipleNotHispanic,
            Unsheltered: unsheltered.Race.MultipleNotHispanic,
            Emergency: emergency.Race.MultipleNotHispanic,
            Transitional: transitional.Race.MultipleNotHispanic,
            SafeHaven: safeHaven.Race.MultipleNotHispanic,
          },
          {
            title: 'Unknown',
            Total: total.Race.Unknown,
            Unsheltered: unsheltered.Race.Unknown,
            Emergency: emergency.Race.Unknown,
            Transitional: transitional.Race.Unknown,
            SafeHaven: safeHaven.Race.Unknown,
          },
        ],
      },
      {
        title: 'Chronically Homeless',
        elements: [
          {
            title: 'Total number of households',
            Total: total.ChronicHouseholds,
            Unsheltered: unsheltered.ChronicHouseholds,
            Emergency: emergency.ChronicHouseholds,
            Transitional: transitional.ChronicHouseholds,
            SafeHaven: safeHaven.ChronicHouseholds,
          },
          {
            title: 'Total number of persons',
            Total: total.ChronicMembers,
            Unsheltered: unsheltered.ChronicMembers,
            Emergency: emergency.ChronicMembers,
            Transitional: transitional.ChronicMembers,
            SafeHaven: safeHaven.ChronicMembers,
          },
        ],
      },
    ];
  },
};

