import { HeadOfHousehold, Household } from '@/models/survey';
import { HMISFinalExport, HMISFinalIndividualData } from '@/models/hmis/HMISFinalExport';
import { DataService } from '../data';
import { HMISClient } from '@/models/hmis/Individual/HMISClient';
import { HMISEnrollment } from '@/models/hmis/Individual/HMISEnrollment';
import {
  HMISClientService, HMISDisabilitiesService, HMISEnrollmentService, HMISHealthAndDVService
} from './exports/individual';
import { HMISDisabilities } from '@/models/hmis/Individual/HMISDisabilities';
import { HMISHealthAndDV } from '@/models/hmis/Individual/HMISHealthAndDV';
import { HMISExportFieldMapping } from '@/models/count';
import { findReportingYearForHousehold } from '../../year-finder';

export const HMISFinalExportService = {
  getIndividualLevelData(households: Household[], mappings: HMISExportFieldMapping[]): HMISFinalIndividualData {
    const exportID = DataService.generateRandomId();
    const projectID = DataService.generateRandomId();
    const userID = DataService.generateRandomId();

    const clients: HMISClient[] = [];
    const enrollments: HMISEnrollment[] = [];
    const disabilities: HMISDisabilities[] = [];
    const healthAndDV: HMISHealthAndDV[] = [];

    households.forEach(household => {
      const householdID = DataService.generateRandomId();
      const head = household.HouseholdMembers[0] as HeadOfHousehold;
      household.HouseholdMembers.forEach(member => {
        const personalID = DataService.generateRandomId();
        const year = findReportingYearForHousehold(household);
        const client = HMISClientService.getClientRecord(member, head, personalID, userID, exportID, mappings, year);
        const enrollment = HMISEnrollmentService.getEnrollmentRecord(member, head, householdID, personalID, projectID, userID, exportID);
        const memberDisabilities = HMISDisabilitiesService.getDisabilitiesRecords(enrollment, member);
        const memberHealthAndDV = HMISHealthAndDVService.getHealthAndDVRecord(enrollment, member);

        clients.push(client);
        enrollments.push(enrollment);
        if (memberDisabilities)
          memberDisabilities.forEach(d => disabilities.push(d));
        healthAndDV.push(memberHealthAndDV);
      });
    });

    return {
      Clients: clients,
      Enrollments: enrollments,
      Disabilities: disabilities !== null ? disabilities : HMISDisabilitiesService.getDisabilitiesHeaderRow(),
      HealthAndDV: healthAndDV,
    }
  }
}
