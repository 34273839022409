
interface Item {
  title: string;
  path: string;
}
import Vue from 'vue';
interface Item {
  title: string;
  path: string;
  icon: string;
}
export default Vue.extend({
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  data: () => ({
    settingsRoutes: [
      {
        title: 'Organization',
        path: '/organization',
        icon: 'business',
      },
      {
        title: 'Shelters',
        path: '/shelters',
        icon: 'mdi-home-group',
      },
      {
        title: 'Questions',
        path: '/questions',
        icon: 'assignment',
      },
    ],
    home: {
      title: 'Home',
      path: '/home',
      icon: 'home',
    },
  }),
});
