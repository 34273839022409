
import { Notification } from '@/services/misc/notification';
import Vue from 'vue';
import firebase from 'firebase';
import Settings from '@/components/Utility/Settings.vue';
import { update } from 'lodash';
import { FirebaseRepository } from '@/repositories/firebase';
export default Vue.extend({
  components: {
    Settings
  },
  data() {
    return {
      password: '',
      newPassword: '',
      repeatPassword: '',
      loading: false,
    };
  },
  computed: {
    useUUIDs: {
      get() {
        return this.$store.getters.preferences?.useUUIDs ?? false;
      },
      set(value: boolean) {
        this.updateUseUUIDs(value);
      }
    },
  },
  methods: {
    async updateUseUUIDs(value: boolean) {
      const result = await FirebaseRepository.updateUseUUIDs(value);
    },
    checkInputs(): boolean {
      if (this.password === '') {
        Notification.methods.send('error', 'Please enter your current password.');
        return false;
      }
      if (this.newPassword === '') {
        Notification.methods.send('error', 'Please enter your new password.');
        return false;
      }

      if (this.repeatPassword === '') {
        Notification.methods.send('error', 'Please repeat your password.');
        return false;
      }

      if (this.newPassword !== this.repeatPassword) {
        Notification.methods.send('error', "Passwords don't match.");
        return false;
      }
      return true;

    },
    async setPassword(event: any) {
      event.preventDefault();
      this.loading = true;
      const result = this.checkInputs();
      if (!result) {
        this.loading = false;
        return;
      }
      firebase.auth()
        .signInWithEmailAndPassword(this.$store.getters.user.email, this.password)
        .then(() => {
          this.$store.getters.user.updatePassword(this.newPassword)
            .then(() => {
              Notification.methods.send('success', "Password changed.");

            }).catch((err: any) => {
              console.log(err);
              Notification.methods.send('error', `Error: ${err.message}`);
            })

        }).catch((err) => {
          console.log(err);
          Notification.methods.send('error', `Couldn't authenticate with your old password. Did you enter it correctly?`);
        })
      this.loading = false;
    },
  },
});
