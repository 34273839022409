
import Vue from 'vue';
import { Household } from '../models/survey';
import SurveyListener from '@/components/PITCount/SurveyListener.vue';
import { Both} from "../Both"
import { ConclusionService } from '../services/pure/conclusion';
import CompareComponent from '@/components/Cleanup/Compare/Compare.vue';
import { AnalyticsService } from '../services/pure/analytics';
import { EntryService } from '@/services/entry';

export default Vue.extend({
  components: {
    SurveyListener,
    CompareComponent,
  },
  props: {
    surveyID: String,
    householdID1: String,
    householdID2: String,
  },
  computed: {
    submissions(): Household[] {
      return AnalyticsService.getHouseholds(this.surveyID) ?? [];
    },
    one(): Both {
      return this.getBoth(this.householdID1, this.submissions) as Both;
    },
    two(): Both {
      return this.getBoth(this.householdID2, this.submissions) as Both;
    },
  },
  methods: {
    getBoth(householdID: string, households: Household[]) {
      if (households.length === 0) return null;
      const household = households.find(
        s => s.householdID === householdID,
      ) as Household;
      const conclusion = EntryService.GetAdminAdjustment(
        household,
        this.surveyID,
      );
      return { household, conclusion };
    },
  },
});
