
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      items: ['Age', 'Ethnicity', 'Race', 'Gender'],
      selections: ['Age', 'Ethnicity', 'Race', 'Gender'],
    };
  },
});
