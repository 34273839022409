
import Vue from 'vue';
import { SurveyService } from '@/services/pure/surveys';
import { DataService } from '../../services/pure/data';

export default Vue.extend({
  name: 'IndividualDetails',
  props: ['householdMember'],
  computed: {
    memberProperties() {
      return Object.entries(this.householdMember);
    },
  },
  methods: {
    nicer(value: string) {
      return DataService.nicer(value);
    }
  }
});
