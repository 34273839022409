
import Vue from 'vue';
import Settings from '@/components/Utility/Settings.vue';
import formRules from '@/services/misc/form-validation';
import GeographicService from '@/services/pure/geo';
import { getKMLData } from '@/services/pure/kmz';
import _ from 'lodash';
import {
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Properties,
} from '@turf/turf';
import { FirebaseRepository } from '@/repositories/firebase';
import { SurveyWithCode } from '@/models/count';
import { Notification } from '@/services/misc/notification';
export default Vue.extend({
  components: {
    Settings,
  },
  data() {
    return {
      kmzRules: formRules.kmz,
      file: null as null | File,
      hotspotCollection: null as null | FeatureCollection<
        Geometry | GeometryCollection,
        Properties
      >,
      content: null as null | string,
    };
  },
  watch: {
    file: {
      async handler(newVal: File) {
        function third(buf: any) {
            const uint8Array = new Uint8Array(buf);
            const data = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
            return data;
        }
        function _arrayBufferToBase64(buffer: any) {
          var binary = '';
          var bytes = new Uint8Array(buffer);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return window.btoa(binary);
        }
        function ab2str(buf: any) {
          return String.fromCharCode.apply(null, new Uint8Array(buf) as any);
        }
        if (!newVal) {
          this.hotspotCollection = ({
            features: [],
          } as unknown) as FeatureCollection<
            Geometry | GeometryCollection,
            Properties
          >;
          return;
        }
        let reader = new FileReader();
        reader.readAsArrayBuffer(newVal);
        reader.onload = async evt => {
          const arrayBuffer = evt.target!.result as ArrayBuffer;
          this.content = third(arrayBuffer);
        };
      },
    },
  },
  methods: {
    async save() {
      const survey: SurveyWithCode = this.$store.get('survey/survey');
      const result = await FirebaseRepository.uploadHotspots(survey.code, this
        .content as string);
      const status = result ? 'success' : 'error';
      const message = result ? 'Upload successful' : 'Something went wrong';
      Notification.methods.send(status, message);
    },
  },
});
