
import Vue from 'vue';
import DuplicatesDialog from '@/components/Dialogs/DuplicatesDialog.vue';
export default Vue.extend({
  components: {
    DuplicatesDialog,
  },
  props: ['both', 'surveyID'],
  data() {
    return {
      showDialog: false,
    };
  },
});
