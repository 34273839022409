
import { Household } from '@/models/survey';
import { FirebaseRepository } from '@/repositories/firebase';
import { DataService } from '@/services/pure/data';
import { SurveyService } from '@/services/pure/surveys';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Vue, {PropType} from 'vue';
import { Feature } from '@/models/all';
import Boundaries from '@/components/Maps/Components/Boundaries.vue';
export default Vue.extend({
  props: {
    center: {
      type: Array as PropType<number[]>,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    features: {
      type: Array as PropType<Feature[]>,
      required: true
    },
    submissions: {
      type: Array as PropType<Household[]>,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Boundaries
  },
  data() {
    return {
      cocBoundaryUrl: '',
      household: this.submissions[0],
      overlay: {
        visible: true,
        location: [0, 0],
      },
    };
  },
  mounted(): void {
    this.getCoCBoundary();
  },
  computed: {
    listOfFeatures(): Feature[] {
      return this.features;
    },
    volunteerName(): string {
      return this.household.volunteerName;
    },
    volunteerPhone(): string | null {
      return DataService.formatPhone(
        this.household.volunteerPhone,
      );
    },
    submissionTime(): string {
      return DataService.formatTime(this.household.date);
    },
  },
  methods: {
    async getCoCBoundary(): Promise<void> {
      const coc = this.$store.getters.CoC;
      if (!coc) return;
      const url = await FirebaseRepository.getCoCShapefileUrl(coc);
      if (!url) return;
      this.cocBoundaryUrl = url;
    },
    openHousehold(): void {
      this.$router.push({
        path: `/survey/${(this as any).id}/household/${
          this.household.householdID
        }`,
      });
    },
    clickHandler(payload: any): void {
      // Fix lint errors.
      const that: any = this;
      that.overlay.visible = false;
      that.$refs.map.forEachFeatureAtPixel(payload.pixel, (p: any) =>
        displayPopupForFeature(this, p),
      );
    },
    genericStyle(): (feature: any) => Style[] {
      return (feature: any) => {
        const style = new Style({
          image: new Icon({
            src: '/img/blue_marker.png',
            scale: 1,
            anchor: [0.5, 1],
          }),
        });
        return [style];
      };
    },
  },
});

async function displayPopupForFeature(that: any, feature: any) {
  const householdIndex = feature.id_;
  const household = that.submissions.find(
    (s: Household) => s.householdID === householdIndex,
  );
  const featureBaseCoordinates = household.latlng;
  that.household = household;
  that.overlay = {
    location: featureBaseCoordinates,
    visible: true,
  };
}
