import {
  HousingStatus,
  PriorResidence,
} from '@/models/hmis/Individual/HMISEnrollment';
import { HeadOfHousehold, HouseholdMember } from '@/models/survey';
export function mapHousingStatus(head: HeadOfHousehold, member: HouseholdMember): HousingStatus {
  if (head['Experienced Domestic Violence'] === 'Yes' || member["Experienced Domestic Violence"] === 'Yes') return 6;

  if (
    !!head['Where They Stayed Last Night'] &&
    (head['Where They Stayed Last Night'] === 'Motel/hotel' ||
      head['Where They Stayed Last Night'] === 'House or apartment')
  )
    return 3;
  if (!!head['Where They Stayed Last Night']) return 1;
  return 99;
}
export function mapResidencePrior(head: HeadOfHousehold): PriorResidence {
  if (!head['Where They Stayed Last Night']) return 99;
  switch (head['Where They Stayed Last Night'] as string) {
    case 'Emergency shelter':
    case 'Motel/hotel paid by agency':
      return 1;
    case 'Motel/hotel':
      return 14;
    case 'Transitional housing':
      return 2;
    case 'Street or sidewalk':
    case 'Vehicle':
    case 'Park':
    case 'Abandoned building':
    case 'Bus, train station, airport':
    case 'Under bridge/overpass':
    case 'Woods or outdoor encampment':
      return 16;
    case 'Cold night shelter':
      return 18;

    default:
      return 17;
  }
}
