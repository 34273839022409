import _ from 'lodash';
import { ShelterInformation } from './../../../models/all';
import { RegionMap } from './../../../models/Organization';
type ValidResult = {
  result: true;
};
type InvalidResult = {
  result: false;
  message: string;
};
type Result = ValidResult | InvalidResult;
const RegionVerificationService = {
  allRegionsAreValid(
    regionMap: RegionMap,
    shelters: ShelterInformation[],
  ): Result {
    const names = regionMap.regions.features.map(r => r.properties!.name);
    for (const shelter of shelters) {
      if (!shelter.region) {
        return { result: false, message: 'Some regions missing' };
      }
      const found = names.find(n => n === shelter.region);
      if (!found)
        return {
          result: false,
          message: `Invalid region name: ${shelter.region}`,
        };
    }
    return { result: true };
  },
};

export default RegionVerificationService;
