import { RegionMap } from './../models/Organization';
import { API } from '../repositories/api';
import { FirebaseRepository } from '../repositories/firebase';
import Store from '@/store';
import {
  AdminAdjustment,
  Conclusion,
  Duplicate,
} from '@/models/adminadjustment';
import { Notification } from '@/services/misc/notification';
import { SurveyWithCode, Survey, SurveyType, SurveyMode } from '@/models/count';
import { ConclusionService } from './pure/conclusion';
import { Household } from '@/models/survey';
import { EntryService } from './entry';
import { ShelterSetWithId } from '@/models/all';

const CountFactory = {
  create(
    name: string,
    observationalSurvey: string | null,
    interviewSurvey: string,
    volunteerSignupSurvey: string | null,
    organizationName: string,
    type: SurveyType,
    regionMap: RegionMap | null,
    shelters: ShelterSetWithId | null,
  ): Survey {
    const survey: Survey = {
      name,
      created: Date.now(),
      mode: !volunteerSignupSurvey ? 'Training' : 'Volunteer Registration',
      interviewSurvey,
      organizationName,
      locationLookup: {},
      coc: Store.getters.CoC,
      type,
    };
    if (observationalSurvey) survey.observationalSurvey = observationalSurvey;
    if (volunteerSignupSurvey)
      survey.volunteerSignupSurvey = volunteerSignupSurvey;
    if (regionMap) survey.regionMap = JSON.stringify(regionMap);
    if (shelters) survey.shelters = shelters;
    return survey;
  },
};

export const CountService = {
  async create(
    name: string,
    code: string,
    interviewSurvey: string,
    observationSurvey: string | null,
    volunteerSignupSurvey: string | null,
    type: SurveyType,
    regionMap: RegionMap | null,
    shelters: ShelterSetWithId | null,
  ): Promise<string> {
    const organizationID = await FirebaseRepository.getOrganizationID();
    const firstResult = await API.methods.createPITCountBackendMapping(
      organizationID,
      code,
    );
    if (!firstResult) return 'error';

    const organizationName = Store.getters.organizationName;
    const survey = CountFactory.create(
      name,
      observationSurvey,
      interviewSurvey,
      volunteerSignupSurvey,
      organizationName,
      type,
      regionMap,
      shelters,
    );
    await FirebaseRepository.createCount(survey, organizationID, code);
    return code;
  },
  async update(survey: SurveyWithCode) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    await FirebaseRepository.updateCount(organizationID, survey);
  },
  async delete(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    await FirebaseRepository.deleteCount(code, organizationID);
  },
  async listenToSubmissions(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToSubmissions(code, organizationID);
    } catch (e) {
      console.error('error listening to submissions', e);
    }


  },
  async listenToMyTeamSubmissions(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToMyTeamSubmissions(
        code,
        organizationID,
      );
    } catch (e) {
      console.error('error listening to my team\'s submissions', e);
    }

   
  },
  async listenToDuplicates(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToDuplicates(code, organizationID);
    } catch (e) {
      console.error('error listening to duplicates', e);
    }

   
  },
  async listenToEvents(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToEvents(code, organizationID);
    } catch (e) {
      console.error('error listening to events', e);
    }
  },
  async listenToVolunteerRegistrations(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToVolunteerRegistrations(code, organizationID);
    } catch (e) {
      console.error('error listening to volunteer registrations', e);
    }
  },
  async listenToDashboard(code: string) {
    return await FirebaseRepository.listenToDashboard(code);
  },
  async setAdminAdjustments(code: string, adminAdjustments: AdminAdjustment[]) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    await FirebaseRepository.setAdminAdjustments(
      code,
      organizationID,
      adminAdjustments,
    );
    Notification.methods.send('success', 'Saved.');
  },
  async setDuplicates(code: string, duplicates: Duplicate[]) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    await FirebaseRepository.setDuplicates(code, organizationID, duplicates);
    Notification.methods.send('success', 'Saved.');
  },
  async updateAdminAdjustment(code: string, newAdjustment: AdminAdjustment) {
    await this.updateAdminAdjustments(code, [newAdjustment as Conclusion]);
  },
  async updateDuplicate(code: string, newDuplicate: Duplicate) {
    if (newDuplicate.isDuplicate) {
      this.updateAdminAdjustment(code, {
        surveyStatus: 'Duplicate',
        householdID: newDuplicate.secondaryHouseholdID,
      } as AdminAdjustment);
    } else {
      await this.updateDuplicates(code, [newDuplicate]);
    }
  },
  async updateDuplicates(code: string, newDuplicates: Duplicate[]) {
    const existing = Store.getters.duplicates(code);
    const notSameIDs = existing.filter((e: Duplicate) => {
      for (const newDuplicate of newDuplicates) {
        if (
          (newDuplicate.primaryHouseholdID === e.primaryHouseholdID &&
            newDuplicate.secondaryHouseholdID === e.secondaryHouseholdID) ||
          (newDuplicate.secondaryHouseholdID === e.primaryHouseholdID &&
            newDuplicate.primaryHouseholdID === e.secondaryHouseholdID)
        )
          return false;
      }
      return true;
    });
    const duplicates = notSameIDs.concat(newDuplicates);
    await this.setDuplicates(code, duplicates);
  },
  async updateAdminAdjustments(code: string, newAdjustments: Conclusion[]) {
    const conclusions: Conclusion[] = EntryService.adminAdjustments(code);
    const households = EntryService.both(code).map(b => b.household);
    const confirmed = conclusions
      .filter(c => hasInfo(c, households, code))
      .map(c => stripGuessPieces(c));
    const withoutWhatWeAreAboutToAdd = confirmed.filter(
      c =>
        newAdjustments.filter(n => n.householdID === c.householdID).length ===
        0,
    );
    const withNew = withoutWhatWeAreAboutToAdd.concat(newAdjustments);
    const withoutIsGuess = withNew.map(wn =>
      ConclusionService.AdminAdjustmentFromConclusion(wn),
    );
    await this.setAdminAdjustments(code, withoutIsGuess);
  },
  async listenToAdminAdjustments(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToAdminAdjustments(
        code,
        organizationID,
      );
    } catch (e) {
      console.error('error listening to admin adjustments', e);
    }
  },
  async listenToHotspots(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToHotspots(
        code,
        organizationID,
      );
    } catch (e) {
      console.error('error listening to hotspots', e);
    }
  },
  async listenToLocationLookup(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();

    try {
      return await FirebaseRepository.listenToLocationLookup(
        code,
        organizationID,
      );
    } catch (e) {
      console.error('error listening to location lookup', e);
    }
  },
  async listenToLiveSurveys(code: string) {
    const organizationID = await FirebaseRepository.getOrganizationID();
    try {
      return await FirebaseRepository.listenToLiveSurveys(code, organizationID);
    } catch (e) {
      console.error('error listening to live surveys', e);
    }
  },
};

const stripGuessPieces = (c: Conclusion): Conclusion => {
  if (!c.isGuess) return c;
  return {
    householdID: c.householdID,
    surveyStatus: c.surveyStatus,
    isGuess: c.isGuess,
  } as Conclusion;
};
const changedStatus = (
  c: Conclusion,
  households: Household[],
  surveyID: string,
) => {
  const household = households.filter(h => h.householdID === c.householdID)[0];
  const liveSurveys = Store.getters.liveSurveys(surveyID);
  const changedSurveyStatus =
    c.surveyStatus !==
    ConclusionService.GetSurveyStatus(household, liveSurveys);
  return changedSurveyStatus;
};

const hasInfo = (c: Conclusion, households: Household[], surveyID: string) => {
  if (!c.isGuess) return true;
  if (changedStatus(c, households, surveyID)) return true;
  return false;
};