import { render, staticRenderFns } from "./GeneralSettings.vue?vue&type=template&id=642807cb&scoped=true&"
import script from "./GeneralSettings.vue?vue&type=script&lang=ts&"
export * from "./GeneralSettings.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralSettings.vue?vue&type=style&index=0&id=642807cb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642807cb",
  null
  
)

export default component.exports