
import Vue, { PropType } from 'vue';
import { answer, StepInfo } from './CreateSurveyDialog.vue';
import TypeBody from './Bodies/TypeBody.vue';
import NameBody from './Bodies/NameBody.vue';
import CodeBody from './Bodies/CodeBody.vue';
import ZoneBody from './Bodies/ZoneBody.vue';
import SignUpBody from './Bodies/SignUpBody.vue';
import ObservationBody from './Bodies/ObservationBody.vue';
import FormBody from './Bodies/FormBody.vue';
export default Vue.extend({
    components: {
        TypeBody,
        NameBody,
        CodeBody,
        ZoneBody,
        ObservationBody,
        FormBody,
        SignUpBody,
    },
  props: {
    step: {
      type: Object as PropType<StepInfo>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
    advance: {
      type: Function as PropType<(step: string) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    }
  },
});
