import { render, staticRenderFns } from "./CodeBody.vue?vue&type=template&id=29af21b2&"
import script from "./CodeBody.vue?vue&type=script&lang=ts&"
export * from "./CodeBody.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports