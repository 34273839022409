import { Survey } from '../../models/count';
import { Household } from '../../models/survey';

export const SubmissionsToKML = (
  survey: Survey,
  households: Household[],
): string => {
  const xmlStart = `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2">
      <Document>
        <name>${survey.name} Hotspots</name>
        <Style id="icon-1899-0288D1-normal">
          <IconStyle>
            <scale>1</scale>
            <Icon>
              <href>images/icon-2.png</href>
            </Icon>
          </IconStyle>
          <LabelStyle>
            <scale>0</scale>
          </LabelStyle>
        </Style>
        <Style id="icon-1899-0288D1-highlight">
          <IconStyle>
            <scale>1</scale>
            <Icon>
              <href>images/icon-2.png</href>
            </Icon>
          </IconStyle>
          <LabelStyle>
            <scale>1</scale>
          </LabelStyle>
        </Style>
        <StyleMap id="icon-1899-0288D1">
          <Pair>
            <key>normal</key>
            <styleUrl>#icon-1899-0288D1-normal</styleUrl>
          </Pair>
          <Pair>
            <key>highlight</key>
            <styleUrl>#icon-1899-0288D1-highlight</styleUrl>
          </Pair>
        </StyleMap>
        `;

  // e.g.:
  //   <Placemark>
  //     <name>Encampment</name>
  //     <description>Details about the encampment</description>
  //     <styleUrl>#icon-1899-0288D1</styleUrl>
  //     <Point>
  //       <coordinates>
  //         -78.7850192,35.8286696,0
  //       </coordinates>
  //     </Point>
  //   </Placemark>

  let xmlHotspots = ``;
  for (const household of households) {
    if (!Array.isArray(household.latlng) || household.latlng.length < 2)
      continue;
    const latLngStr = `${household.latlng[0]},${household.latlng[1]},0`;
    const piece = `<Placemark>
    ${household.customLocation ? `<description>${household.customLocation}</description>` : ''}
    <styleUrl>#icon-1899-0288D1</styleUrl>
    <Point>
      <coordinates>
       ${latLngStr}
      </coordinates>
    </Point>
  </Placemark>
`;
    xmlHotspots += piece;
  }

  const xmlEnd = `
      </Document>
    </kml>
    `;
  return xmlStart + xmlHotspots + xmlEnd;
};
