import Vue from 'vue';
import Router from 'vue-router';
import Store from './store';
import { RouterService } from '@/services/misc/router';
Vue.use(Router);

function guard(to: any, from: any, next: any) {
  try {
    const isGoingToLoggedOutRoute = RouterService.isLoggedOutRoute(to);
    const isLoggedIn = Store.getters.loggedIn;
    if (isGoingToLoggedOutRoute && isLoggedIn) {
      next('/home');
      return;
    }

    if (!isGoingToLoggedOutRoute && !isLoggedIn) {
      next('/login');
      return;
    }
    next();
  } catch (error) {}
}

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    beforeEnter: guard,
    component: () => import('./pages/Surveys.vue'),
  },
  {
    path: '/account',
    name: 'account',
    beforeEnter: guard,
    component: () => import('./pages/Account.vue'),
  },
  {
    path: '/organization',
    name: 'organization',
    beforeEnter: guard,
    component: () => import('./pages/Organization.vue'),
  },
  {
    path: '/shelters',
    name: 'shelters',
    beforeEnter: guard,
    component: () => import('./pages/Shelters.vue'),
  },
  {
    path: '/survey/:id',
    name: 'survey',
    beforeEnter: guard,
    component: () => import('./pages/Survey.vue'),
    props: true,
  },
  {
    path: '/survey/:surveyID/duplicates',
    name: 'surveyduplicates',
    beforeEnter: guard,
    component: () => import('./components/PITCount/Tools/Duplicates.vue'),
    props: true,
  },
  {
    path: '/survey/:surveyID/compare/:householdID1/:householdID2',
    name: 'surveycompare',
    beforeEnter: guard,
    component: () => import('./pages/Compare.vue'),
    props: true,
  },
  {
    path: '/survey/:surveyID/data/unconfirmed',
    name: 'surveyunconfirmed',
    beforeEnter: guard,
    component: () => import('./pages/PITCountUnconfirmed.vue'),
    props: true,
  },
  {
    path: '/survey/:surveyID/data/needattention',
    name: 'surveyneedattention',
    beforeEnter: guard,
    component: () => import('./pages/PITCountNeedAttention.vue'),
    props: true,
  },
  {
    path: '/survey/:surveyID/household/:householdID',
    name: 'household',
    beforeEnter: guard,
    component: () => import('./pages/Household.vue'),
    props: true,
  },
  {
    path: '/hmis-field-mappings/:surveyID',
    name: 'hmis-field-mappings',
    beforeEnter: guard,
    component: () => import('./pages/HMISFieldMappings.vue'),
    props: true,
  },
  {
    path: '/questions',
    name: 'questions',
    beforeEnter: guard,
    component: () => import('./pages/Forms.vue'),
  },
  {
    path: '/edit-form/:id',
    name: 'editform',
    beforeEnter: guard,
    component: () => import('./pages/FormEditor.vue'),
    props: true,
  },
  {
    path: '/view-form/:id/:type',
    name: 'viewform',
    beforeEnter: guard,
    component: () => import('./pages/FormViewer.vue'),
    props: true,
  },
  {
    path: '/organization/regions/:id',
    name: 'regions',
    beforeEnter: guard,
    component: () => import('./pages/Regions.vue'),
    props: true,
  },
  {
    path: '/organization/shelters/:id',
    name: 'shelter',
    beforeEnter: guard,
    component: () => import('./pages/Shelter.vue'),
    props: true,
  },
  {
    path: '/register',
    name: 'register',
    beforeEnter: guard,
    component: () => import('./pages/Register.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    beforeEnter: guard,
    component: () => import('./pages/ForgotPassword.vue'),
  },
  {
    path: '/forgotpasswordhandler',
    name: 'forgotpasswordhandler',
    beforeEnter: guard,
    component: () => import('./pages/ForgotPasswordHandler.vue'),
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: guard,
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/*',
    name: '404',
    component: () => import('./pages/FourOhFour.vue'),
  },
];
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
