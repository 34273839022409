export function getDateString(date: Date) {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    .toISOString()
    .split("T")[0];
}

export function getTimeString(date: Date){
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
  .toISOString()
  .split('.')[0]
  .replace('T', ' ');
}

export function mapYesNo(answer: string) {
  if (!answer) return 99;
  switch (answer) {
    case 'No':
      return 0;
    case 'Yes':
      return 1;
    case 'Not Sure':
      return 8;
    case 'Refused to Answer':
      return 9;
    default:
      return 99;
  }
}

export function parseYear(year?: string): number | null {
  if (!year) return null;
  if (year.length !== 4) return null;
  const regex = /^[0-9]{4}$/;
  if (!regex.test(year)) return null;
  return parseInt(year, 10);
}

// parseInt replacing NaN with null
export function safeParseInt(str: string): number | null {
  const val = parseInt(str);
  if ((!val || val === NaN) && val !== 0)
    return null;
    
  return val;
}