
import Vue, { PropType } from 'vue';
import { API } from '../../../../repositories/api';
import { answer } from '../CreateSurveyDialog.vue';
export default Vue.extend({
  data() {
    return {
      code: '',
      checkingCustomCode: false,
      codeValid: true,
    };
  },
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    },
  },
  computed: {
    codeLoading(): boolean {
      return this.code === '' || this.checkingCustomCode;
    },
  },
  async mounted() {
    this.code = await this.getValidCode();
  },
  methods: {
    selectAnswer() {
      this.advance({ stepID: 'code', answer: this.code });
    },
    async getValidCode() {
      const code = await API.methods.getCode();
      this.codeValid = true;
      return code;
    },
    async codeChanged(newValue: string) {
      this.checkingCustomCode = true;
      const result = await API.methods.checkCode(newValue);
      this.codeValid = result;
      this.checkingCustomCode = false;
    },
  },
});
