
import { Survey } from '@/models/count';
import { RegionMap } from '@/models/Organization';
import { asArray, asString } from 'ol/color';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Vue from 'vue';
export default Vue.extend({
  computed: {
      features() {
          const survey: Survey = this.$store.get('survey/survey');
          if (!survey.regionMap) return [];
          const regionMap: RegionMap = JSON.parse(survey.regionMap);
          const all = regionMap.regions.features;
          const selectedRegionNames = this.$route.query.regions;
          if (!selectedRegionNames || selectedRegionNames.length === 0) return [];
          const filtered = all.filter(a => selectedRegionNames.includes(a.properties?.name));
          return filtered;
      }
  },
  methods: {
    style() {
      return (feature: any) => {
        const [r, g, b] = Array.from(asArray(feature.values_.fill));
        const withAlpha = asString([r, g, b, 0.5]);
        // Use default stroke style and no fill style.
        return [
          new Style({
            stroke: new Stroke({
              color: feature.values_.stroke,
              width: 1.25,
            }),
            fill: new Fill({
              color: withAlpha,
            }),
          }),
        ];
      };
    },
  },
});
