
import Settings from '@/components/Utility/Settings.vue';
import Vue from 'vue';
import { ages } from '../../../models/all';
export default Vue.extend({
  components: { Settings },
  props: {
    value: {},
  },
  computed: {
    ages() {
      return ages('2023');
    },
  },
});
