
import Vue from 'vue';
import { Survey, SurveyWithCode, HMISExportFieldMapping } from '@/models/count';
import { SurveyWrapperEtc, Household, HeadOfHousehold } from '../models/survey';
import { FirebaseRepository } from '../repositories/firebase';
import { CountService } from '@/services/count';
import { forEach } from 'lodash';
import _ from 'lodash';
import { AnalyticsService } from '@/services/pure/analytics';
import { DataService } from '@/services/pure/data';
export default Vue.extend({
  props: ['surveyID'],
  data() {
    return {
      dialog: false,
      editedItem: {} as HMISExportFieldMapping,
      editedItemIndex: -1,
      headers: [
        { text: 'HMIS CSV Field', value: 'exportFieldName', width: '20%' },
        { text: 'Example Value', value: 'exampleValue', width: '25%' },
        {
          text: 'Head of Household Survey',
          value: 'headOfHouseholdField',
          width: '25%',
        },
        {
          text: 'Household Member Survey',
          value: 'householdMemberField',
          width: '25%',
        },
        {
          text: 'Select Fields',
          value: 'actions',
          sortable: false,
          width: '5%',
        },
      ],
      defaultFieldInformation: [
        {
          exportFieldName: 'SSN',
          exampleValue: '123-45-5678',
          headOfHouseholdField: '',
          householdMemberField: '',
        },
        {
          exportFieldName: 'DOB',
          exampleValue: '01/12/1953',
          headOfHouseholdField: '',
          householdMemberField: '',
        },
      ] as HMISExportFieldMapping[],
      ssnSearchStrings: ['ssn', 'social', 'security'],
      dobSearchStrings: ['dob', 'birth', 'born'],
      fieldMappings: [] as HMISExportFieldMapping[],
    };
  },
  computed: {
    survey(): SurveyWithCode {
      return this.$store.getters.Survey(this.surveyID);
    },
    headOfHouseholdFields(): string[] {
      if (!this.households) return [];

      const fields = DataService.getHeadOfHouseholdQuestions(this.households);
      const fieldsWithRecommendations = this.getFieldsWithRecommendations(fields);
      fieldsWithRecommendations.unshift('');

      return fieldsWithRecommendations;
    },
    householdMemberFields(): string[] {
      if (!this.households) return [];

      const fields = DataService.getHouseholdMemberQuestions(this.households);
      const fieldsWithRecommendations = this.getFieldsWithRecommendations(fields);
      fieldsWithRecommendations.unshift('');

      return fieldsWithRecommendations;
    },
    households(): Household[] | null {
      return AnalyticsService.getHouseholds(this.survey.code);
    }
  },
  mounted() {
    this.fieldMappings = this.survey.hmisMappings;

    if (!this.fieldMappings || this.fieldMappings.length === 0) {
      this.fieldMappings = this.defaultFieldInformation;
    } else {
      this.defaultFieldInformation.forEach(defaultMapping => {
        if (
          !this.fieldMappings.some(
            mapping =>
              mapping.exportFieldName == defaultMapping.exportFieldName,
          )
        ) {
          this.fieldMappings.push(defaultMapping);
        }
      });
    }
  },
  methods: {
    async saveAndClose() {
      const survey = { ...this.survey };
      survey.hmisMappings = this.fieldMappings;
      await CountService.update(survey);
      // Goes to previous page in router history, done to preserve filters on data page.
      this.$router.go(-1);
    },
    editItem(item: HMISExportFieldMapping) {
      this.editedItem = { ...item };
      this.editedItemIndex = this.fieldMappings.indexOf(item);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedItem = {} as HMISExportFieldMapping;
      this.editedItemIndex = -1;
    },
    saveDialog() {
      if (this.editedItemIndex > -1) {
        this.editedItem.exampleValue = this.getExampleValues(this.editedItem);
        Object.assign(
          this.fieldMappings[this.editedItemIndex],
          this.editedItem,
        );
      }
      this.closeDialog();
    },
    getFieldsWithRecommendations(fields: string[]) {
      let fieldsWithRecommendations = [...fields];
      if (this.editedItem.exportFieldName === 'SSN') {
        fieldsWithRecommendations = getRecommendedFields(
          fields,
          this.ssnSearchStrings,
        );
      } else if (this.editedItem.exportFieldName === 'DOB') {
        fieldsWithRecommendations = getRecommendedFields(
          fields,
          this.dobSearchStrings,
        );
      }
      return fieldsWithRecommendations;
    },
    getExampleValues(exportMapping: HMISExportFieldMapping): string {
      let headExampleVal = '';

      const headFieldName = exportMapping.headOfHouseholdField;
      if (headFieldName && this.households){
        const headOfHouseholdMembers = this.households.map(s => {
          return s.HouseholdMembers[0]
        });
        headExampleVal = getExampleFieldValue(headOfHouseholdMembers, headFieldName);  
      }

      return headExampleVal;
    }
  },
});

function getRecommendedFields(
  fields: string[],
  searchValues: string[],
): string[] {
  const newFields = [...fields];
  const recommendedFields = fields.filter(field => {
    for (let i = 0; i < searchValues.length; i++) {
      if (field.toLowerCase().indexOf(searchValues[i]) >= 0) {
        return true;
      }
    }
  });
  recommendedFields.forEach(f => {
    newFields.splice(fields.indexOf(f), 1);
    newFields.unshift(f);
  });
  return newFields;
}

function getExampleFieldValue(members: HeadOfHousehold[], fieldName: string) {
  return members.map((m: any) => m[fieldName])
                .find(m => m ?? false)
                ?? '';
}
