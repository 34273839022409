import { ShelterSetWithId } from '../models/all';
import { Lead, RegionMap } from './Organization';

export type SurveyMode = 'Training' | 'Live' | 'Finished' | 'Volunteer Registration';

export type LocationRecord = {
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  country?: string;
  formatted_address?: string;
  locality?: string;
  postal_code?: string;
  route?: string;
};
export interface Survey {
  name: string;
  created: number;
  mode: SurveyMode;
  observationalSurvey?: string;
  volunteerSignupSurvey?: string;
  interviewSurvey: string;
  organizationName: string;
  type: SurveyType;
  coc?: string;
  locationLookup: Record<string, LocationRecord>;
  // Stringified
  regionMap?: string;
  leads?: Lead[];
  // KMZ file contents.
  hotspots?: string;
  shelters?: ShelterSetWithId;
}

export interface TeamAssignment {
  Team: string;
  ID: string;
}

export type SurveyType = 'PIT Count' | 'Other';

export interface WithCode {
  code: string;
}

export interface WithMappings {
  includeObservationalInExport: boolean;
  hmisMappings: HMISExportFieldMapping[];
}

export type SurveyWithCode = Survey & WithCode & WithMappings;

// April 1, 2020.
export const paidThroughDefault = 1585778005094;

export interface HMISExportFieldMapping {
  exportFieldName: string;
  exampleValue: string;
  headOfHouseholdField: string;
  householdMemberField: string;
}
