
import Vue from 'vue';
import FormEditor from '@/components/Forms/FormEditor.vue';
import { Form, FormWrapper } from '@/models/formstructure';
import { FirebaseRepository } from '@/repositories/firebase';
import { Notification } from '@/services/misc/notification';
export default Vue.extend({
  props: ['id'],
  components: {
    FormEditor
  },
  // Pass in the actual structure
  // Control where it's saved to and how saving works (automatically vs not)
  computed: {
    surveyStructure(): FormWrapper {
      const formStructures: FormWrapper[] = this.$store.getters.formStructures;
      const formStructure = formStructures.find(
        ss => ss.ID === this.id,
      ) as FormWrapper;
      return formStructure;
    },
    saveMethod() {
      return (text: string, previous: string) => {
        const formStructures: FormWrapper[] = this.$store.getters.formStructures;
        const editing = formStructures.find(
        ss => ss.ID === this.id,
      ) as FormWrapper;
      if (text === previous) return;
      editing.Structure = (text as any) as Form;
      editing.Edited = Date.now();
      this.$store.commit('updateFormStructures', formStructures);
      FirebaseRepository.saveFormStructures(formStructures, []).then((res: boolean) => {
        if (res) {
        }
      });
      }
  }
  }
});
