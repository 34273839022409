import Store from '@/store';
import { DeDuplicationCluster } from '../../models/DeDuplicationResult';
import {
  Household,
  HeadOfHousehold,
} from '@/models/survey';
import _ from 'lodash';
import { Both } from "../../Both";
import { Duplicate, SurveyStatus } from '@/models/adminadjustment';

export const DeduplicatonService = {
  methods: {
    findDuplicates(all: Both[], surveyID: string): DeDuplicationCluster[] {
      const both = all.filter(b => b.conclusion.surveyStatus !== 'Duplicate');
      const existingDuplicates: Duplicate[] = Store.getters.duplicates(surveyID);
      const surveys = both.map(b => b.household);
      const duplicateBases = [];

      const clusters = [] as DeDuplicationCluster[];
      for (let i = 0; i < surveys.length; i++) {
        const possibleCluster: DeDuplicationCluster = {
          originalID: surveys[i].householdID,
          duplicateIDs: [],
        };
        for (let j = i + 1; j < surveys.length; j++) {
          if (duplicateBases.indexOf(surveys[j].householdID) !== -1) {
            continue;
          }
          if (
            !alreadyFlagged(surveys[i], surveys[j], existingDuplicates) &&
            this.isDuplicate(surveys[i], surveys[j])
          ) {
            possibleCluster.duplicateIDs.push(surveys[j].householdID);
          }
        }
        if (possibleCluster.duplicateIDs.length > 0) {
          duplicateBases.push(possibleCluster.originalID);
          for (const duplicateIndex of possibleCluster.duplicateIDs) {
            duplicateBases.push(duplicateIndex);
          }
          clusters.push(possibleCluster);
        }
      }
      return clusters;
    },
    isDuplicate(oneWrapper: Household, twoWrapper: Household): boolean {
      try {
        if (oneWrapper.HouseholdMembers.length !== twoWrapper.HouseholdMembers.length) return false;
        const one = oneWrapper.HouseholdMembers[0] as HeadOfHousehold;
        const two = twoWrapper.HouseholdMembers[0] as HeadOfHousehold;

        let similar = 0;
        // For Austin
        const oneAny = one as any;
        const twoAny = two as any;
        if (
          !!oneAny.First_Name &&
          !!twoAny.First_Name &&
          !!oneAny.Last_name &&
          !!twoAny.Last_name
        ) {
          const oaf = oneAny.First_Name.trim().toUpperCase();
          const oal = oneAny.Last_name.trim().toUpperCase();
          const taf = twoAny.First_Name.trim().toUpperCase();
          const tal = twoAny.Last_name.trim().toUpperCase();
          if (oaf === taf && oal === tal) return true;
          if (oaf === taf || oal === tal) similar++;
        }

        if (
          !!one['Initials Or Name'] &&
          !!two['Initials Or Name'] &&
          one['Initials Or Name'].trim().toUpperCase() ===
            two['Initials Or Name'].trim().toUpperCase()
        ) {
          return true;
        }

        if (one.Age !== two.Age) {
          return false;
        }
        if (
          one['Where They Stayed Last Night'] !==
          two['Where They Stayed Last Night']
        ) {
          return false;
        }
        if (
          oneWrapper.HouseholdMembers.length !==
          twoWrapper.HouseholdMembers.length
        ) {
          return false;
        }
        if (!_.isEqual(one.Race, two.Race) || !one.Race) {
          return false;
        }
        if (one.Gender !== two.Gender || !one.Gender) {
          return false;
        }

        if (!!oneAny.DOB && !!twoAny.DOB && oneAny.DOB === twoAny.DOB) {
          similar++;
        }

        if (!!oneAny.SSN && !!twoAny.SSN && oneAny.SSN === twoAny.SSN) {
          similar++;
        }

        if (
          !!Array.isArray(oneWrapper.latlng) &&
          !!Array.isArray(twoWrapper.latlng)
        ) {
          const lat = Math.pow(
            oneWrapper.latlng[0] - twoWrapper.latlng[0],
            2,
          );
          const long = Math.pow(
            oneWrapper.latlng[1] - twoWrapper.latlng[1],
            2,
          );
          const distance = Math.sqrt(lat + long);
          if (distance < 0.0001) {
            similar++;
          }
        }

        return similar > 1;
      } catch (err) {
        return false;
      }
    },
  },
};

function alreadyFlagged(
  one: Household,
  two: Household,
  existingDuplicates: Duplicate[],
) {
  return (
    existingDuplicates.filter(
      d =>
        (d.primaryHouseholdID === one.householdID &&
          d.secondaryHouseholdID === two.householdID) ||
        (d.secondaryHouseholdID === one.householdID &&
          d.primaryHouseholdID === two.householdID),
    ).length > 0
  );
}
