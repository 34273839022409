
import Vue from 'vue';
import { StringService } from '../services/misc/string';
import { CountService } from '@/services/count';

export default Vue.extend({
  props: ['survey', 'elevation', 'classes'],
  data() {
    return {
      deleteDialog: false,
    };
  },
  computed: {
    hasHotspots() {
      return this.$store.getters.hotspots(this.survey.code).length > 0;
    },
  },
  methods: {
    prettyDate() {
      return StringService.getPrettyDate(this.survey.created);
    },
    getActive() {
      return this.survey.mode === 'Training'
        ? 'Training Mode'
        : this.survey.mode;
    },
    getName() {
      return this.survey.name;
    },
    navigateToPITCount() {
      if (this.deleteDialog) {
        return;
      }
      this.$router.push({ path: `/survey/${this.survey.code}` });
    },
    startDelete() {
      (this as any).deleteDialog = true;
    },
    finishDelete() {
      CountService.delete(this.survey.code);
    },
  },
});
