
import Vue from 'vue';
import DetailBox from '@/components/Cleanup/DetailBox.vue';

export default Vue.extend({
  components: {
    DetailBox,
  },
  props: ['survey'],
  computed: {
    time(): string {
      return new Date(this.survey.date).toLocaleString();
    },
  },
});
