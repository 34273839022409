import { HeadOfHousehold, HouseholdMember, Household } from '@/models/survey';
import { DataService } from '@/services/pure/data';
import { getTimeString } from '../../hmis-utility';
import { HMISEnrollment, HMISEnrollmentBase, HMISEnrollmentHousehold, HMISEnrollmentHousing, HMISEnrollmentQuestions, HMISEnrollmentMetaData } from '@/models/hmis/Individual/HMISEnrollment';
import { mapTimesHomelessPastThreeYears as mapTimesHomelessPastThreeYears, mapMonthsHomelessPastThreeYears } from '../field/times-homeless';
import { mapHousingStatus, mapResidencePrior } from '../field/housing';
import { mapDisablingCondition } from '../field/disabling';
import { mapActiveMilitaryParent, mapRelation } from '../field/relation';

export const HMISEnrollmentService = {
  getEnrollmentRecord(member: (HouseholdMember | HeadOfHousehold), head: HeadOfHousehold, householdID: string,
    personalID: string, projectID: string, userID: string, exportID: string): HMISEnrollment {
    return {
      ...this.getEnrollmentBase(member),
      ...this.getEnrollmentHousehold(member, householdID),
      ...this.getEnrollmentHousing(member, head),
      ...this.getEnrollmentQuestions(member, head),
      ...this.getEnrollmentMetaData(member["Personal ID"] ?? personalID, projectID, userID, exportID)
    }
  },
  getEnrollmentBase(member: (HouseholdMember | HeadOfHousehold)): HMISEnrollmentBase {
    return {
      DateOfBCPStatus: null,
      FYSBYouth: null,
      ReasonNoServices: null,
      SexualOrientation: null,
      FormerWardChildWelfare: null,
      ChildWelfareYears: null,
      ChildWelfareMonths: null,
      FormerWardJuvenileJustice: null,
      JuvenileJusticeYears: null,
      JuvenileJusticeMonths: null,
      HouseholdDynamics: null,
    }
  },
  getEnrollmentHousehold(member: (HouseholdMember | HeadOfHousehold), householdID: string): HMISEnrollmentHousehold {
    return {
      HouseHoldID: householdID,
      RelationshipToHoH: mapRelation(member as HouseholdMember),
    }
  },
  getEnrollmentHousing(member: (HouseholdMember | HeadOfHousehold), headOfHousehold: HeadOfHousehold): HMISEnrollmentHousing {
    return {
      ResidencePrior: mapResidencePrior(headOfHousehold),
      OtherResidencePrior: null,
      ResidencePriorLengthOfStay: null,
      DisablingCondition: mapDisablingCondition(member),
      TimesHomelessPastThreeYears: mapTimesHomelessPastThreeYears(headOfHousehold["Times Homeless In Last Three Years"]),
      MonthsHomelessPastThreeYears: mapMonthsHomelessPastThreeYears(headOfHousehold["Months Homeless In Last Three Years"]),
      HousingStatus: mapHousingStatus(headOfHousehold, member),
      DateOfEngagement: null,
      InPermanentHousing: null,
      ResidentialMoveInDate: null,
      DateOfPATHStatus: null,
      ClientEnrolledInPATH: null,
      ReasonNotEnrolled: null,
      WorstHousingSituation: null,
      PercentAMI: null,
      LastPermanentStreet: null,
      LastPermanentCity: null,
      LastPermanentState: null,
      LastPermanentZIP: null,
      AddressDataQuality: null,
    }
  },
  getEnrollmentQuestions(member: (HouseholdMember | HeadOfHousehold), head: HeadOfHousehold): HMISEnrollmentQuestions {
    return {
      SexualOrientationGenderIDYouth: null,
      SexualOrientationGenderIDFam: null,
      HousingIssuesYouth: null,
      HousingIssuesFam: null,
      SchoolEducationalIssuesYouth: null,
      SchoolEducationalIssuesFam: null,
      UnemploymentYouth: null,
      UnemploymentFam: null,
      MentalHealthIssuesYouth: null,
      MentalHealthIssuesFam: null,
      HealthIssuesYouth: null,
      HealthIssuesFam: null,
      PhysicalDisabilityYouth: null,
      PhysicalDisabilityFam: null,
      MentalDisabilityYouth: null,
      MentalDisabilityFam: null,
      AbuseAndNeglectYouth: null,
      AbuseAndNeglectFam: null,
      AlcoholDrugAbuseYouth: null,
      AlcoholDrugAbuseFam: null,
      InsufficientIncome: null,
      ActiveMilitaryParent: mapActiveMilitaryParent(member, head),
      IncarceratedParent: null,
      IncarceratedParentStatus: null,
      ReferralSource: null,
      CountOutreachReferralApproaches: null,
      ExchangeForSexPastThreeMonths: null,
      CountOfExchangeForSex: null,
      AskedOrForcedToExchangeForSex: null,
    }
  },
  getEnrollmentMetaData(personalID: string, projectID: string, userID: string, exportID: string): HMISEnrollmentMetaData {
    const projectEntryID = DataService.generateRandomId();
    const time = getTimeString(new Date());

    return {
      ProjectEntryID: projectEntryID,
      PersonalID: personalID,
      ProjectID: projectID,
      EntryDate: time,
      DateCreated: time,
      DateUpdated: time,
      UserID: userID,
      DateDeleted: null,
      ExportID: exportID,
    }
  },
};
