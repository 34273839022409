
import Vue from 'vue';
import { API, Result } from '../repositories/api';
import { Notification } from '../services/misc/notification';
import { FirebaseRepository } from '../repositories/firebase';
import firebase from 'firebase/app';
export default Vue.extend({
  name: 'register',
  data() {
    return {
      email: '',
      password: '',
      repeatPassword: '',
      organizationName: '',
      accepted: false,
      loading: false,
    };
  },
  methods: {
    async register(event: any) {
      event.preventDefault();
      if (!this.checkInputsAreValid()) {
        return;
      }
      this.loading = true;
      const result = await API.methods.registerAdmin(
        firebase.auth(),
        this.email,
        this.password,
        this.organizationName,
      );
      if (result.result === Result.AccountExists) {
        Notification.methods.send(
          'error',
          'An account with that email already exists.',
        );
        this.loading = false;
        return;
      }
      if (result.result === Result.InternalServerError) {
        Notification.methods.send(
          'error',
          'Something went wrong. Please try again.',
        );
        this.loading = false;
        return;
      }
      const user = firebase.auth().currentUser;
      await FirebaseRepository.sendVerificationEmail(user);
      this.loading = false;
    },
    checkInputsAreValid(): boolean {
      if (this.email === '') {
        Notification.methods.send('error', 'Please enter your email.');
        return false;
      }
      if (this.organizationName === '') {
        Notification.methods.send(
          'error',
          "Please enter your organization's name.",
        );
        return false;
      }
      if (this.password === '') {
        Notification.methods.send('error', 'Please enter your password.');
        return false;
      }

      if (this.repeatPassword === '') {
        Notification.methods.send('error', 'Please repeat your password.');
        return false;
      }

      if (this.password !== this.repeatPassword) {
        Notification.methods.send('error', "Passwords don't match.");
        return false;
      }
      if (!this.accepted) {
        Notification.methods.send(
          'error',
          'You must accept the privacy policy and terms before proceeding.',
        );
        return false;
      }
      if (!emailIsValid(this.email)) {
        Notification.methods.send('false', "That email address isn't valid.");
        return false;
      }
      return true;
    },
  },
});
function emailIsValid(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}
