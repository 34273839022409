import { GenderExport } from "@/models/hmis/Individual/HMISClient";

export const HMISGender = {
  mapGender(gender?: string | string[]): GenderExport {
    switch (gender) {
      case 'Female':
        return 0;
      case 'Male':
        return 1;
        case 'TransMTF': //temp
        case 'Transgender MTF':
        return 2;
        case 'Transgender FTM':
        case 'TransFTM': //temp
        return 3;
      case 'Other':
      case 'GenderNon-Conforming': //temp
      case 'Gender Non-Conforming': //temp
      case 'Transgender':
      case 'NonConforming':
        return 4;
      case 'Not Sure':
        return 8;
      case 'Refused to Answer':
        return 9;
      default:
        return 99;
    }
  }
}