import Strings from '@/components/reporting/Strings';
import {
  Category,
  Column,
  Property,
  ReportingConfig,
  ReportLine,
  ReportSection,
} from '@/models/all';
import { AnalyticsService } from './analytics';
import {
  Breakdown,
  BreakdownWrapper,
  EndProperty,
  CountYear,
} from './breakdown';

const ReportingService = {
  YouthUnaccompanied(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: '',
      path: ['Youth', 'Unaccompanied'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.UnaccompaniedChildren(report),
          lastProperty: 'TotalNumberPeopleChild',
        },
        {
          title: Strings.UnaccompaniedYouth(report),
          lastProperty: 'TotalNumberPeopleYouth',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  YouthParenting(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: '',
      path: ['Youth', 'Parenting'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.ParentingYouthUnder18(report),
          lastProperty: 'TotalNumberPeopleParentingYouthU18',
        },
        {
          title: Strings.ChildrenWithParentsUnder18(report),
          lastProperty: 'TotalNumberPeopleChildrenParentsU18',
        },
        {
          title: Strings.ParentingYouthOlder(report),
          lastProperty: 'TotalNumberPeopleParentingYouth1824',
        },
        {
          title: Strings.ChildrenWithOlderParents(report),
          lastProperty: 'TotalNumberPeopleChildrenParents1824',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  VeteranBoth(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: report
        ? ''
        : 'Persons in Households with at least one Adult and one Child',
      path: ['Veteran', 'OneAdultAndOneChild'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.Persons(report),
          lastProperty: 'TotalNumberPeople',
        },
        {
          title: Strings.Veterans(report),
          lastProperty: 'TotalNumberVeterans',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  VeteranAdults(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: report ? '' : 'Persons in Households without Children',
      path: ['Veteran', 'OnlyAdults'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.Persons(report),
          lastProperty: 'TotalNumberPeople',
        },
        {
          title: Strings.Veterans(report),
          lastProperty: 'TotalNumberVeterans',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  AllChildren(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: report ? '' : 'Persons in Households with only Children',
      path: ['All', 'OnlyChildren'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.Persons(report),
          lastProperty: 'TotalNumberPeople',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  AllBoth(breakdown: BreakdownWrapper, report: boolean) {
    if (breakdown.year === '2021' || breakdown.year === '2022') {
      const reportingConfig: ReportingConfig = {
        title: report
          ? ''
          : 'Persons in Households with at least one Adult and one Child',
        path: ['All', 'OneAdultAndOneChild'],
        sections: [
          {
            title: Strings.Households(report),
            lastProperty: 'TotalNumberHouseholds',
          },
          {
            title: Strings.Persons(report),
            lastProperty: 'TotalNumberPeople',
          },
          {
            title: Strings.Children(report),
            lastProperty: 'TotalNumberPeopleChild',
          },
          {
            title: Strings.Youth(report),
            lastProperty: 'TotalNumberPeopleYouth',
          },
          {
            title: Strings.Adults25Plus(report),
            lastProperty: 'TotalNumberPeopleAdult',
          },
        ],
      };
      return toDisplay(breakdown, reportingConfig);
    }
    const reportingConfig: ReportingConfig = {
      title: report
        ? ''
        : 'Persons in Households with at least one Adult and one Child',
      path: ['All', 'OneAdultAndOneChild'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.Persons(report),
          lastProperty: 'TotalNumberPeople',
        },
        {
          title: Strings.Children(report),
          lastProperty: 'TotalNumberPeopleChild',
        },
        {
          title: Strings.Youth(report),
          lastProperty: 'TotalNumberPeopleYouth',
        },
        {
          title: Strings.Adults25To34(report),
          lastProperty: 'TotalNumberPeople25To34',
        },
        {
          title: Strings.Adults35To44(report),
          lastProperty: 'TotalNumberPeople35To44',
        },
        {
          title: Strings.Adults45To54(report),
          lastProperty: 'TotalNumberPeople45To54',
        },
        {
          title: Strings.Adults55To64(report),
          lastProperty: 'TotalNumberPeople55To64',
        },
        {
          title: Strings.Adults65Plus(report),
          lastProperty: 'TotalNumberPeople65Plus',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);

  },
  AllUnknown(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: report ? '' : 'Persons in Households with unknown ages',
      path: ['All', 'UnknownAge'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.Persons(report),
          lastProperty: 'TotalNumberPeople',
        },
        {
          title: Strings.UnknownAge(report),
          lastProperty: 'TotalNumberPeopleUnknownAge',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  AllAdults(breakdown: BreakdownWrapper, report: boolean) {
    if (breakdown.year === '2021' || breakdown.year === '2022') {
      const reportingConfig: ReportingConfig = {
        title: report ? '' : 'Persons in Households without Children',
        path: ['All', 'OnlyAdults'],
        sections: [
          {
            title: Strings.Households(report),
            lastProperty: 'TotalNumberHouseholds',
          },
          {
            title: Strings.Persons(report),
            lastProperty: 'TotalNumberPeople',
          },
          {
            title: Strings.Youth(report),
            lastProperty: 'TotalNumberPeopleYouth',
          },
          {
            title: Strings.Adults25Plus(report),
            lastProperty: 'TotalNumberPeopleAdult',
          },
        ],
      };
      return toDisplay(breakdown, reportingConfig);
    }

    const reportingConfig: ReportingConfig = {
      title: report ? '' : 'Persons in Households without Children',
      path: ['All', 'OnlyAdults'],
      sections: [
        {
          title: Strings.Households(report),
          lastProperty: 'TotalNumberHouseholds',
        },
        {
          title: Strings.Persons(report),
          lastProperty: 'TotalNumberPeople',
        },
        {
          title: Strings.Youth(report),
          lastProperty: 'TotalNumberPeopleYouth',
        },
        {
          title: Strings.Adults25To34(report),
          lastProperty: 'TotalNumberPeople25To34',
        },
        {
          title: Strings.Adults35To44(report),
          lastProperty: 'TotalNumberPeople35To44',
        },
        {
          title: Strings.Adults45To54(report),
          lastProperty: 'TotalNumberPeople45To54',
        },
        {
          title: Strings.Adults55To64(report),
          lastProperty: 'TotalNumberPeople55To64',
        },
        {
          title: Strings.Adults65Plus(report),
          lastProperty: 'TotalNumberPeople65Plus',
        },
      ],
    };
    return toDisplay(breakdown, reportingConfig);
  },
  AdditionalHomelessPopulations(breakdown: BreakdownWrapper, report: boolean) {
    const reportingConfig: ReportingConfig = {
      title: report ? '' : 'Additional Homeless Populations',
      path: ['Additional'],
      sections: [
        {
          title: Strings.MentalHealth(report),
          lastProperty: 'MentalIllness',
        },
        {
          title: Strings.SubstanceAbuse(report),
          lastProperty: 'SubstanceAbuse',
        },
        {
          title: Strings.HIV(report),
          lastProperty: 'HIV',
        },
        {
          title: Strings.DV(report),
          lastProperty: 'DomesticViolence',
        },
      ],
    };
    const totals = reportingConfig.sections.map(element => {
      return getReportLine(
        breakdown,
        element.title,
        reportingConfig.path,
        element.lastProperty,
      );
    });
    return [
      { title: reportingConfig.title, elements: totals },
    ] as ReportSection[];
  },
};

const toDisplay = (
  breakdown: BreakdownWrapper,
  reportingConfig: ReportingConfig,
) => {
  const totals = reportingConfig.sections.map(element => {
    return getReportLine(
      breakdown,
      element.title,
      reportingConfig.path,
      element.lastProperty,
    );
  });
  const subAnalytics = AnalyticsService.CreateSubAnalytics(
    breakdown,
    reportingConfig.path,
  );
  return [
    { title: reportingConfig.title, elements: totals },
    ...subAnalytics,
  ] as ReportSection[];
};

const getReportLine = (
  breakdownWrapper: BreakdownWrapper,
  title: string,
  keys: string[],
  lastKey: string,
) => {
  const columns: Column[] = [
    'Total',
    'Unsheltered',
    'Emergency',
    'Transitional',
    'SafeHaven',
  ];
  const reportLine = { title } as ReportLine;
  for (const column of columns) {
    reportLine[column] = breakdownWrapper[column] as any;
    for (const key of keys) reportLine[column] = (reportLine[column] as any)[key];
    reportLine[column] = (reportLine[column] as any)[lastKey];
  }
  return reportLine;
};

export default ReportingService;
