
import Vue from 'vue';
import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
export default Vue.extend({
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        responsive: false,
        maintainAspectRatio: false,
      },
    };
  },
  mounted(): void {
    // injected from Pie
    (this as any).renderChart(this.chartData, this.options);
  },
});
