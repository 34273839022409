
import Vue, { PropType } from 'vue';
import { answer } from '../CreateSurveyDialog.vue';
export default Vue.extend({
  data() {
    return {
      name: '',
    };
  },
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    }
  },
  watch: {
    answers: {
      immediate: true,
      handler() {
        this.name = this.isPIT ? 'PIT Count 2024' : 'Survey';
      }
    }
  },
  computed: {
    isPIT(): boolean {
      if (this.answers.length < 1) return false;
     return this.answers[0].answer === 'PIT Count';
    }
  },
  methods: {
    selectAnswer() {
      this.advance({ stepID: 'name', answer: this.name });
    },
  },
});
