import { Household, SurveyMapIcon } from '../../models/survey';
import { DataService } from './data';
import { StringService } from '../misc/string';
import Store from '../../store';
import { AdminAdjustment, AdminAdjustmentWrapper, SurveyStatus } from '../../models/adminadjustment';
import { GetAdminAdjustmentWrapperWithoutStore } from '../shared/surveys';

export const SurveyService = {
  GetAdminAdjustment(survey: Household, code: string): AdminAdjustmentWrapper {
    const adminAdjustments: AdminAdjustment[] = Store.getters.adminAdjustments(code);
    return GetAdminAdjustmentWrapperWithoutStore(survey, adminAdjustments);
  },
  stringifySurvey(survey: Household): string {
    let displayString = '';
    const internalKeys = ['surveyState', 'duplicateToIndex'];
    const householdMembers = [];
    for (const [key, value] of Object.entries(survey).filter(
      (kv: any) => !internalKeys.includes(kv[0]),
    )) {
      if (key === 'HouseholdMembers' && Array.isArray(value)) {
        householdMembers.push(value);
      } else if (key === 'date') {
        displayString += `Survey Time <b>${StringService.getPrettyDate(
          value,
        )}</b><br/>`;
      } else if (key === 'location') {
        displayString += `Location <b>[${value}]</b><br/>`;
      } else if (key === 'volunteerPhone') {
        displayString += `Volunteer Phone # <b>${DataService.formatPhone(
          value,
        )}</b><br/>`;
      } else {
        displayString += `${DataService.nicer(key)} <b>${
          value
        }</b><br/>`;
      }
    }
    for (const member of householdMembers) {
      for (const [index, entry] of member.entries()) {
        displayString += `<u>Household Member ${index + 1}</u><br/>`;
        for (const [key2, value2] of Object.entries(entry)) {
          displayString += `${DataService.nicer(key2)} <b>${
            value2
          }</b><br/>`;
        }
      }
    }
    return displayString;
  },
  getIconPaths() {
    return {
      Green: '/img/green_marker.png',
      Blue: '/img/blue_marker.png',
      Red: '/img/red_marker.png',
      Black: '/img/black_marker.png'
    };
  },
};
