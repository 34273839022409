
import Vue, { PropType } from 'vue';
import {
  HUDSection,
  ReportSectionTitle,
  ReportTitle,
  ethnicitiesWithoutUnknown,
} from '../../models/all';
import { GroundTruthService } from '../../services/misc/ground-truth';
import { BreakdownWrapper, CurrentYear } from '../../services/pure/breakdown';
export default Vue.extend({
  props: {
    count: {
      type: Number,
    },
    query: {
      type: Object,
    },
    item: {
      type: String as PropType<ReportTitle>,
    },
    category: {
      type: String as PropType<ReportSectionTitle>,
    },
    title: {
      type: String as PropType<HUDSection>,
    },
    breakdown: {
      type: Object as PropType<BreakdownWrapper>,
    },
  },
  computed: {
    link() {
      return;
    },
    shouldDisplayLink() {
      if (this.item.includes('Multiple Includes')) return false;
      return this.breakdown.year === CurrentYear;
    },
    queryFromItemAndCategory(): any {
      let query = {};
      query =
        this.category === 'Chronically Homeless'
          ? { housingStatus: 'Chronically Homeless' }
          : { housingStatus: undefined };

      // most item cases are gender, ethnicity, race and we have many duplicates of that.
      const genderOptions = GroundTruthService.GetGendersWithTitles(
        this.breakdown.year,
      );
      const foundGender = genderOptions.find(i => i.text === this.item);
      if (foundGender !== undefined && this.category === 'Gender') {
        query = { ...query, genders: [foundGender.value] };
      }
      const isEthnicity = ethnicitiesWithoutUnknown.indexOf(this.item) !== -1;
      if (isEthnicity) {
        query = { ...query, ethnicities: [this.item] };
      }
      const foundRace = GroundTruthService.GetRacesWithTitles(
        this.breakdown.year,
      ).find(i => i.text === this.item);
      if (foundRace !== undefined && this.category === 'Race and Ethnicity') {
        query = { ...query, races: [foundRace.value] };
      }

      // We have some special cases. Additional homeless populations and youth.
      if (this.item === 'Adults with a Serious Mental Illness')
        query = { ...query, conditions: ['Serious Mental Illness'] };
      if (this.item === 'Adults with a Substance Use Disorder')
        query = { ...query, conditions: ['Substance Use Disorder'] };
      if (this.item === 'Adults with HIV/AIDS')
        query = { ...query, conditions: ['HIV/AIDS'] };
      if (this.item === 'Adult Survivors of Domestic Violence (optional)')
        query = { ...query, conditions: ['Domestic Violence'] };

      if (
        this.item === 'Number of parenting youth (under age 18)' ||
        this.item ===
        'Children in households with parenting youth under age 18 (children under age 18 with parents under 18)'
      )
        query = { ...query, parenting: 'Parents under 18' };
      if (
        this.item === 'Number of parenting youth (age 18 to 24)' ||
        this.item ===
        'Children in households with parenting youth under age 18 to 24 (children under age 18 with parents age 18 to 24)'
      )
        query = { ...query, parenting: 'Parents 18-24' };

      // Household type
      // Every other category implies some kind of filter.
      // Additional homeless populations doesn't.
      if (this.title !== 'Additional Homeless Populations')
        query = { ...query, populations: [this.title] };

      // we have "unknown" for genders, ethnicities, and races"
      // if (this.item === 'Unknown') {
      //   const value = 'Unknown';
      //   const key =
      //     this.category === 'Gender'
      //       ? 'genders'
      //       : this.category === 'Ethnicity'
      //       ? 'ethnicities'
      //       : 'races';
      //   query = { ...query, [key]: value };
      // }

      // ages
      const noAdditionalFilters = [
        'Total Number of Households',
        'Total Number of Persons',
        'Number of Veterans',
        'Total number of households',
        'Total number of persons',
      ];
      if (noAdditionalFilters.indexOf(this.item) !== -1) return query;

      const ageMap = {
        'Number of Persons (under age 18)': '<18',
        'Number of unaccompanied children (under age 18)': '<18',
        'Number of Persons (18 - 24)': '18-24',
        'Number of unaccompanied youth (age 18 to 24)': '18-24',
        'Number of Persons (over age 24)': '>24',
        'Number of Persons (25 - 34)': '25-34',
        'Number of Persons (35 - 44)': '35-44',
        'Number of Persons (45 - 54)': '45-54',
        'Number of Persons (55 - 64)': '55-64',
        'Number of Persons (65+)': '65+',
        'Number of Persons (unknown age)': 'Unknown',
      };
      if (ageMap[this.item] !== undefined) {
        query = { ...query, ages: ageMap[this.item] };
      }

      return query;
    },
    to(): any {
      const query = {
        ...this.$route.query,
        tab: 'Data',
        ...this.query,
        ...this.queryFromItemAndCategory,
      };
      return {
        path: '',
        query,
      };
    },
  },
});
