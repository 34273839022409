
import Vue from 'vue';
export default Vue.extend({
    name: 'Toast',
    data() {
        return {
            now: Date.now()
        }
    },
    created() {
        var self = this
        setInterval(function () {
            self.now = Date.now()
        }, 1000)
    },
    computed: {
        message() {
            return this.$store.getters.toast?.message;
        },
        color() {
            return this.$store.getters.toast?.type === 'error' ? 'bg-red-500' : 'bg-green-500';
        },
        show() {
            if (!this.$store.getters.toast) {
                return false;
            }
            const lessThanFiveSecondsAgo: boolean = this.now - this.$store.getters.toast?.time < 5000;
            return lessThanFiveSecondsAgo;
        },
    }
});
