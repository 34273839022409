import { render, staticRenderFns } from "./CompareDialog.vue?vue&type=template&id=643cac36&scoped=true&"
import script from "./CompareDialog.vue?vue&type=script&lang=ts&"
export * from "./CompareDialog.vue?vue&type=script&lang=ts&"
import style0 from "./CompareDialog.vue?vue&type=style&index=0&id=643cac36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643cac36",
  null
  
)

export default component.exports