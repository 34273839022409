
import Vue from 'vue';
import { Both } from '../../../Both';
import { ConclusionService } from '../../../services/pure/conclusion';
import { DataService } from '../../../services/pure/data';
export default Vue.extend({
  props: ['one', 'two', 'surveyID'],
  methods: {
    getType(both: Both) {
      return ConclusionService.GetHouseholdType(both.conclusion);
    },
    getTime(both: Both) {
      return DataService.formatTime(both.household.date);
    },
    getLocation(both: Both) {
      return DataService.formatLocation(both.household.latlng);
    },
  },
});
